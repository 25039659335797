import React from "react";
import { Outlet } from "react-router-dom";
import { Box, Container } from "@mui/material";

import { Header } from "features/Layout/components/Header";
import { Footer } from "features/Layout/components/Footer";
import { useAppStore, useCheckoutStore } from "stores";

export const Layout = ({ children }) => {
  const loading = useAppStore(state => state.loading);
  const error = useAppStore(state => state.error);

  const errorPost = useCheckoutStore(state => state.errorPost);

  return (
    <>
      <Container>
        <Header />
      </Container>

      <Box
        component="main"
        padding={{
          xs: loading || error || errorPost ? "0px" : "24px 0px 40px 0px",
          md: loading || error || errorPost ? "0px" : "64px 0px",
        }}
        sx={{
          background: () => {
            if (loading) {
              return "#f9f9f9";
            }

            if (error || errorPost) {
              return "#ffffff";
            }

            return "linear-gradient(#ffffff 320px, #F9F9F9 320px 60%)";
          },
        }}
      >
        <section>
          <Container>
            <Outlet />
            {children}
          </Container>
        </section>
      </Box>

      <Footer />
    </>
  );
};
