import * as yup from "yup";
import valid from "card-validator";
import { isValid, getCreditCardNameByNumber } from "creditcard.js";
import { validateCPF } from "../../shared/util/validations";

export const creditCardSchema = yup.object().shape({
  creditCard: yup
    .string()
    .required("campo obrigatório")
    .min(13, "cartão inválido")
    .test("test-number", "cartão inválido", value => {
      if (value === undefined) return false;
      return isValid(value);
    }),
  cvv: yup
    .string()
    .required("campo obrigatório")
    .min(3, "código de segurança inválido")
    .max(4, "código de segurança inválido")
    .when("creditCard", {
      is: creditCard => getCreditCardNameByNumber(creditCard) === "Amex",
      then: yup
        .string()
        .test(
          "test-number",
          "código de segurança inválido",
          value => valid.cvv(value, 4).isValid
        ),
      otherwise: yup
        .string()
        .test(
          "test-number",
          "código de segurança inválido",
          value => valid.cvv(value, 3).isValid
        ),
    }),
  expiryDate: yup
    .string()
    .required("campo obrigatório")
    .test("test-number", "data inválida", value => {
      if (value === undefined) return false;
      const result = value.split("/");
      return (
        valid.expirationMonth(result[0]).isValid &&
        valid.expirationYear(result[1]).isValid
      );
    }),
  name: yup
    .string()
    .min(8, "digite seu nome completo")
    .required("campo obrigatório"),
  document: yup
    .string()
    .required("campo obrigatório")
    .test("test-number", "documento inválido", document => {
      return validateCPF(document);
    }),
});
