import React from "react";
import { Grid, Typography, Box, Stack } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";

import { TransactionDataCard } from "features/CheckoutSuccess/components/TransactionDataCard";
import { ReceiptCard } from "features/CheckoutSuccess/components/ReceiptCard";
import { formatDate } from "shared/util/formatter";
import { useCheckoutStore } from "stores/checkout";
import { theme } from "theme";

export const CheckoutSuccess = () => {
  const data = useCheckoutStore(state => state.data);
  const transactionResponse = useCheckoutStore(
    state => state.transactionResponse
  );
  const creditCardValues = useCheckoutStore(state => state.creditCardValues);

  return (
    <>
      <Stack
        direction={{ xs: "column", md: "row" }}
        alignItems="center"
        spacing={{ xs: 4, md: 5 }}
      >
        <DoneIcon
          sx={{
            color: theme.palette.green,
            fontSize: "80px",
          }}
        />

        <Stack spacing={{ xs: 2, md: 3 }}>
          <Typography variant="h1">pagamento efetuado!</Typography>

          <Typography variant="body">
            {formatDate(transactionResponse.transactionTimestamp, true)}
          </Typography>
        </Stack>
      </Stack>

      <Box mt={{ xs: 6, md: 7 }}>
        <Grid container spacing={{ xs: 3, lg: 4 }}>
          <Grid item xs={12} md={6}>
            <TransactionDataCard
              description={data.product_name}
              creditCard={creditCardValues.creditCard}
              payment={creditCardValues.installments.slice(3)}
              totalValue={creditCardValues.formattedTotalValue}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <ReceiptCard
              vendor={data.trading_name}
              document={creditCardValues.document}
              creditCard={creditCardValues.creditCard}
              dateAndTime={formatDate(transactionResponse.transactionTimestamp)}
              value={creditCardValues.formattedTotalValue}
              installments={creditCardValues.installments.slice(3)}
              authCode={transactionResponse.authCode}
              proofOfSale={`${transactionResponse.processorReferenceNumber}/${transactionResponse.processorTransactionID}`}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
