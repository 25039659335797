import create from "zustand";

import { useAppStore } from "stores/app";
import { getCheckoutPixPF } from "services/checkoutPixPF";

export const useCheckoutPixPFStore = create(set => ({
  data: null,
  fetchData: async (saleId, token) => {
    useAppStore.setState({ loading: true });
    getCheckoutPixPF(saleId, token)
      .then(response => {
        if (response.status === 200) {
          set({
            data: response.data,
          });
          useAppStore.getState().setSuccess();
        }
      })
      .catch(error => {
        useAppStore.getState().setError({
          message: error.message,
        });
      });
  },
}));
