export const maskCEP = value => {
  return value.replace(/\D/g, "").replace(/^(\d{5})(\d{3})+?$/, "$1-$2");
};

export const stripCEP = value => {
  return value.replace(/\D+/g, "");
};

export const formatDate = (timedate, showDayOfWeek = false) => {
  const date = new Date(timedate * 1000);

  const hour = date
    .getHours()
    .toString()
    .padStart(2, "0");
  const minutes = date
    .getMinutes()
    .toString()
    .padStart(2, "0");
  const dayOfWeek = date.getDay();
  const days = [
    "domingo",
    "segunda-feira",
    "terça-feira",
    "quarta-feira",
    "quinta-feira",
    "sexta-feira",
    "sábado",
  ];

  const strHour = `${hour}:${minutes}`;
  const strDate = date.toLocaleDateString();

  return `${
    showDayOfWeek ? `${days[dayOfWeek]},` : ""
  } ${strDate} às ${strHour}`;
};

export const formatCurrency = new Intl.NumberFormat("pt-br", {
  style: "currency",
  currency: "BRL",
});
