import React from "react";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import ReactCardFlip from "react-card-flip";

import { CreditCardFlag } from "shared/components/CreditCardFlag";

export const CreditCardFlip = ({ formik, focusCVV }) => {
  return (
    <ReactCardFlip isFlipped={focusCVV} flipDirection="horizontal">
      <Stack alignItems="center" width="100%">
        <Card
          sx={{
            maxWidth: "312px",
            height: "183.53px",
            position: "relative",
            width: "100%",
          }}
        >
          <CardContent>
            {formik.values.creditCard && (
              <Stack alignItems="end">
                <CreditCardFlag creditCard={formik.values.creditCard} />
              </Stack>
            )}

            <Stack
              spacing={3}
              sx={{
                position: "absolute",
                bottom: "24px",
                width: "calc(100% - 48px)",
              }}
            >
              <Typography variant="creditCard" aria-hidden="true">
                {formik.values.creditCard || "XXXX XXXX XXXX XXXX"}
              </Typography>

              <Stack direction="row" justifyContent="space-between">
                <Typography variant="creditCard" aria-hidden="true">
                  {formik.values.name || "NOME IMPRESSO"}
                </Typography>
                <Typography variant="creditCard" aria-hidden="true">
                  {formik.values.expiryDate || "MM/AA"}
                </Typography>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
      <Stack alignItems="center" width="100%">
        <Card
          sx={{
            maxWidth: "312px",
            height: "183.53px",
            position: "relative",
            width: "100%",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#2B374A",
              width: "100%",
              height: "32px",
              position: "absolute",
              top: "32px",
            }}
          />

          <Typography
            variant="creditCard"
            aria-hidden="true"
            sx={{
              color: "#FF4B8D",
              position: "absolute",
              bottom: "24px",
              right: "32px",
            }}
          >
            {formik.values.cvv || "CVV"}
          </Typography>
        </Card>
      </Stack>
    </ReactCardFlip>
  );
};
