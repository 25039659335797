import React, { useEffect, useCallback } from "react";
import { Grid, Typography, Box } from "@mui/material";
import MediaQuery from "react-responsive";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { VendorCard } from "features/Pix/components/VendorCard";
import { RevisionCard } from "features/Pix/components/RevisionCard";
import { PixCard } from "features/Pix/components/PixCard";
import { QRCodeCard } from "features/Pix/components/QRCodeCard";
import { formatPixKey } from "features/Pix/util/formatPixKey";
import { Loading } from "features/Layout/components/Loading";
import { ErrorPage } from "features/ErrorHandler/components/ErrorPage";
import { formatCurrency } from "shared/util/formatter";
import { useAppStore, useCheckoutPixStore } from "stores";

export const CheckoutPix = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const loading = useAppStore(state => state.loading);
  const error = useAppStore(state => state.error);
  const success = useAppStore(state => state.success);

  const data = useCheckoutPixStore(state => state.data);
  const fetchData = useCheckoutPixStore(state => state.fetchData);

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) return;

    const token = await executeRecaptcha("checkoutpix");
    const saleId = new URLSearchParams(window.location.search).get("saleId");
    await fetchData(saleId, token);
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return (
    <>
      {loading && <Loading />}
      {error && (
        <ErrorPage
          title="o link não está mais disponível"
          description="Entre em contato com a pessoa que fez a venda e peça um novo link de pagamento"
          image={require("../shared/svg/naoencontrado.svg").default}
        />
      )}
      {success && (
        <>
          <Typography variant="h1">
            vamos ver se está tudo ok antes de pagar? :)
          </Typography>

          <Box mt={{ xs: 4, md: 7 }}>
            <Grid container spacing={4}>
              <Grid container item direction="column" spacing={4} md={6}>
                <Grid item>
                  <VendorCard
                    nameLabel="vendedor"
                    name={data.trading_name}
                    description={data.payment.description}
                    totalValue={formatCurrency.format(data.total_value)}
                    showTotalValue={Boolean(data.total_value)}
                  />
                </Grid>

                <MediaQuery minWidth={768}>
                  {Boolean(data.total_value) && (
                    <Grid item>
                      <RevisionCard
                        product={data.product_name}
                        productValue={formatCurrency.format(data.product_value)}
                        shipping={formatCurrency.format(data.shipping_value)}
                        discount={formatCurrency.format(data.discount)}
                        totalValue={formatCurrency.format(data.total_value)}
                        showShipping={Boolean(data.shipping_value)}
                        showDiscount={Boolean(data.discount)}
                      />
                    </Grid>
                  )}
                </MediaQuery>
              </Grid>

              <Grid container item direction="column" spacing={4} md={6}>
                <Grid item>
                  <PixCard pixCode={data.payment.emv} />
                </Grid>

                <Grid item>
                  <QRCodeCard
                    src={data.payment.image}
                    pixKey={
                      formatPixKey(data.payment.key)[data.payment.key_type]
                    }
                  />
                </Grid>

                <MediaQuery maxWidth={767}>
                  {Boolean(data.total_value) && (
                    <Grid item>
                      <RevisionCard
                        product={data.product_name}
                        productValue={formatCurrency.format(data.product_value)}
                        shipping={formatCurrency.format(data.shipping_value)}
                        discount={formatCurrency.format(data.discount)}
                        totalValue={formatCurrency.format(data.total_value)}
                        showShipping={Boolean(data.shipping_value)}
                        showDiscount={Boolean(data.discount)}
                      />
                    </Grid>
                  )}
                </MediaQuery>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};
