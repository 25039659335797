/* eslint-disable */
export const clearSaleId = () => {
  const timestamp = new Date().getTime();
  (function(a, b, c, d, e, f, g) {
    a["CsdpObject"] = e;
    (a[e] =
      a[e] ||
      function() {
        (a[e].q = a[e].q || []).push(arguments);
      }),
      (a[e].l = 1 * new Date());
    (f = b.createElement(c)), (g = b.getElementsByTagName(c)[0]);
    f.async = 1;
    f.src = d;
    g.parentNode.insertBefore(f, g);
  })(window, document, "script", "//device.clearsale.com.br/p/fp.js", "csdp");
  csdp("app", " ejzwvtmce0l7g3anci1c");
  csdp("sessionid", timestamp);
  return timestamp.toString();
};
