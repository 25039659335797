import React from "react";
import { Box, Stack, Typography } from "@mui/material";

export const Footer = () => {
  return (
    <Box
      component="footer"
      role="contentinfo"
      aria-label="Rodapé"
      backgroundColor="dark"
      padding={{ xs: "32px", md: "64px" }}
    >
      <Stack spacing={5}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems="center"
          spacing={5}
        >
          <Box
            component="img"
            src={require("../../../shared/svg/logo.svg").default}
            alt="Logo iti"
            height={{ xs: "98px", md: "64px" }}
          />

          <Stack
            direction={{ xs: "column", md: "row" }}
            alignItems="center"
            spacing={4}
          >
            <Typography variant="footerStrong" component="p">
              faz um iti
            </Typography>

            <a href="https://apps.apple.com/br/app/iti-banco-digital-do-ita%C3%BA/id1442872271">
              <img
                src={require("../../../shared/svg/apple.svg").default}
                alt="Logo da Apple Store"
              />
            </a>

            <a href="https://play.google.com/store/apps/details?id=com.itau.iti&hl=pt_BR&gl=US">
              <img
                src={require("../../../shared/svg/google.svg").default}
                alt="Logo da Google Play"
              />
            </a>
          </Stack>
        </Stack>

        <Stack spacing={0} textAlign={{ xs: "center", md: "right" }}>
          <Typography variant="footerBody" component="p">
            BANCO ITAUCARD S.A. | CNPJ nº: 17.192.451/0001-70
          </Typography>
          <Typography variant="footerBody" component="p">
            Praça Alfredo Egydio de Souza Aranha, 100, Torre Olavo Setubal, 7º
            andar, Parque Jabaquara
          </Typography>
          <Typography variant="footerBody" component="p">
            São Paulo - SP | CEP 04344-020
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};
