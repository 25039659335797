import create from "zustand";

export const useAppStore = create(set => ({
  loading: true,
  error: false,
  success: false,
  setError: error => {
    set({ error, success: false, loading: false });
  },
  setSuccess: () => {
    set({ error: false, success: true, loading: false });
  },
}));
