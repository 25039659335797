import React from "react";
import { CircularProgress, Stack, Typography } from "@mui/material";

export const Loading = () => {
  return (
    <Stack
      spacing={4}
      alignItems="center"
      justifyContent="center"
      pt="120px"
      pb="120px"
      sx={{
        minHeight: { xs: "calc(100vh - 558px)", md: "calc(100vh - 398px)" },
      }}
    >
      <CircularProgress
        sx={{
          color: "transparent",
          borderRadius: "50%",
          border: "5px #fff solid",
          borderTop: " 5px #ec7000 solid",
          borderRight: "5px #ec7000 solid",
          borderBottom: "5px #ff4b8d solid",
        }}
      />
      <Stack spacing={1} textAlign="center">
        <Typography variant="bodyBolded2" component="p">
          aguarde um momento
        </Typography>
        <Typography variant="label" component="p">
          estamos carregando as informações
        </Typography>
      </Stack>
    </Stack>
  );
};
