import React from "react";
import { getCreditCardNameByNumber } from "creditcard.js";

import { creditCardFlags } from "../constants/creditCardFlags";

export const CreditCardFlag = ({ creditCard }) => {
  const brandName = getCreditCardNameByNumber(creditCard);
  const Icon = creditCardFlags[brandName];

  return <>{Boolean(Icon) && <Icon />}</>;
};
