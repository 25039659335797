import React from "react";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";

export const VendorCard = ({
  vendor,
  totalValue,
  product,
  paymentType,
  maxInstallments,
  productValue,
  shipping,
  discount,
  hasShippingAndDiscount,
}) => {
  const maxInstallmentsText = `Crédito em até ${maxInstallments}x`;

  return (
    <Card>
      <CardContent>
        <Stack spacing={4}>
          <Stack direction="row" spacing={3}>
            <Box
              component="img"
              alt="Ícone"
              aria-hidden="true"
              src={require("../../../shared/svg/iticon.svg").default}
              width={{
                xs: "32px",
                md: "56px",
              }}
            />

            <Stack spacing={1}>
              <Typography variant="label" component="p">
                vendedor
              </Typography>
              <Typography variant="strongResponsive" component="p">
                {vendor}
              </Typography>
            </Stack>
          </Stack>

          <Typography variant="h2">revisão de pagamento</Typography>

          <Stack spacing={1}>
            <Typography variant="label" component="p">
              valor a pagar
            </Typography>
            <Typography variant="strong" component="p">
              {totalValue}
            </Typography>
          </Stack>

          <Stack spacing={4}>
            {product && (
              <Stack spacing={1}>
                <Typography variant="label" component="p">
                  nome do produto
                </Typography>
                <Typography variant="body" component="p">
                  {product}
                </Typography>
              </Stack>
            )}

            <Stack spacing={1}>
              <Typography variant="label" component="p">
                pagamento
              </Typography>
              <Typography variant="body" component="p">
                {paymentType}
              </Typography>
            </Stack>

            <Stack>
              <Stack spacing={1}>
                <Typography variant="label" component="p">
                  parcelamento
                </Typography>
                <Typography variant="body" component="p">
                  {maxInstallmentsText}
                </Typography>
              </Stack>
            </Stack>

            {hasShippingAndDiscount && (
              <Stack>
                <Stack spacing={2}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="label" component="p">
                      valor da venda
                    </Typography>
                    <Typography variant="bodyBolded" component="p">
                      {productValue}
                    </Typography>
                  </Stack>

                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="label" component="p">
                      valor da entrega
                    </Typography>
                    <Typography variant="bodyBolded" component="p">
                      {shipping}
                    </Typography>
                  </Stack>

                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="label" component="p">
                      desconto
                    </Typography>
                    <Typography variant="bodyBolded" component="p">
                      - {discount}
                    </Typography>
                  </Stack>
                </Stack>

                <Box
                  sx={{
                    backgroundColor: "gray300",
                    margin: "24px 0px",
                    height: "0.5px",
                  }}
                />

                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="label" component="p">
                    valor a pagar
                  </Typography>
                  <Typography variant="bodyBolded" component="p">
                    {totalValue}
                  </Typography>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
