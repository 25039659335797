import React from "react";

import { ErrorPage } from "features/ErrorHandler/components/ErrorPage";
import {
  brandCodes1,
  brandCodes2,
  brandCodes3,
  brandCodes4,
  brandCodes5,
  brandCodes6,
  brandCodes7,
  brandCodes8,
} from "shared/constants/brandCodes";
import { useCheckoutStore, useStepsStore } from "stores";

export const ErrorHandler = () => {
  const transactionResponse = useCheckoutStore(
    state => state.transactionResponse
  );
  const finalStatusCode = useCheckoutStore(state => state.finalStatusCode);
  const setErrorPost = useCheckoutStore(state => state.setErrorPost);

  const setCurrentStep = useStepsStore(state => state.setCurrentStep);
  const setEditing = useStepsStore(state => state.setEditing);

  const handleEdit = value => {
    setErrorPost(false);
    setCurrentStep(value);
    setEditing(true);
  };

  if (finalStatusCode === 409) {
    return (
      <ErrorPage
        title="não foi possível completar a transação"
        description="revise os dados da transação ou verifique com a operadora do cartão e tente novamente"
        button={{
          variant: "outlined",
          color: "secondary",
          label: "tentar de novo",
          onClick: () => handleEdit(3),
        }}
        image={require("../../shared/svg/naoenviado.svg").default}
      />
    );
  }

  if (transactionResponse.responseCode === 1) {
    if (brandCodes1.includes(transactionResponse.brandCode))
      return (
        <ErrorPage
          title="o cartão ainda está bloqueado"
          description="Desbloqueie seu cartão e tente de novo"
          button={{
            variant: "contained",
            color: "primary",
            label: "tentar de novo",
            onClick: () => handleEdit(3),
          }}
          image={require("../../shared/svg/ilustracartao.svg").default}
        />
      );

    if (brandCodes2.includes(transactionResponse.brandCode))
      return (
        <ErrorPage
          title="transação não efetuada"
          description="Entre em contato com o banco do seu cartão ou revise os dados da transação"
          button={{
            variant: "contained",
            color: "primary",
            label: "revisar dados",
            onClick: () => handleEdit(3),
          }}
          image={require("../../shared/svg/ilustracartao.svg").default}
        />
      );

    if (brandCodes3.includes(transactionResponse.brandCode))
      return (
        <ErrorPage
          title="erro no processamento do cartão"
          description="Revise os dados do cartão e tente de novo."
          button={{
            variant: "contained",
            color: "primary",
            label: "tentar de novo",
            onClick: () => handleEdit(3),
          }}
          image={require("../../shared/svg/ilustracartao.svg").default}
        />
      );

    if (brandCodes4.includes(transactionResponse.brandCode))
      return (
        <ErrorPage
          title="erro nos dados do cartão ou a função não está habilitada para este cartão"
          description="Revise os dados do cartão e tente de novo"
          button={{
            variant: "contained",
            color: "primary",
            label: "revisar dados do cartão",
            onClick: () => handleEdit(1),
          }}
          image={require("../../shared/svg/ilustracartao.svg").default}
        />
      );

    if (brandCodes5.includes(transactionResponse.brandCode))
      return (
        <ErrorPage
          title="este cartão não está habilitado para este tipo de compra"
          description="Entre em contato com o banco do seu cartão ou use outro cartão"
          button={{
            variant: "contained",
            color: "primary",
            label: "usar outro cartão",
            onClick: () => handleEdit(1),
          }}
          image={require("../../shared/svg/ilustracartao.svg").default}
        />
      );

    if (brandCodes6.includes(transactionResponse.brandCode))
      return (
        <ErrorPage
          title="transação não autorizada pelo seu banco"
          description="Revise os dados ou use outro cartão"
          button={{
            variant: "contained",
            color: "primary",
            label: "revisar dados do cartão",
            onClick: () => handleEdit(1),
          }}
          image={require("../../shared/svg/ilustracartao.svg").default}
        />
      );

    if (brandCodes7.includes(transactionResponse.brandCode))
      return (
        <ErrorPage
          title="limite insuficiente"
          description="Entre em contato com o banco do seu cartão ou use outro cartão"
          button={{
            variant: "contained",
            color: "primary",
            label: "usar outro cartão",
            onClick: () => handleEdit(1),
          }}
          image={require("../../shared/svg/ilustracartao.svg").default}
        />
      );

    if (brandCodes8.includes(transactionResponse.brandCode))
      return (
        <ErrorPage
          title="o sistema falhou"
          description="Este erro não era esperado, mas não se preocupe, você pode tentar de novo"
          button={{
            variant: "outlined",
            color: "secondary",
            label: "tentar de novo",
            onClick: () => handleEdit(3),
          }}
          image={require("../../shared/svg/ilustra.svg").default}
        />
      );
  }

  if (transactionResponse.responseCode === 2) {
    return (
      <ErrorPage
        title="transação interrompida"
        description="Detectamos um problema e bloqueamos a transação para sua proteção. Entre em contato com a pessoa que fez a venda."
        image={require("../../shared/svg/naoenviado.svg").default}
      />
    );
  }

  if (transactionResponse.responseCode === 7) {
    return (
      <ErrorPage
        title="o link não está mais disponível"
        description="Entre em contato com a pessoa que fez a venda e peça um novo link de pagamento"
        image={require("../../shared/svg/naoencontrado.svg").default}
      />
    );
  }

  if (transactionResponse.responseCode === 4097) {
    return (
      <ErrorPage
        title="o sistema falhou"
        description="Este erro não era esperado, mas não se preocupe, você pode tentar de novo"
        button={{
          variant: "outlined",
          color: "secondary",
          label: "tentar de novo",
          onClick: () => handleEdit(3),
        }}
        image={require("../../shared/svg/ilustra.svg").default}
      />
    );
  }

  return (
    <ErrorPage
      title="o sistema falhou"
      description="Este erro não era esperado, mas não se preocupe, você pode tentar de novo"
      button={{
        variant: "outlined",
        color: "secondary",
        label: "tentar de novo",
        onClick: () => handleEdit(3),
      }}
      image={require("../../shared/svg/ilustra.svg").default}
    />
  );
};
