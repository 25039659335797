import React from "react";
import { SvgIcon } from "@mui/material";

export const Iti = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.95774 4.50027C8.37031 4.49062 9.44424 5.52354 9.44424 6.88469C9.44424 8.23618 8.34128 9.29807 6.95774 9.27876C5.56452 9.26911 4.50993 8.23618 4.50026 6.89434C4.48091 5.5332 5.54517 4.50027 6.95774 4.50027ZM25.4275 6.86538C25.4179 8.22653 26.4821 9.27876 27.8947 9.28841C29.2879 9.29807 30.3812 8.24583 30.3812 6.88469C30.3812 5.54285 29.3266 4.50992 27.9431 4.50027C26.5208 4.48096 25.4372 5.50423 25.4275 6.86538ZM16.4684 16.6444H22.9604V13.3622H16.4684V6.93296H15.7331C13.9722 6.93296 12.55 8.35202 12.55 10.109V10.1379C12.55 11.2095 12.55 12.2714 12.55 13.3912L12.5596 23.9714C12.5693 24.7244 12.6177 25.487 12.7531 26.2207C13.1111 28.1997 14.0593 29.8215 15.9556 30.6999C18.355 31.8101 20.5222 31.6653 22.9604 30.8737C22.9604 30.8737 22.9604 28.3541 22.9604 27.2729C22.9604 27.2729 21.6542 27.8232 19.7386 27.8232C19.4677 27.8232 19.2064 27.8135 18.9452 27.7653C17.6681 27.5336 16.8554 26.713 16.5942 25.3519C16.5168 24.9561 16.4781 24.5603 16.4781 24.1548L16.4684 16.6444ZM8.91211 13.3622H8.87341C6.80294 13.3622 5.11947 15.0419 5.11947 17.1078V30.922H8.91211V13.3622ZM29.8491 30.922V17.1367C29.8491 15.0516 28.1559 13.3622 26.0661 13.3622H26.0274V30.922H29.8491Z"
          fill="url(#paint0_linear_2466_290376)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2466_290376"
            x1="4.46656"
            y1="4.4098"
            x2="30.5631"
            y2="32.0679"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2287" stopColor="#F36D00" />
            <stop offset="0.3038" stopColor="#F56917" />
            <stop offset="0.462" stopColor="#F96243" />
            <stop offset="0.6149" stopColor="#FB5C65" />
            <stop offset="0.7591" stopColor="#FD587E" />
            <stop offset="0.8913" stopColor="#FF568D" />
            <stop offset="1" stopColor="#FF5592" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};
