import React from "react";
import { Card, CardContent, Typography, Box, Stack } from "@mui/material";

export const ReceiptCard = ({
  vendor,
  document,
  creditCard,
  dateAndTime,
  value,
  installments,
  authCode,
  proofOfSale,
}) => {
  return (
    <>
      <Card>
        <CardContent
          sx={{ position: "relative", borderBottom: "1px dashed #2B394A" }}
        >
          <Box
            component="img"
            src={require("../../../shared/svg/logoitau.svg").default}
            alt="Ícone Iti"
            aria-hidden="true"
            sx={{ position: "absolute", right: "24px", width: "35px" }}
          />

          <Stack spacing={3}>
            <Typography variant="h2">via do cliente</Typography>
            <Stack spacing={1}>
              <Typography variant="label">vendedor</Typography>
              <Typography variant="body">{vendor}</Typography>
            </Stack>

            <Stack spacing={1}>
              <Typography variant="label">documento</Typography>
              <Typography variant="body">{document}</Typography>
            </Stack>
          </Stack>

          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <Stack spacing={3}>
            <Typography variant="h2">pagamento</Typography>

            <Stack spacing={1}>
              <Typography variant="label">cartão</Typography>
              <Typography variant="body">
                {`**** **** **** ${creditCard.slice(-4)}`}
              </Typography>
            </Stack>

            <Stack spacing={1}>
              <Typography variant="label">data e hora</Typography>
              <Typography variant="body">{dateAndTime}</Typography>
            </Stack>

            <Stack spacing={1}>
              <Typography variant="label">valor da compra</Typography>
              <Typography variant="body">{value}</Typography>
            </Stack>

            <Stack spacing={1}>
              <Typography variant="label">parcelamento</Typography>
              <Typography variant="body">{installments}</Typography>
            </Stack>

            <Stack spacing={1}>
              <Typography variant="label">nº autorização</Typography>
              <Typography variant="body">{authCode}</Typography>
            </Stack>
          </Stack>
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <Stack spacing={4}>
            <Stack spacing={1}>
              <Typography
                variant="label"
                textAlign={{ xs: "center", lg: "left" }}
              >
                comprovante da venda (NSU/TID)
              </Typography>
              <Typography
                variant="body"
                textAlign={{ xs: "center", lg: "left" }}
              >
                {proofOfSale}
              </Typography>
            </Stack>

            <Typography
              variant="label"
              textAlign={{ xs: "center", lg: "left" }}
            >
              Este slip não tem valor jurídico
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};
