import axios from "axios";

const URL = {
  dev: "https://iti-website-backend.hom.cloud.itau.com.br",
  hom: "https://iti-website-backend.hom.cloud.itau.com.br",
  prod: "https://iti-website-backend.cloud.itau.com.br",
};

const URL_REDE = {
  dev: "https://api-hom.userede.com.br/redelabs/payment-link/v1/",
  hom: "https://api-hom.userede.com.br/redelabs/payment-link/v1/",
  prod: "https://api.userede.com.br/redelabs/payment-link/v1/",
};

export const api = axios.create({
  baseURL:
    URL[process.env.REACT_APP_ENVIRONMENT] ||
    "https://iti-website-backend.hom.cloud.itau.com.br",
  crossDomain: true,
});
export const apiREDE = axios.create({
  baseURL:
    URL_REDE[process.env.REACT_APP_ENVIRONMENT] ||
    "https://api-hom.userede.com.br/redelabs/payment-link/v1/",
  crossDomain: true,
});
