import { api } from "services/api";

export const getCheckoutPix = async (saleId, token) => {
  const URL = `/pix?saleId=${saleId}`;

  try {
    const response = await api.get(URL, {
      headers: {
        "Content-type": "application/json",
        Authorization: `${token}`,
      },
    });

    return response;
  } catch (error) {
    if (error.message.includes("401"))
      throw Error("Ocorreu um erro com o token do recaptcha");
    else if (error.message.includes("400")) {
      throw Error("Ocorreu um erro, informações invalidas");
    } else {
      throw Error("Ocorreu um erro, tente novamente mais tarde");
    }
  }
};
