import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";

// import { useAppStore } from "stores/app";

export const TermsAndConditions = () => {
  useEffect(() => {
    //   useAppStore.getState().setSuccess();
  }, []);

  return (
    <Box
      sx={{ marginLeft: "auto", marginRight: "auto" }}
      pt={{ xs: 4, md: 7 }}
      pb={{ xs: 4, md: 7 }}
      maxWidth={{ md: 720, lg: 960, xl: 1140 }}
    >
      <Box component="section">
        <Typography variant="h2Terms" component="h2" mt={4}>
          CONTRATO DE CREDENCIAMENTO E ADESÃO DE ESTABELECIMENTOS AO SISTEMA ITI
        </Typography>

        <Typography variant="pTerms" component="p" mt={4}>
          Por este instrumento, disponibilizado no canal de contratação do BANCO
          ITAUCARD S.A., pessoa jurídica estabelecido na Praça Alfredo Egydio de
          Souza Aranha, 100 – Torre Olavo Setubal, 7º andar, bairro Jabaquara,
          na cidade de São Paulo, estado de São Paulo, inscrito no CNPJ/MF
          17.192.451/0001-70, doravante denominado Conta de Pagamento ou
          Aplicativo ITI e , a pessoa jurídica ou a pessoa física qualificada na
          respectiva Proposta de Credenciamento e Adesão de Estabelecimentos ao
          SISTEMA ITI (“PROPOSTA DE CREDENCIAMENTO”), quais são parte integrante
          deste CONTRATO, ora designada ESTABELECIMENTO e a REDECARD INSTITUICAO
          DE PAGAMENTO S.A., com sede na Cidade de São Paulo, Estado de São
          Paulo, Rua Tenente Mauro de Miranda, nº 36, 7º andar parte, Parque
          Jabaquara, CEP 04345-030, inscrita no CNPJ/MF sob nº
          01.425.787/0001-04 (“ITI”), denominada individualmente como Parte ou
          coletivamente como Partes, têm entre si justo e acordado este
          CONTRATO, uma vez aprovada a sua adesão ao SISTEMA ITI, nos termos e
          condições abaixo:
        </Typography>
      </Box>

      <Box component="section">
        <List>
          <Typography variant="h2Terms" component="h2" mt={4}>
            OBJETO
          </Typography>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>1</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              O objeto deste CONTRATO é:
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                a captura, roteamento, transmissão e processamento das
                TRANSAÇÕES, mediante credenciamento do ESTABELECIMENTO para
                integrar o SISTEMA ITI, habilitando-o a aceitar os respectivos
                CARTÕES e MEIOS DE PAGAMENTO e a usufruir dos respectivos
                PRODUTOS; e
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  b
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                a administração, garantia e/ou efetivação da liquidação
                financeira ao ESTABELECIMENTO do VALOR LÍQUIDO das TRANSAÇÕES,
                desde que cumpridos os termos e condições deste CONTRATO:
              </Typography>
            </ListItem>
          </List>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>1.1</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              Constituem atividades relacionadas ao objeto deste CONTRATO:
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                o fornecimento de COMPROVANTES DE VENDAS, RESUMOS DE VENDAS e
                EQUIPAMENTOS, possibilitando a captura eletrônica das
                TRANSAÇÕES;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  b
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                a promoção e a divulgação do ESTABELECIMENTO aos PORTADORES,
                mediante fornecimento de sinalização específica e MATERIAIS;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  c
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                a coordenação e a manutenção adequada da operacionalidade do
                SISTEMA ITI; e
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  d
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                a disponibilização ao ESTABELECIMENTO de múltiplas BANDEIRAS,
                cuja listagem se encontra no APLICATIVO ITI para consulta e
                conhecimento.
              </Typography>
            </ListItem>
          </List>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>1.2</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              Os serviços e atividades de que trata essa cláusula são prestados
              e/ou propiciados aos ESTABELECIMENTOS pelos integrantes do SISTEMA
              ITI (dentre eles, EMISSORES e BANDEIRAS), por intermédio do ITI ,
              cabendo a cada um deles, conforme acordado em contratos próprios,
              uma parcela da TAXA DE DESCONTO, da TARIFA POR TRANSAÇÃO ou,
              conforme o caso, de outras formas de remuneração previstas neste
              CONTRATO.
            </Typography>
          </ListItem>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>1.3</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              As definições que permitem um melhor entendimento deste CONTRATO,
              de seus Aditivos e de documentos vinculados encontram-se no ANEXO
              I.
            </Typography>
          </ListItem>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>1.4</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              Este CONTRATO não gera nenhum direito de exclusividade às Partes,
              podendo o ESTABELECIMENTO firmar contratos semelhantes com outras
              empresas que possuam a mesma atividade do ITI.
            </Typography>
          </ListItem>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>1.5</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              São partes integrantes deste CONTRATO e de seu objeto, o ANEXO I,
              a PROPOSTA DE CREDENCIAMENTO, disponibilizada por meio do
              APLICATIVO ITI. Em caso de conflito entre referidos documentos e
              este CONTRATO, prevalecerá o CONTRATO, a menos que um instrumento
              específico altere expressamente esta condição.
            </Typography>
          </ListItem>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>1.6</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              Os serviços e atividades previstos neste CONTRATO serão prestados
              pelo ITI que poderá subcontratar terceiros para realizá-los.
            </Typography>
          </ListItem>
          <Typography variant="h2Terms" component="h2" mt={4}>
            Credenciamento
          </Typography>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>2</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              O início do processo de credenciamento e adesão do ESTABELECIMENTO
              ao SISTEMA ITI poderá ocorrer pelos canais disponibilizados pelo
              ITI ou pelo Aplicativo ITI, podendo ser: canais de telemarketing,
              empresas terceiras ou parceiras, área comercial do ITI, por auto
              credenciamento via SITE ou APLICATIVO ITI, ou ainda por outros
              canais que vierem a ser disponibilizados pelo ITI.
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O credenciamento por quaisquer dos canais disponíveis consiste
                na solicitação do ESTABELECIMENTO, com a apresentação das
                condições comerciais, as quais podem ser formalizadas por meio
                eletrônico ou por qualquer outro meio que vier a ser
                disponibilizado pelo ITI.
              </Typography>
            </ListItem>
          </List>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>3</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              O efetivo credenciamento e a adesão do ESTABELECIMENTO ao SISTEMA
              ITI está condicionado à aceitação prévia do ITI, mediante
              apresentação pelo ESTABELECIMENTO, das informações e documentos
              solicitados pelo ITI,
            </Typography>
          </ListItem>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>3.1</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              que fará a análise das atividades desenvolvidas pelo
              ESTABELECIMENTO, da sua saúde financeira e de seus
              sócios/representantes/proprietários/acionistas, do histórico de
              relacionamento anterior com o ITI, dentre outros critérios de
              análise cadastral e financeira que venham a ser adotados pelo ITI,
              a qualquer tempo, inclusive durante a vigência deste CONTRATO.
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                A relação de documentos solicitados pelo ITI será informada no
                momento do credenciamento e poderá ser, a qualquer momento,
                modificada a critério do ITI.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  b
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Caso o ITI avalie que os critérios de análise cadastral e
                financeira não foram atingidos ou completos, tais como,
                informações incorretas, incompletas e/ou inverídicas, não envio
                de forma satisfatória de documentos e esclarecimentos
                solicitados ou caso o ITI constate restrições ao crédito do
                ESTABELECIMENTO, o ITI poderá bloquear, suspender, cancelar e/ou
                revogar o credenciamento do ESTABELECIMENTO ou a aprovação e a
                realização de quaisquer Transações ou movimentações, sem
                prejuízo de outras medidas de acordo com o previsto no presente
                CONTRATO e eventual responsabilidade por perdas e danos.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  c
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Determinados ESTABELECIMENTOS não serão credenciados ou serão
                descredenciados pelo ITI por exercerem atividades consideradas
                ilegais ou indesejáveis. O rol destas atividades está disponível
                para consulta, a critério do ITI, ou no APLICATIVO ITI ou
                através da CENTRAL DE ATENDIMENTO.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  d
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O ESTABELECIMENTO se compromete a não efetuar TRANSAÇÕES em
                segmentos ou ramos de atividades diversos daqueles informados
                pelo ESTABELECIMENTO no momento de seu credenciamento no ITI,
                mesmo que tais atividades constem de seu objeto social. Qualquer
                alteração no segmento de atuação ou no ramo de atividade do
                ESTABELECIMENTO deve ser informada ao ITI, que em caso de
                aprovação, efetuará a alteração cadastral, ficando o
                ESTABELECIMENTO ciente que tal alteração pode levar a uma nova
                negociação comerc
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  e
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Na hipótese do não credenciamento do ESTABELECIMENTO ou do
                descredenciamento do ESTABELECIMENTO por parte do ITI, em até 12
                meses contados a partir do pagamento da TAXA DE SERVIÇO pelo
                ESTABELECIMENTO, resolve-se o CONTRATO, cujo efeito retroagirá à
                data do pagamento. Nesse caso, o valor pago será integralmente
                restituído pelo ITI. A restituição dos valores pelo ITI poderá
                ser realizada por meio de depósito na CONTA DE PAGAMENTO, ou via
                ordem de pagamento para o DOMICÍLIO BANCÁRIO indicado pelo
                ESTABELECIMENTO.
              </Typography>
            </ListItem>
          </List>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>4</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              Após a aceitação e inclusão do ESTABELECIMENTO no SISTEMA ITI são
              gerados, automaticamente:
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                NÚMERO DE ESTABELECIMENTO
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  b
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                o ITI enviará uma comunicação por meio eletrônico, com os dados
                cadastrais do ESTABELECIMENTO, e demais informações, tais como:
                valor da TAXA DE DESCONTO e/ou da TARIFA POR TRANSAÇÃO, valor da
                TAXA DE SERVIÇO, os prazos de pagamento, informações estas que
                deverão ser devidamente conferidas pelo ESTABELECIMENTO quando
                do recebimento da referida comunicação, e em caso de
                divergência, o ESTABELECIMENTO deverá imediatamente por meio dos
                Canais Digitais ou entrar em contato pela CENTRAL DE ATENDIMENTO
                solicitando a regularização;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  c
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O presente CONTRATO passa a vigorar a partir do momento que o
                ESTABELECIMENTO estiver apto e habilitado a realizar TRANSAÇÕES,
                independentemente de realizá-las.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  d
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                A ocorrência dos eventos acima implica a concordância do
                ESTABELECIMENTO com todos os termos e condições deste CONTRATO,
                bem como com as regras e exigências determinadas pelo ITI ou
                pelas BANDEIRAS, pelo mercado de meios de pagamento e pela
                legislação, tenham sido elas estipuladas no passado ou venham
                ser no futuro, sendo que seu descumprimento pode acarretar a
                rescisão deste CONTRATO.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  e
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O ESTABELECIMENTO poderá vincular um ou mais PONTOS DE VENDAS
                (PV), sob sua responsabilidade, ao seu cadastro no SISTEMA ITI.
                O ITI avaliará o pedido conforme seus próprios critérios,
                podendo aprová-lo ou recusá-lo.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  f
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O ESTABELECIMENTO deverá providenciar a divulgação do CONTRATO
                às suas filiais, impondo-lhes a estrita observância de seus
                termos e condições.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  g
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Será permitido ao ESTABELECIMENTO, a qualquer momento, solicitar
                a adesão ou o cancelamento de determinados PRODUTOS ofertados
                pelo ITI, detalhados nos respectivos instrumentos específicos
                disponíveis no APLICATIVO ITI.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  h
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Os PRODUTOS disponíveis no SISTEMA ITI podem ser oferecidos ao
                ESTABELECIMENTO de forma remota, ou seja, sem que haja
                necessidade de uma visita ao ESTABELECIMENTO, sendo que a adesão
                do ESTABELECIMENTO se efetuará quando da realização de qualquer
                TRANSAÇÃO do PRODUTO ou mediante habilitação pelo ITI, conforme
                o caso, o que resulta na aceitação de todos os termos e
                condições do instrumento específico que faz parte deste
                CONTRATO.
              </Typography>
            </ListItem>
          </List>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>5</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              É obrigação do ESTABELECIMENTO sinalizar suas instalações com os
              MATERIAIS fornecidos pelo ITI, em locais de destaque e de boa
              visibilidade, para exposição ao público em geral, conforme
              orientações do ITI e observada a legislação em vigor.
            </Typography>
          </ListItem>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>6</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              O ESTABELECIMENTO autoriza o ITI, sempre que esta julgar
              necessário, a vistoriar durante o horário de funcionamento do
              ESTABELECIMENTO, diretamente ou por terceiros por ela designados,
              (i) a regularidade e permanência de suas atividades (ii) a
              adequação da sinalização de uso obrigatório, nos termos da
              Cláusula 5 deste CONTRATO,
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                (iii) a regularidade na realização das TRANSAÇÕES, (iv) o
                funcionamento dos EQUIPAMENTOS, e (v) a adequada guarda, o
                consumo e o abastecimento de todo e qualquer MATERIAL necessário
                à realização das TRANSAÇÕES.
              </Typography>
            </ListItem>
          </List>
          <Typography variant="h2Terms" component="h2" mt={4}>
            FORMA DE COBRANÇA DO ESTABELECIMENTO
          </Typography>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>7</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              Além da TAXA DE DESCONTO e/ou TARIFA POR TRANSAÇÃO, o ITI poderá
              cobrar do ESTABELECIMENTO os seguintes encargos e taxas, sem
              prejuízo das cobranças específicas dos PRODUTOS e quaisquer outras
              taxas, tarifas e encargos eventualmente incidentes sobre este
              CONTRATO:
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                TAXA DE SERVIÇO – valor pago ao ITI pelos serviços prestados, em
                razão da instalação, reposição, manutenção de cada EQUIPAMENTO
                e/ou fornecimento adicional de EQUIPAMENTOS;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  b
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                INDENIZAÇÃO POR EQUIPAMENTO PERDIDO – valor cobrado por
                EQUIPAMENTOS do ITI ou de terceiros que não foram devolvidos
                para o ITI de acordo com as condições deste CONTRATO;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  c
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                A TARIFA POR TRANSAÇÃO poderá ser cobrada isoladamente ou em
                conjunto com a TAXA DE DESCONTO. O ESTABELECIMENTO poderá pagar
                diferentes TARIFAS POR TRANSAÇÃO e/ou TAXAS DE DESCONTO,
                dependendo da modalidade de TRANSAÇÃO, da atividade exercida
                e/ou da BANDEIRA capturada.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  d
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O ITI poderá cobrar TAXA DE DESCONTO ou TARIFA POR TRANSAÇÃO
                diferenciadas, conforme o ESTABELECIMENTO.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  e
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                As taxas, preços e tarifas poderão ser reajustadas anualmente ou
                na menor periodicidade permitida em LEI, pela variação do
                IPC/FGV no período, ou por qualquer outro índice que vier a
                substituí-lo. As taxas e preços fixados em percentual do valor
                da TRANSAÇÃO não serão alcançadas pela regra desta cláusula.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  f
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                As taxas e preços fixados em percentual do valor da TRANSAÇÃO
                poderão ser reajustados a qualquer momento. As eventuais
                alterações serão comunicadas pelo ITI, inclusive por meio
                eletrônico. Se não concordar com as alterações, o
                ESTABELECIMENTO poderá rescindir este CONTRATO nos termos aqui
                previstos.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  g
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O ITI poderá instituir novas modalidades de remuneração pelos
                seus serviços prestados, tais como tarifas ou taxas: (i) pela
                utilização dos seus recursos de atendimento; (ii) pelo
                fornecimento e reposição de MATERIAIS e insumos; (iii) pela
                emissão de extratos, relatórios e borderôs; (iv) por pesquisa de
                dados ou documentos; (v) por recuperação de documentos e outras
                solicitações do ESTABELECIMENTO; (vi) por serviços especiais;
                (vii) pela manutenção de ESTABELECIMENTO inativo ao SISTEMA ITI;
                (viii) pela operacionalização, administração e controle de cada
                bloqueio de créditos do ESTABELECIMENTO, decorrentes de ofícios
                e/ou mandados de penhora, originados de autoridades judiciárias;
                e (ix) por programas de incentivos e pacotes de PRODUTOS,
                objetivando incrementar a utilização dos CARTÕES e MEIOS DE
                PAGAMENTO junto aos ESTABELECIMENTOS.
              </Typography>
            </ListItem>
          </List>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>8</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              Para a cobrança dos valores devidos pelo ESTABELECIMENTO, o ITI
              poderá adotar, a seu exclusivo critério, qualquer das seguintes
              alternativas:
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                compensar o valor do débito com quaisquer outros créditos,
                presentes ou futuros, devidos ao ESTABELECIMENTO;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  b
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                realizar lançamentos a débito na CONTA DE PAGAMENTO vinculada
                para recebimento dos Recebíveis das TRANSAÇÕES do
                ESTABELECIMENTO, conforme venha a ser disponibilizado pelo ITI;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  c
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                permitir que o ESTABELECIMENTO, no caso de ausência de créditos
                a compensar ou na impossibilidade de lançamento a débito em
                conta de livre movimentação, efetue, desde que acordado com o
                ITI, o pagamento mediante cheque, ordem de pagamento, DOC, TED,
                boleto bancário ou depósito identificado; ou
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  d
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                efetuar cobrança, judicial ou extrajudicial, por conta própria
                ou através de escritório especializado.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  e
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                A falta, parcial ou total, ou o atraso do pagamento, nos prazos
                acordados no CONTRATO e respectivas alterações, poderá sujeitar
                o ESTABELECIMENTO ao pagamento dos seguintes encargos
                adicionais, sem prejuízo da inclusão dos débitos do
                ESTABELECIMENTO no cadastro de Pendências Financeiras (PEFIN)
                dos órgãos de proteção ao crédito: (i) atualização monetária com
                base no IPC/FGV ou, na falta deste, por outro índice que
                legalmente o substitua; e (ii) juros de 1% (um por cento) a.m.
                “pro rata die”.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  f
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O ESTABELECIMENTO está ciente que, mediante a adesão ao SISTEMA
                ITI, autoriza que a instituição da sua CONTA DE PAGAMENTO efetue
                lançamentos a débito, crédito e outros previstos neste CONTRATO,
                em sua CONTA DE PAGAMENTO ou DOMICÍLIO BANCÁRIO, independente de
                prévia consulta ou de qualquer ato ou formalidade legal ou
                documental. Caso a instituição da sua CONTA DE PAGAMENTO se
                declare impedido de dar cumprimento às ordens de débito
                emitidas, o ITI estará autorizado a reter o pagamento dos
                créditos até a respectiva compensação do débito. A autorização
                constante na cláusula 8.2 perdurará enquanto houver obrigações
                oriundas deste contrato.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  g
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O ESTABELECIMENTO se compromete a ressarcir o ITI, nas formas de
                cobrança descritas nesta Cláusula, de todo valor ou prejuízo
                comprovadamente sofrido pelo ITI, como exemplo, mas não se
                limitando de: (i) multas e/ou penalidades sofridas pelo
                ESTABELECIMENTO direcionadas o ITI; (II) valores de
                Processamento de Chargeback (Fee) aplicado pelas BANDEIRAS,
                pelos EMISSORES, ou pelas autoridades governamentais, (iii) em
                razão do descumprimento pelo ESTABELECIMENTO das obrigações
                legais ou regras e exigências previstas no presente CONTRATO.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  h
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Os valores cobrados serão acrescidos as atualizações financeiras
                necessárias à neutralização dos efeitos fiscais decorrentes do
                reconhecimento dessa receita (“ressarcimento”), bem como do
                recolhimento pelo ITI de quaisquer tributos municipais ou
                estaduais e os correspondentes acréscimos legais em que o
                contribuinte seja o ESTABELECIMENTO.
              </Typography>
            </ListItem>
          </List>
          <Typography variant="h2Terms" component="h2" mt={4}>
            PAGAMENTO DAS TRANSAÇÕES AO ESTABELECIMENTO
          </Typography>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>9</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              O ESTABELECIMENTO está ciente e autoriza o ITI a fazer o pagamento
              do VALOR LÍQUIDO das TRANSAÇÕES, na forma e prazos definidos pelo
              ITI, mediante crédito do respectivo valor em sua CONTA DE
              PAGAMENTO, bem como por qualquer outro meio de pagamento admitido
              por este CONTRATO ou acordado entre as Partes, incluída a
              compensação, desde que a TRANSAÇÃO tenha sido realizada de acordo
              com o previsto neste CONTRATO.
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O prazo de pagamento será contado a partir da data da
                atualização do RESUMO DE VENDAS no APLICATIVO ITI ao
                ESTABELECIMENTO ou a partir da data do recebimento do protocolo
                de TRANSAÇÕES efetuadas na modalidade TRANSMISSÃO DE ARQUIVOS ou
                TRANSAÇÃO OFF-LINE.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  b
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O ITI poderá instituir o PRAZO DE PAGAMENTO DIFERENCIADO DE
                VENDAS relativo às TRANSAÇÕES, ficando a seu exclusivo critério,
                antecipar ou não os valores de acordo com o perfil do
                ESTABELECIMENTO.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  c
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O prazo de pagamento pelas TRANSAÇÕES poderá ser alterado a
                exclusivo critério do ITI, em especial em razão de fraudes,
                suspensão ou bloqueio das TRANSAÇÕES, a fim de garantir o
                cumprimento de suas obrigações previstas neste CONTRATO e a
                segurança do mercado de meios de pagamentos. Em caso de
                suspensão o ITI poderá reter, nos moldes da regulamentação
                vigente, os recursos financeiros oriundos das TRANSAÇÕES com
                objetivo de efetivar as devidas compensações de eventuais
                débitos oriundos deste Contrato.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  d
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Se a data prevista para o crédito do VALOR LÍQUIDO da TRANSAÇÃO
                recair em feriado ou em dia de não funcionamento bancário na
                sede do ITI, o pagamento será realizado no primeiro dia útil
                subsequente.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  e
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                A falta, parcial ou total, ou o atraso do pagamento nos prazos
                acordados, poderá sujeitar o ITI ao pagamento dos seguintes
                encargos adicionais:
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  f
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                (a) atualização monetária com base no IPC/FGV ou, na falta
                deste, por outro índice que legalmente o substitua; e
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  g
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                juros de 1% (um por cento) a.m. “pro rata die”.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  h
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O ITI poderá disponibilizar ao ESTABELECIMENTO o extrato das
                TRANSAÇÕES, mediante acesso por meio do APLICATIVO ITI.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  i
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Caso o ESTABELECIMENTO tenha contratado operação de crédito
                garantida por recebíveis de arranjo de pagamento por meio do
                sistema de registro recebíveis, o DOMICÍLIO BANCÁRIO poderá
                reter, nos moldes da regulamentação vigente, os recursos
                financeiros oriundos de operações de antecipação que não sejam
                de livre movimentação. Após isso, os valores poderão ser
                liberados em sua Conta de Pagamento ou utilizados para amortizar
                saldo devedor da operação de crédito contratada.
              </Typography>
            </ListItem>
          </List>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>10</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              Nos casos em que se verificar a iliquidez, insolvência, pedido de
              recuperação judicial ou extrajudicial, estado pré-falimentar,
              encerramento de atividades ou qualquer outra hipótese em que ficar
              caracterizada a dificuldade do ESTABELECIMENTO em cumprir suas
              obrigações contratuais e/ou legais, o ITI reserva-se o direito de
              reter os créditos a ele devidos, a fim de garantir o cumprimento
              de suas obrigações previstas neste CONTRATO e a segurança do
              mercado de meios de pagamentos.
            </Typography>
          </ListItem>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>11</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              O ESTABELECIMENTO terá o prazo de 45 (quarenta e cinco) dias, a
              contar da data prevista para a realização do pagamento pelo ITI,
              para apontar qualquer eventual divergência em relação aos valores
              pagos e/ou debitados do ESTABELECIMENTO. Findo tal prazo, não
              caberá nenhuma outra reclamação por parte do ESTABELECIMENTO,
              ocorrendo a quitação automática e definitiva quanto aos referidos
              valores.
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Excepcionalmente, o ITI, a seu exclusivo critério, mediante
                cobrança de tarifa específica de pesquisa, poderá, findo o prazo
                estipulado acima e respeitados os prazos de cobrança estipulados
                em Lei, verificar a existência de divergência nos créditos ou
                débitos apontados pelo ESTABELECIMENTO e, conforme o caso,
                efetuar os créditos ou débitos em sua CONTA DE PAGAMENTO.
              </Typography>
            </ListItem>
          </List>
          <Typography variant="h2Terms" component="h2" mt={4}>
            EQUIPAMENTOS
          </Typography>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>12</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              O ITI fornece ao ESTABELECIMENTO, os EQUIPAMENTOS de sua
              propriedade bem como homologa os EQUIPAMENTOS de propriedade do
              ESTABELECIMENTO ou de terceiros para integração ao SISTEMA ITI.
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Os EQUIPAMENTOS são fornecidos ao ESTABELECIMENTO com as devidas
                orientações para manuseio. Em caso de dúvidas, o ESTABELECIMENTO
                tem à disposição o manual de utilização disponibilizado pelo
                fabricante do EQUIPAMENTO e/ou disponível no APLICATIVO ITI, ou
                ainda poderá entrar em contato com a CENTRAL DE ATENDIMENTO.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  b
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                A entrega e desinstalação do EQUIPAMENTO pode ser realizada pelo
                ITI ou por terceiros por ela indicados, no endereço cadastrado
                no ITI.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  c
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O ESTABELECIMENTO deve se responsabilizar pelo tipo de
                EQUIPAMENTO que seja obrigado a utilizar em virtude da
                legislação específica, bem como pelo pagamento de todos os
                tributos e contribuições decorrentes da utilização do
                EQUIPAMENTO, não se responsabilizando o ITI por qualquer
                situação que venha a ser imposta em função da escolha e
                utilização do EQUIPAMENTO pelo ESTABELECIMENTO, ficando o
                ESTABELECIMENTO ciente de que, em caso de desrespeito ao aqui
                previsto, será o responsável e arcará com os riscos decorrentes
                de tal inobservância, dentre os quais se incluem, mas não se
                limitam, aos riscos legais e fiscais.
              </Typography>
            </ListItem>
          </List>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>13</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              Em relação aos EQUIPAMENTOS, o ESTABELECIMENTO obriga-se a:
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                conferir, no momento da instalação ou manutenção do EQUIPAMENTO,
                os dados cadastrais impressos no COMPROVANTE DE VENDA emitido
                pelo EQUIPAMENTO;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  b
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                usar os EQUIPAMENTOS e PERIFÉRICOS corretamente, respondendo
                pelos custos de instalação, conserto e manutenção dos
                EQUIPAMENTOS de sua propriedade, caso aplicável, bem como, pelos
                custos de instalação, conserto e manutenção dos EQUIPAMENTOS de
                propriedade do ITI, na hipótese de sua quebra ou falha ou
                decorrentes de uso e/ou instalação e/ou manuseio indevidos por
                seus empregados ou prepostos, sendo que a instalação, conserto e
                manutenção serão efetuados pelo ITI;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  c
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                manter os EQUIPAMENTOS no local em que o ESTABELECIMENTO exerce
                suas atividades conforme cadastrado no ITI, devendo comunicar
                previamente o ITI em caso de qualquer mudança, não podendo
                ceder, sublocar, transferir ou alienar, total ou parcialmente,
                os EQUIPAMENTOS de propriedade do ITI, sem a anuência do ITI,
                ficando o ESTABELECIMENTO ciente de que, em caso de desrespeito
                ao aqui previsto, será o responsável e arcará com os riscos
                decorrentes de tal inobservância, dentre os quais se incluem,
                mas não se limitam, aos riscos legais e fiscais;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  d
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                adotar todas as providências e cautelas necessárias para manter
                a guarda, a integridade e a perfeita conservação e funcionamento
                dos EQUIPAMENTOS de propriedade do ITI, sendo vedado ao
                ESTABELECIMENTO realizar qualquer reparação ou modificação em
                tais EQUIPAMENTOS, comprometendo-se a comunicar imediatamente ao
                ITI qualquer intervenção ou violação por terceiros de quaisquer
                dos seus direitos relativamente ao EQUIPAMENTO;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  e
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                reconhecer e concordar que os softwares aplicativos cedidos ou
                inseridos nos EQUIPAMENTOS, de forma gratuita ou onerosa pelo
                ITI, são de titularidade do ITI ou de terceiros e incorporam a
                propriedade intelectual do ITI ou de tais terceiros, podendo o
                ESTABELECIMENTO apenas fazer uso deles, comprometendo-se a não
                ceder, copiar, alterar, modificar, adaptar, manipular,
                adicionar, descompilar, decompor ou efetuar qualquer conversão
                dos softwares, sendo vedado também o uso de engenharia reversa
                ou utilização para fins diversos dos previstos no presente
                CONTRATO, sob pena de imediata rescisão do CONTRATO, sem
                prejuízo do ressarcimento por eventuais perdas e danos
                acarretados;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  f
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                assumir a responsabilidade pelo pagamento do valor do
                EQUIPAMENTO, nos seguintes casos: furto, roubo, perda total ou
                parcial, incêndio, destruição total ou parcial, falta de
                solicitação de assistência técnica, descuido no manuseio,
                retenção ou qualquer outro fato ou evento que impossibilite,
                dificulte ou prejudique o direito de propriedade sobre os
                EQUIPAMENTOS por parte do ITI além da responsabilidade pela
                apreensão, remoção, bloqueio, lacre, confisco ou leilão dos
                EQUIPAMENTOS por quaisquer órgãos ou autoridades, desde que
                tenha dado causa a tais eventos, e pelo custo de reparo,
                substituição ou liberação, bem como eventuais multas e
                penalidades impostas;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  g
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                em casos de furto ou roubo, incêndio ou destruição total ou
                parcial, o ESTABELECIMENTO deverá apresentar ao ITI o respectivo
                Boletim de Ocorrência ou laudo específico, onde deve constar,
                obrigatoriamente, dados que identifiquem o EQUIPAMENTO;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  h
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                comunicar imediatamente ao ITI caso haja suspeita de fraude ou
                fraude confirmada no EQUIPAMENTO; e
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  i
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                quando aplicável, providenciar o descarte adequado de todos os
                resíduos oriundos da presente relação contratual, seja em razão
                da manutenção ou substituição de bens, providenciando a
                descaracterização de quaisquer documentos, mídias e sinais de
                identificação do ITI encontrados nos resíduos, adotando todas as
                medidas necessárias para conferir aos resíduos a destinação
                final ambientalmente adequada, condizente com a sua natureza e
                com a legislação em vigor tal como mas não limitada à Política
                Nacional de Resíduos Sólidos, instituída pela Lei nº.
                12.305/2010 e regulamentada pelo Decreto nº. 7.404/2010, e
                independentemente de culpa, ressarcirá o ITI por qualquer
                quantia que este incorra ou seja compelido a pagar em razão de
                descumprimento de Legislação Socioambiental.
              </Typography>
            </ListItem>
          </List>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>14</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              O ESTABELECIMENTO pode utilizar EQUIPAMENTO, software, hardware e
              materiais relacionados, de sua propriedade ou de terceiros por ele
              contratados, desde que homologados pelo ITI, e nesta hipótese,
              quaisquer substituições ou alterações relativas aos EQUIPAMENTOS,
              softwares, hardwares devem ser submetidas à aprovação prévia do
              ITI.
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O ESTABELECIMENTO é o único responsável pela adequação dos
                EQUIPAMENTOS, software, hardware e materiais de sua propriedade
                ou de terceiros, utilizados nas operações do SISTEMA ITI bem
                como pela conexão, instalação, manuseio, manutenção e
                abastecimento dos mesmos, e ainda pelo treinamento e obtenção
                das necessárias licenças e autorizações, respondendo
                isoladamente por esses custos, ou por quaisquer eventos, ônus ou
                encargos decorrentes da utilização inadequada ou ineficiente de
                tais EQUIPAMENTOS, software, hardware e materiais relacionados.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  b
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O ESTABELECIMENTO deve cumprir e manter-se aderente às regras
                das BANDEIRAS e às regras do mercado de meios de pagamento,
                devendo, quando for o caso, adequar os padrões de funcionamento
                de seus EQUIPAMENTOS, aos novos padrões, nos prazos e condições
                estabelecidas pelo ITI. Em caso de não adequação, o ITI fica
                isenta de quaisquer responsabilidades relativas a indícios ou
                suspeitas de fraude, em todas as TRANSAÇÕES com CARTÕES e MEIOS
                DE PAGAMENTO, devendo o ESTABELECIMENTO arcar com quaisquer
                prejuízos que comprovadamente vierem a ser ocasionados ao ITI,
                ao próprio ESTABELECIMENTO, a terceiros ou ainda eventuais
                multas cobradas pelas BANDEIRAS.
              </Typography>
            </ListItem>
          </List>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>15</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              O ITI providenciará a manutenção do EQUIPAMENTO, se houver
              necessidade ou em caso de solicitação do ESTABELECIMENTO. A
              manutenção preventiva e corretiva só será realizada pelo ITI nos
              EQUIPAMENTOS de sua propriedade, sendo de responsabilidade do
              ESTABELECIMENTO a manutenção do EQUIPAMENTO de sua propriedade.
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                A manutenção pode ser realizada remotamente e, havendo
                necessidade de reparo físico, será feito o atendimento no local
                da instalação do EQUIPAMENTO, sendo atendido pelo ITI ou por
                terceiros por ela indicados, dentro do horário de funcionamento
                do ESTABELECIMENTO. O ITI poderá, ainda, promover a troca do
                EQUIPAMENTO após avaliação das condições deste, mesmo que
                remota, devendo comunicar o ESTABELECIMENTO a respeito dessa
                troca, devendo este, por sua vez, realizar a entrega do
                EQUIPAMENTO via Correios ou outra empresa indicada, conforme
                orientação do ITI.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  b
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O ESTABELECIMENTO deve facilitar o acesso dos técnicos aos
                locais de instalação dos EQUIPAMENTOS.
              </Typography>
            </ListItem>
          </List>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>16</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              O ESTABELECIMENTO reconhece o direito do ITI de efetuar
              interrupções no fornecimento dos serviços e desde já declara que
              tem conhecimento pleno de que os serviços poderão, eventualmente,
              ser afetados, ou temporariamente interrompidos por motivos
              técnicos, em razão de reparo, manutenção ou troca de EQUIPAMENTO.
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Nas hipóteses descritas na cláusula 15 deste Contrato, o ITI não
                garante que seus serviços ficarão sem interrupção ou que estarão
                livres de erros e não ressarcirá o ESTABELECIMENTO de eventuais
                danos decorrentes de tais interrupções.
              </Typography>
            </ListItem>
          </List>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>17</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              O ITI não se responsabiliza por eventuais falhas, atrasos ou
              interrupções na prestação de serviço decorrente de caso fortuito
              ou motivos de força maior, nos termos da cláusula 37 deste
              CONTRATO, bem como por limitações impostas por parte do Poder
              Público ou da atuação de operadoras de serviço de telecomunicações
              interconectadas à rede do ESTABELECIMENTO, ou, ainda, por má
              utilização do serviço pelo ESTABELECIMENTO ou por qualquer outro
              fato alheio ao ITI.
            </Typography>
          </ListItem>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>18</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              O ESTABELECIMENTO pode solicitar a qualquer momento o cancelamento
              do seu cadastro e a desinstalação dos EQUIPAMENTOS. Ao término ou
              rescisão do CONTRATO, o ESTABELECIMENTO compromete-se a devolver o
              EQUIPAMENTO de propriedade do ITI, com seus respectivos
              PERIFÉRICOS, no mesmo estado em que os recebeu, salvo desgaste
              natural pelo uso.
            </Typography>
          </ListItem>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>19</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              O ITI poderá oferecer ao ESTABELECIMENTO a oferta do EQUIPAMENTO
              para locação em um número determinado de parcelas de aluguel,
              sendo certo que, mesmo após o término do pagamento dessas
              parcelas, os EQUIPAMENTOS permanecerão de propriedade do ITI e
              mantidos no ESTABELECIMENTO durante a vigência deste CONTRATO a
              título de comodato.
            </Typography>
          </ListItem>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>20</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              Fica certo e ajustado que em nenhum momento, durante e após a
              vigência deste CONTRATO, haverá a transferência de titularidade
              dos EQUIPAMENTOS do ITI ao ESTABELECIMENTO.
            </Typography>
          </ListItem>
          <Typography variant="h2Terms" component="h2" mt={4}>
            REALIZAÇÃO DAS TRANSAÇÕES
          </Typography>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>21</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              No momento da realização da TRANSAÇÃO, o ESTABELECIMENTO deve,
              obrigatoriamente:
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                verificar se o prazo de validade do CARTÃO não está vencido ou
                se o CARTÃO não está adulterado ou rasurado;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  b
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                conferir, em casos de CARTÃO sem CHIP e/ou quando não houver
                digitação de SENHA, o nome e a assinatura do PORTADOR lançada no
                COMPROVANTE DE VENDA, com o nome e a assinatura constantes do
                CARTÃO ou documento de identificação do PORTADOR;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  c
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                apresentar ao PORTADOR as condições de sua TRANSAÇÃO que constam
                no EQUIPAMENTO para que o PORTADOR possa conferir referidas
                condições e realizar a compra com o CARTÃO;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  d
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                comparar os últimos 4 (quatro) dígitos do número do CARTÃO, com
                os dígitos impressos no COMPROVANTE DE VENDAS;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  e
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                conferir a existência do código de segurança, formado por três
                dígitos, no verso do CARTÃO;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  f
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                observar as características de segurança utilizadas pelas
                BANDEIRAS, como hologramas tridimensional, marcas de segurança,
                letras estilizadas, dentre outras;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  g
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                cumprir todos os procedimentos, padrões e normas exigidas neste
                CONTRATO, sendo que o ITI não se responsabilizará pelas
                TRANSAÇÕES concluídas em desacordo com o aqui disposto; e
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  h
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                orientar os PORTADORES sobre a melhor condição de pagamento para
                aquisição de bens e/ou serviços, de forma clara e objetiva, a
                fim de que estes façam opção consciente do uso do CARTÃO.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  i
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Nas TRANSAÇÕES realizadas com CARTÕES com CHIP, o
                ESTABELECIMENTO deve efetuar a leitura do CHIP no EQUIPAMENTO.
              </Typography>
            </ListItem>
          </List>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>22</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              O ESTABELECIMENTO deve utilizar os EQUIPAMENTOS somente para
              realizar TRANSAÇÕES regulares, estritamente de acordo com normas e
              condições deste CONTRATO, sendo vedado ao ESTABELECIMENTO aceitar
              os CARTÕES e MEIOS DE PAGAMENTO em TRANSAÇÕES fictícias ou
              simuladas, tais como, mas não limitadas a:
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                realizar TRANSAÇÕES com a finalidade de garantia ou caução, sem
                a devida autorização do ITI;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  b
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                efetuar TRANSAÇÕES não relacionadas com o ramo de atividade do
                ESTABELECIMENTO cadastrado no ITI;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  c
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                desmembrar uma única venda em duas ou mais TRANSAÇÕES no mesmo
                CARTÃO;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  d
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                fornecer ou restituir aos PORTADORES, por qualquer motivo,
                quantias (moeda nacional ou estrangeira, cheques, ordens de
                pagamento ou títulos de crédito), salvo nas hipóteses previstas
                pelo ITI neste CONTRATO;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  e
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                qualquer outro tipo ou forma de TRANSAÇÕES consideradas
                irregulares e decorrentes de atividades consideradas ilegais ou
                indesejáveis, conforme estabelecido pelo ITI, pelos EMISSORES e
                pelas BANDEIRAS;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  f
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                aceitar CARTÃO de titularidade de terceiros;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  g
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                utilizar os EQUIPAMENTOS, sem autorização prévia do ITI, em
                outro local que não o seu endereço cadastrado com o ITI ou
                utilizar o EQUIPAMENTO de outro ESTABELECIMENTO.
              </Typography>
            </ListItem>
          </List>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>23</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              Estão sujeitas ao não processamento ou não pagamento as TRANSAÇÕES
              irregularmente realizadas pelo ESTABELECIMENTO, sob quaisquer
              modalidades, de forma conivente ou não, em circunstâncias que
              caracterizem indícios ou suspeita de fraude que objetivem a
              obtenção de vantagens ilícitas ou estejam em desacordo com este
              CONTRATO. Os eventos mencionados nesta cláusula estão sujeitos ao
              ressarcimento, pelo ESTABELECIMENTO, nos termos deste CONTRATO.
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Desta forma, a TRANSAÇÃO, mesmo após ser autorizada e
                processada, poderá ser cancelada pelo ITI, a qualquer tempo, se
                for constatada a ocorrência de irregularidades e/ou de
                circunstâncias que caracterizem indícios ou suspeita de fraudes.
              </Typography>
            </ListItem>
          </List>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>24</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              O ESTABELECIMENTO está ciente que será descredenciado caso atinja
              um percentual de TRANSAÇÕES suspeitas ou irregulares conforme
              definição das BANDEIRAS ou regras de monitoramento de fraude do
              ITI, bem como se atingir índices de CONTESTAÇÃO DE TRANSAÇÕES além
              dos limites estabelecidos pelo ITI e/ou pelas BANDEIRAS, exceto se
              o ITI entender que é possível reverter a situação.
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Ocorridas as hipóteses acima, sem prejuízo de determinadas
                obrigações a serem assumidas pelo ESTABELECIMENTO, o
                ESTABELECIMENTO deverá ressarcir o ITI dos prejuízos causados e
                penalidades aplicadas, pelas formas de cobrança previstas no
                CONTRATO.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  b
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O ESTABELECIMENTO está ciente e concorda com os métodos que o
                ITI vier a adotar para identificar e prevenir fraudes e práticas
                ilícitas, comprometendo-se o ESTABELECIMENTO a monitorar e
                orientar seus funcionários, bem como cooperar e colaborar,
                principalmente no fornecimento das informações solicitadas, sob
                pena de ressarcimento, pelo ESTABELECIMENTO, nos termos deste
                CONTRATO e rescisão do mesmo.
              </Typography>
            </ListItem>
          </List>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>25</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              O ESTABELECIMENTO, na consecução de suas atividades e realização
              de TRANSAÇÕES, não poderá utilizar recursos tecnológicos,
              hardware, software ou qualquer outra tecnologia não homologada ou
              não autorizada pelo ITI e/ou que venha a trazer riscos de fraude
              ou segurança para o SISTEMA ITI e que estejam em desacordo com as
              normas e padrões internacionais da indústria de CARTÕES.
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                As TRANSAÇÕES, no âmbito do SISTEMA ITI, deverão ser capturadas,
                processadas, roteadas, liquidadas e compensadas apenas pelo ITI,
                ou por Câmara de Liquidação autorizada pelo Banco Central e
                contratada pelo ITI, e também devem estar em consonância com
                normas, procedimentos e autorizações da BANDEIRA e do mercado de
                meios de pagamento.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  b
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Este CONTRATO não é o documento suficiente para regular toda a
                relação entre o ITI e ESTABELECIMENTOS que atuem como
                SUBCREDENCIADORES ou com qualquer atuação na intermediação da
                relação de pagamento. Desta forma, ESTABELECIMENTOS que queiram
                participar na intermediação das TRANSAÇÕES de terceiros deverão
                informar o ITI e assinar documento específico.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  c
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O descumprimento desta cláusula pelo ESTABELECIMENTO autorizará
                o ITI a rescindir este CONTRATO por justa causa, nos moldes da
                cláusula 38, sem prejuízo do ressarcimento pelo ESTABELECIMENTO
                das perdas e danos resultantes para o ITI.
              </Typography>
            </ListItem>
          </List>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>26</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              O ESTABELECIMENTO é o exclusivo responsável por solucionar,
              diretamente com os PORTADORES, toda e qualquer eventual
              controvérsia sobre as características, qualidade, quantidade,
              propriedades, origem, preço, funcionamento, garantias, defeitos
              e/ou avarias dos bens e produtos adquiridos e/ou serviços
              prestados, incluindo casos de defeito ou devolução, problemas de
              entrega etc.
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O ITI, as BANDEIRAS e o EMISSOR são isentos de quaisquer
                responsabilidades convencionais ou legais em relação aos fatos
                mencionados nesta cláusula, inclusive com relação ao Código de
                Proteção e Defesa do Consumidor.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  b
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O ESTABELECIMENTO está ciente e concorda expressamente que a
                responsabilidade do ITI limita-se à execução das obrigações
                descritas neste CONTRATO, sendo certo que quaisquer obrigações
                ou ônus decorrentes, direta ou indiretamente, de quaisquer
                procedimentos administrativos ou judiciais, resultantes de
                eventual descumprimento de qualquer obrigação principal ou
                acessória por parte do ESTABELECIMENTO e promovida por qualquer
                órgão federal, estadual ou municipal competente, deverão ser
                suportados integralmente pelo ESTABELECIMENTO.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  c
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Na hipótese de o ITI despender quaisquer valores em razão do
                disposto na cláusula acima, será aplicado o procedimento de
                cobrança previsto neste CONTRATO.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  d
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O ESTABELECIMENTO autoriza expressamente o ITI, desde já, a lhe
                repassar quaisquer despesas legítimas para o cumprimento de
                ordem de terceiro com relação ao ESTABELECIMENTO, incluindo, sem
                limitar-se, atendimento de ofícios judiciais, bloqueios,
                penhoras e arrestos.
              </Typography>
            </ListItem>
          </List>
          <Typography variant="h2Terms" component="h2" mt={4}>
            TRANSAÇÕES SEM CARTÃO PRESENTE
          </Typography>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>27</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              As TRANSAÇÕES SEM CARTÃO PRESENTE são aquelas realizadas pelos
              ESTABELECIMENTOS, mediante a digitação do número do cartão nos
              EQUIPAMENTOS sem autenticação da TRANSAÇÃO pelo Portador, por
              exemplo, mas não se limitando aos casos de transações OFF-LINE,
              VENDAS MANUAIS, VENDAS DIGITADAS ou capturadas por Link de
              Pagamento.
            </Typography>
          </ListItem>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>28</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              O ESTABELECIMENTO deve ser previamente autorizado pelo ITI para
              realizar TRANSAÇÃO SEM CARTÃO PRESENTE, assumindo total
              responsabilidade pela TRANSAÇÃO, inclusive em caso de CONSTESTAÇÃO
              DA TRANSAÇÃO e CANCELAMENTO DAS TRANSAÇÕES, que serão sempre
              debitadas dos ESTABELECIMENTOS, sem prejuízo do previsto na
              cláusula 24 do presente CONTRATO.
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Conforme informado no momento da autorização acima prevista,
                nessa modalidade de TRANSAÇÃO, caso o PORTADOR não reconheça ou
                discorde do valor da TRANSAÇÃO perante o EMISSOR, observado as
                disposições abaixo, o ITI deixará de efetuar o pagamento do
                valor da TRANSAÇÃO ao ESTABELECIMENTO ou, caso já o tenha feito,
                poderá adotar, a seu exclusivo critério, quaisquer das formas de
                cobrança previstas neste CONTRATO, ainda que o ESTABELECIMENTO
                apresente qualquer documento que comprove a realização da
                TRANSAÇÃO, inclusive o COMPROVANTE DE VENDA com ou sem
                assinatura do PORTADOR.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  b
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                As Partes reconhecem que o ITI, a seu exclusivo critério, poderá
                disponibilizar novas modalidades de autorização de TRANSAÇÕES.
              </Typography>
            </ListItem>
          </List>
          <Typography variant="h2Terms" component="h2" mt={4}>
            LINK DE PAGAMENTO
          </Typography>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>29</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              O LINK DE PAGAMENTO é uma solução de pagamento disponibilizada
              pelo ITI, que possibilita a captura das Transações sem Cartão
              Presente, permitindo a conexão direta entre o ESTABELECIMENTO e o
              Portador sem a necessidade de serviços de armazenamento de dados e
              gestão de pagamentos, independente da contratação de um
              equipamento ou hospedagem de um site para execução desses
              serviços.
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O ITI não assume qualquer responsabilidade, nem outorga qualquer
                garantia com relação aos bens e/ou serviços oferecidos via Link
                de Pagamento, tampouco é responsável pela entrega correta e
                tempestiva dos bens ou serviços que vierem a ser
                disponibilizados pelo ESTABELECIMENTO, e adquiridos pelos
                Portadores.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  b
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O Link de Pagamento está integrado com serviço de ANTIFRAUDE
                para as transações realizadas por meio do Link de Pagamento, se
                trata de uma ferramenta licenciada de forma intransferível e não
                exclusiva por terceiro ao ITI que, visa mitigar a incidência de
                Fraudes nessa modalidade de pagamento.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  c
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                A utilização da funcionalidade Link de Pagamento com Antifraude
                deverá ser realizada pelo ESTABELECIMENTO, por meio dos canais
                digitais disponibilizados pelo ITI ou APLICATIVO ITI, e poderá
                ser submetida a análise prévia realizada com base no perfil de
                cadastro, em virtude da modalidade de venda das TRANSAÇÕES SEM
                CARTÃO PRESENTE.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  d
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                A habilitação da funcionalidade do Link de Pagamento ou do
                Antifraude poderá ser formalizada por meio de adesão a este
                Contrato, e em caso de utilização do Link de Pagamento, o
                ESTABELECIMENTO concorda com todas as condições estabelecidas
                para o produto.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  e
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O Antifraude tem a finalidade análise de riscos e negócios do
                ESTABELECIMENTO em relação as Transações realizadas pelo Link de
                Pagamento. O Antifraude não elimina as responsabilidades do
                Estabelecimento previstas nesta cláusula inerentes às transações
                fraudulentas, canceladas ou estornadas na modalidade TRANSAÇÕES
                SEM CARTÃO PRESENTE.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  f
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O ESTABELECIMENTO é o único responsável pelo envio do Link de
                Pagamento aos Portadores com Serviços de ANTIFRAUDE.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  g
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O ESTABELECIMENTO está ciente que o ITI enviará ao terceiro os
                dados cadastrais do ESTABELECIMENTO e informações necessárias
                para a utilização do Antifraude, inclusive dos seus Portadores,
                nos termos da Política de Privacidade do ITI. Para viabilidade
                da análise de fraude, serão compartilhadas as informações
                inseridas pelo Portador no check-out do Link de Pagamento, como
                exemplo, mas não se limitando, aos números do cartão, nome, CPF,
                endereço, número de telefone, endereço, e-mail, bem como, o
                dispositivo eletrônico do titular do cartão que é usado para
                concluir as transações do Estabelecimento.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  h
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                As informações pessoais do Portador são usadas para (i) para
                fins de segurança e prevenção à fraude, inclusive, para
                confirmar a identidade do Portador com terceiros conforme
                Política de Privacidade do ITI, com objetivo de mitigar a fraude
                em nome do ESTABELECIMENTO e do seu Portador e (ii) apoiar a
                criação e aprimoramento de ferramentas e modelos de segurança e
                prevenção de fraude para uso pelo ESTABELECIMENTO ou qualquer
                outro cliente do Terceiro.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  i
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Todos os tributos, tais como, mas não se limitando a, impostos,
                taxas e contribuições, incidentes ou que venham a incidir sobre
                as operações decorrentes do Link de Pagamento ou do Antifraude,
                serão suportados pela parte definida como contribuinte nos
                termos da legislação aplicável, não lhe assistindo direito a
                qualquer reembolso pela outra parte, seja a que título for.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  j
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                A concessão dos produtos de Recebimento Diferenciado de Vendas
                nas Transações por meio do Link de Pagamento, poderá ser
                disponibilizada a exclusivo critério do ITI.
              </Typography>
            </ListItem>
          </List>
          <Typography variant="h2Terms" component="h2" mt={4}>
            CONTESTAÇÃO E CANCELAMENTO DE TRANSAÇÕES
          </Typography>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>30</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              Na hipótese de CONTESTAÇÃO DA TRANSAÇÃO, o ITI receberá tal
              informação do EMISSOR e solicitará ao ESTABELECIMENTO, quando
              cabível, a comprovação da TRANSAÇÃO, sendo aplicáveis as condições
              abaixo.
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O ESTABELECIMENTO deve, sempre que lhe for solicitado, enviar ao
                ITI cópias legíveis e sem rasuras dos COMPROVANTES DE VENDAS,
                assinados ou não pelos PORTADORES, bem como qualquer
                documentação adicional que comprove a TRANSAÇÃO, dentro do prazo
                de 5 (cinco) dias a contar da data da solicitação do ITI. Se o
                ESTABELECIMENTO não apresentar a cópia dos documentos
                mencionados acima no prazo fixado, estará sujeito ao não
                pagamento ou ao débito da respectiva TRANSAÇÃO.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  b
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Para cumprimento do disposto acima, o ESTABELECIMENTO deve
                manter em arquivo a via original assinada ou não dos
                COMPROVANTES DE VENDAS, bem como de qualquer documentação
                adicional que comprove a TRANSAÇÃO, pelo prazo indicado nos
                arranjos das BANDEIRAS, a contar da data da TRANSAÇÃO.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  c
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Os dados impressos no COMPROVANTE DE VENDAS têm vida útil de até
                5 (cinco) anos, conforme informado no verso do COMPROVANTE DE
                VENDAS, desde que se evite o contato direto com plásticos,
                solventes ou produtos químicos, bem como a exposição ao calor e
                umidade excessivos, à luz solar e à iluminação de lâmpadas
                fluorescentes. Para maior segurança, recomenda-se que o
                ESTABELECIMENTO tire cópias dos COMPROVANTES DE VENDAS bem como
                de qualquer documentação de comprovação da entrega dos bens
                adquiridos ou da prestação de serviços realizada.
              </Typography>
            </ListItem>
          </List>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>31</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              Sem prejuízo do disposto no item 31.44 abaixo, que prevalecerá se
              conflitante, o ESTABELECIMENTO pode solicitar o CANCELAMENTO DAS
              TRANSAÇÕES na modalidade crédito no prazo de 45 (quarenta e cinco)
              dias e o CANCELAMENTO DAS TRANSAÇÕES na modalidade débito no prazo
              de 7 (sete) dias, a contar da data da realização da respectiva
              TRANSAÇÃO. Findo tais prazos, não caberá nenhuma outra reclamação
              por parte do ESTABELECIMENTO, ocorrendo a quitação automática e
              definitiva quanto aos referidos valores.
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Se o ESTABELECIMENTO solicitar o cancelamento das TRANSAÇÕES
                antes do recebimento do valor da TRANSAÇÃO, a TRANSAÇÃO será
                cancelada e não será efetuado o respectivo pagamento.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  b
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Se o ESTABELECIMENTO solicitar o cancelamento das TRANSAÇÕES e o
                pagamento da TRANSAÇÃO já tiver sido efetuado ao
                ESTABELECIMENTO, total ou parcialmente, mesmo que por
                antecipação, o ITI deverá ser restituída do valor da TRANSAÇÃO
                mediante compensação com valores de TRANSAÇÕES a serem
                liquidadas ou realização de depósito bancário pelo
                ESTABELECIMENTO.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  c
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Na hipótese das TRANSAÇÕES pendentes realizadas através do
                PDV/TEF o ESTABELECIMENTO deve confirmá-las ou desfazê-las, no
                prazo previamente informado pelo ITI, por uma das formas de
                comunicação prevista neste CONTRATO. O descumprimento desse
                prazo pelo ESTABELECIMENTO implicará o desfazimento automático
                da respectiva TRANSAÇÃO, independentemente de qualquer
                comunicação por parte do ITI, devendo o ESTABELECIMENTO
                ressarcir o ITI pelos prejuízos causados em decorrência das
                TRANSAÇÕES pendentes.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  d
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O ITI possibilitará ao ESTABELECIMENTO o CANCELAMENTO das
                TRANSAÇÕES nos prazos indicados pelas regras de BANDEIRAS, de
                acordo com o ramo de atividade do ESTABELECIMENTO. Referidos
                prazos estarão descritos e/ou serão informados nos canais
                disponibilizados para efetivação CANCELAMENTO, bem como
                encontram-se disponíveis no APLICATIVO ITI.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  e
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Em caso de constituição de Ônus nas unidades de recebíveis
                vinculadas ao ESTABELECIMENTO, o CANCELAMENTO dessas TRANSAÇÕES
                relacionadas com tais unidades de recebíveis não será
                operacionalizado pelo ITI.
              </Typography>
            </ListItem>
          </List>
          <Typography variant="h2Terms" component="h2" mt={4}>
            NEGOCIAÇÃO DOS CRÉDITOS DOS ESTABELECIMENTOS
          </Typography>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>32</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              O ESTABELECIMENTO reconhece e concorda, expressamente, que serão
              ineficazes e sem efeito, não produzindo nenhuma consequência
              relativamente ao ITI, a caução, cessão ou transferência de
              titularidade, negociações envolvendo quaisquer títulos de crédito,
              ou o oferecimento em garantia dos créditos decorrentes de
              TRANSAÇÕES, salvo na hipótese de prévia e escrita concordância do
              ITI diretamente ao ESTABELECIMENTO
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                ou pelas regras do sistema de registro recebíveis, por meio de
                uma instituição bancária que o represente ou pelo sistema de
                registro recebíveis, oportunidade na qual poderão ser cobradas
                pelo ITI, taxas e/ou tarifas então vigentes para o serviço
                prestado.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  b
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O ESTABELECIMENTO autoriza que o ITI constitua Ônus em suas
                unidades de recebíveis por meio do sistema de registro de
                recebíveis, com a finalidade de garantia das operações ou
                obrigações advindas deste Contrato.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  c
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O ESTABELECIMENTO autoriza o ITI e outras entidades do Grupo
                Itaú Unibanco a acessarem as suas TRANSAÇÕES realizadas em todas
                as credenciadoras ou subcredenciadoras, por meio do sistema de
                registro recebíveis, a fim de poderem oferecer produtos e
                serviços de crédito, credenciamento ou conciliação. O
                ESTABELECIMENTO poderá, a qualquer momento, revogar essa
                autorização por meio dos Canais de Atendimento.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  d
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Caso o ESTABELECIMENTO tenha contratado operação de crédito
                garantida por recebíveis de arranjo de pagamento em seu
                DOMICÍLIO BANCÁRIO ou por meio da CONTA DE PAGAMENTO, o ITI
                poderá reter, nos moldes da regulamentação vigente, os recursos
                financeiros oriundos de operações de antecipação que não sejam
                de livre movimentação.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  e
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O ESTABELECIMENTO autoriza que a instituição do seu DOMICÍLIO
                BANCÁRIO ou CONTA DE PAGAMENTO efetue lançamentos a débito,
                crédito e outros previstos neste CONTRATO, em seu DOMICÍLIO
                BANCÁRIO indicado ao ITI, independente de prévia consulta ou de
                qualquer ato ou formalidade legal ou documental em virtude das
                Negociações de Recebíveis. Caso seu DOMICÍLIO BANCÁRIO ou CONTA
                DE PAGAMENTO se declare impedido de dar cumprimento às ordens de
                débito emitidas, o ITI estará autorizado a reter o pagamento dos
                créditos até o recebimento de comunicação e regularização do
                DOMICÍLIO BANCÁRIO pelo ESTABELECIMENTO ou a respectiva
                compensação do débito das unidades de recebíveis negociadas.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  f
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O ESTABELECIMENTO autoriza as empresas do Grupo Itaú Unibanco a
                acessarem o valor agregado das TRANSAÇÕES realizadas pelo
                ESTABELECIMENTO, com a discriminação dos arranjos de pagamento
                transacionados, por meio de sistema dedicado à troca de
                informações do Banco Central do Brasil. A presente consulta
                abrange os 12 (doze) meses anteriores à solicitação de
                informações ao Banco Central do Brasil a ser feita no momento do
                credenciamento e regularmente durante a vigência do presente
                contrato. O ESTABELECIMENTO poderá, a qualquer momento, revogar
                essa autorização por meio dos Canais de Atendimento.
              </Typography>
            </ListItem>
          </List>
          <Typography variant="h2Terms" component="h2" mt={4}>
            CONFIDENCIALIDADE E SEGURANÇA DAS INFORMAÇÕES
          </Typography>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>33</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              As Partes comprometem-se a manter em sigilo os dados ou
              especificações a que tiverem acesso ou que venham a ter sobre
              TRANSAÇÕES, PORTADORES e condições estabelecidas neste CONTRATO
              (“Informações Confidenciais”), devendo zelar pela sua guarda,
              recepção, compartilhamento e tratamento.
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                As Partes obrigam-se, por si, por suas AFILIADAS e por seus
                REPRESENTANTES, a:
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  b
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                sob as penas da LEI, obter sua base de dados de forma lícita,
                autorizada pelos seus titulares e em conformidade com a
                legislação aplicável em vigor, de modo que possam ser
                compartilhadas e utilizadas para fins deste Contrato;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  c
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                não reproduzir, duplicar, armazenar em qualquer meio, inclusive
                eletrônico, dispor ou utilizar, de forma diversa da prevista
                neste CONTRATO ou para outra finalidade que não aquelas
                relacionadas ao CONTRATO, as Informações Confidenciais;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  d
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                adotar todas as cautelas e precauções necessárias para impedir o
                uso indevido das Informações Confidenciais por qualquer pessoa
                que venha a ter acesso por seu intermédio, de suas AFILIADAS
                e/ou de seus REPRESENTANTES, bem como não discutir, perante
                terceiros, nem divulgar, revelar ou transmitir as Informações
                Confidenciais a terceiros, a AFILIADAS ou Representantes não
                autorizados a acessar as Informações Confidenciais.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  e
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                manter em absoluta confidencialidade toda Informação
                Confidencial, durante o prazo de vigência e nos 05 (cinco) anos
                subsequentes ao término deste Contrato, a menos que prazo maior
                seja requerido por LEI aplicável ao Contrato ou às Partes.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  f
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Caso o ESTABELECIMENTO tome conhecimento de (i) qualquer ação
                tomada por meio tecnológico que resulte em um efeito adverso nas
                suas operações, dados e informações transmitidos e/ou
                armazenadas em Software e/ou em Nuvem (“Incidente Cibernético”);
                e/ou (ii) qualquer dano, acesso não autorizado, destruição ou
                uso indevido ou abusivo de dados, informações e Informações
                Confidenciais em Software e/ou Nuvem que resultem em um efeito
                adverso em suas operações, dados e informações do ITI
                transmitidos e/ou armazenadas em Software e/ou em Nuvem (“Outros
                Incidentes”), o ESTABELECIMENTO deverá:
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  g
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                notificar o ITI, por escrito e imediatamente (em não mais que 12
                horas após ter tomado conhecimento do Incidente Cibernético ou
                Outros Incidentes);
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  h
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                cumprir os direcionamentos do ITI relacionados ao Incidente
                Cibernético e/ou Outros Incidentes;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  i
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                obter evidências de como, quando e quais operações e dados do
                ITI transmitidos e/ou armazenados em Software e/ou em Nuvem
                foram ou tenham sido comprometidos, e fornecê-las ao ITI,
                mediante solicitação dessa. O ESTABELECIMENTO deverá preservar
                referidas evidências por um período não inferior a 12 (doze)
                meses;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  j
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                implementar medidas para mitigar e reduzir o impacto do
                Incidente Cibernético, Outros Incidentes e/ou qualquer impacto
                de natureza similar; e
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  k
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                preservar e proteger as operações e dados de clientes do ITI.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  l
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Para fins deste CONTRATO, as Informações Confidenciais não
                abrangem aquelas que (i) sejam ou se tornem de domínio público,
                desde que sem culpa ou negligência da Parte reveladora ou de
                seus representantes; (ii) sejam posteriormente obtidas
                legalmente pela Parte receptora de terceiro ou terceiros, sem
                violação deste Contrato pela receptora, conforme comprovado por
                documentação que ateste ser o terceiro a fonte das informações;
                (iii) sejam posteriormente recebidas de terceiros, após a devida
                verificação de que tais terceiros não estariam proibidos de
                revelar estas informações à Parte reveladora em razão de LEI ou
                obrigação contratual; (iv) sejam independentemente desenvolvidas
                pela Parte que as receber, desde que tal Parte seja capaz de
                provar o desenvolvimento independente; (v) sejam ou venham a ser
                públicas em função de ou como resultado de autorização das
                Partes; e (vi) devam ser reveladas pela Parte receptora, em
                razão de uma ordem emitida por órgão administrativo ou
                judiciário com jurisdição sobre referida Parte, somente até a
                extensão de tal ordem.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  o
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O ITI prestará às autoridades competentes, tais como o
                Ministério da Fazenda, o Banco Central do Brasil, a Receita
                Federal, as Secretarias da Fazenda Estaduais e Municipais,
                Comissões Parlamentares de Inquérito, todas as informações que
                forem solicitadas em relação ao ESTABELECIMENTO ou quaisquer
                dados relativos às TRANSAÇÕES efetuadas nos ESTABELECIMENTOS.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  p
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                ESTABELECIMENTO autoriza e concorda que, a qualquer tempo, mesmo
                após o término deste Contrato, o ITI poderá compartilhar com as
                Empresas do Conglomerado Itaú Unibanco e com parceiros
                estratégicos do ITI as informações cadastrais e aquelas oriundas
                do relacionamento do ESTABELECIMENTO ou de seus Representantes
                com o ITI, objetivando a melhora na prestação dos serviços, a
                constante manutenção da segurança nas transações, e também a
                oferta de produtos e serviços que possam ser do interesse do
                ESTABELECIMENTO ou de seus representantes.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  q
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O ESTABELECIMENTO autoriza e concorda que o ITI, os EMISSORES, a
                Instituição de sua CONTA DE PAGAMENTO e as BANDEIRAS
                compartilhem suas informações cadastrais ou especificações das
                TRANSAÇÕES com terceiros prestadores de serviço do ITI,
                relacionados ao objeto deste Contrato.
              </Typography>
            </ListItem>
          </List>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>33.4</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              O ITI e demais empresas do Conglomerado Itaú (“Itaú”) tratam dados
              pessoais de pessoas físicas (como clientes, representantes e
              sócios/acionistas/empregados de clientes pessoa jurídica) para
              diversas finalidades relacionadas ao desempenho de nossas
              atividades. Nesse item resumimos as principais informações sobre
              como coletamos e usamos dados pessoais.
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Para maiores informações, inclusive sobre os direitos dos
                titulares dos dados em relação aos seus dados pessoais (como de
                correção, acesso aos dados e informações sobre o tratamento,
                eliminação, bloqueio, exclusão, oposição e portabilidade de
                dados pessoais), acesse a nossa Política de Privacidade em
                nossos sites e aplicativos.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  b
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Dados coletados: Os dados pessoais coletados e tratados pelo
                Itaú podem incluir dados cadastrais, financeiros, transacionais
                ou outros dados, que podem ser fornecidos diretamente pelo
                titular dos dados ou obtidos em decorrência da prestação de
                serviços ou fornecimento de produtos pelo Itaú ao titular dos
                dados pessoais ou a ele relacionados, seja a pedido do próprio
                titular de dados ou em decorrência da execução de contratos com
                terceiros que mantém relacionamento com o titular de dados.
                Adicionalmente, dados pessoais podem ser obtidos de outras
                fontes conforme permitido na legislação aplicável, tais como
                fontes públicas, empresas do Conglomerado Itaú, outras
                instituições do sistema financeiro, parceiros ou fornecedores,
                bem como empresas e órgãos com os quais o Conglomerado Itaú
                tenha alguma relação contratual e com os quais o titular dos
                dados pessoais possua vínculo ou algum tipo de relação.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  c
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Finalidades de uso dos dados: Poderemos usar os dados pessoais
                para diversas finalidades relacionadas ao desempenho de nossas
                atividades, na forma prevista na Política de Privacidade, como
                por exemplo: (i) oferta, divulgação, prestação de serviços e
                fornecimento de produtos; (ii) execução de contrato e de etapas
                prévias ao contrato, incluindo a avaliação dos produtos e
                serviços mais adequados ao perfil, bem como atividades de
                crédito, financeiras, de investimento, securitárias,
                previdenciárias, cobrança e demais atividades do Conglomerado
                Itaú; (iii) cumprimento de obrigações legais e regulatórias;
                (iv) atendimento de requisições de autoridades administrativas e
                judiciais; (v) exercício regular de direitos, inclusive em
                processos administrativos, judiciais e arbitrais; (vi) análise,
                gerenciamento e tratamento de potenciais riscos, incluindo os de
                crédito, fraude e segurança; (vii) verificação de identidade e
                dados pessoais, inclusive dados biométricos, para fins de
                autenticação, segurança e/ou prevenção à fraude; (viii)
                verificação, análise e tratamento de dados pessoais para fins de
                avaliação, manutenção e aprimoramento dos nossos serviços; (ix)
                hipóteses de legítimo interesse, como desenvolvimento e ofertas
                de produtos e serviços do Conglomerado Itaú.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  d
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Dados biométricos: Poderemos utilizar biometria facial e/ou
                digital em produtos e/ou serviços das empresas do Conglomerado
                Itaú para processos de identificação e/ou autenticação em
                sistemas eletrônicos próprios ou de terceiros para fins de
                segurança e prevenção a fraudes.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  e
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Compartilhamento dos dados: Os dados pessoais poderão ser
                compartilhados para as finalidades previstas neste documento e
                na nossa Política de Privacidade, como, por exemplo, entre as
                empresas do Conglomerado Itaú, com prestadores de serviços e
                fornecedores localizados no Brasil ou no exterior, bureaus de
                crédito de acordo com as regras aplicáveis à atividade, órgãos
                reguladores e entidades públicas, inclusive administrativas e
                judiciais e, ainda, com parceiros estratégicos para possibilitar
                a oferta de produtos e serviços. Apenas compartilharemos dados
                na medida necessária, com segurança e de acordo com a legislação
                aplicável.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  f
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Clientes Pessoa Jurídica: Os Clientes Pessoa Jurídica que nos
                fornecerem dados pessoais (como, por exemplo, de seus clientes,
                contrapartes, fornecedores, representantes e
                sócios/acionistas/empregados) para o desempenho das atividades
                do Itaú, devem observar a legislação aplicável à proteção de
                dados, privacidade e sigilo, inclusive em relação ao
                fornecimento de informações aos titulares dos dados pessoais a
                respeito do compartilhamento desses dados com o Itaú.
              </Typography>
            </ListItem>
          </List>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>34</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              Caso o ESTABELECIMENTO trafegue, processe ou armazene em seu
              ambiente DADOS DO PORTADOR DE CARTÃO, seja em mídia física ou
              digital, este compromete-se a cumprir e manter-se aderente às
              regras emanadas pelo PCI (Payment Card Industry) ou qualquer norma
              posterior que venha a regular a segurança de DADOS DO PORTADOR DE
              CARTÃO no mercado de meios de pagamento, durante a vigência deste
              CONTRATO, conforme prazos e condições definidas pelo ITI.
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                A obrigatoriedade acima se estende a qualquer fornecedor
                contratado pelo ESTABELECIMENTO cuja atividade seja passível de
                tráfego, processamento ou armazenamento dos DADOS DO PORTADOR DE
                CARTÃO.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  b
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                As normas emanadas pelo PCI e a listagem das empresas
                homologadas para a realização de verificações de conformidades
                às normas estão disponíveis no site do PCI Council
                (http://www.pcisecuritystandards.org).
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  c
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O ESTABELECIMENTO compromete-se a realizar as adequações
                técnicas solicitadas pelo ITI, tais como homologações e
                atualizações de sistemas, software, etc., nos prazos acordados,
                com o intuito de garantir a segurança de seu ambiente.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  d
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O ESTABELECIMENTO deve comunicar imediatamente o ITI caso tome
                conhecimento de vazamento dos DADOS DO PORTADOR DE CARTÃO.
              </Typography>
            </ListItem>
          </List>
          <Typography variant="h2Terms" component="h2" mt={4}>
            PRAZO DE VIGÊNCIA
          </Typography>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>35</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              Este CONTRATO vigorará por prazo indeterminado, a contar da
              aceitação e inclusão do ESTABELECIMENTO no SISTEMA ITI, nos termos
              da cláusula 4.1 deste CONTRATO.
            </Typography>
          </ListItem>
          <Typography variant="h2Terms" component="h2" mt={4}>
            TÉRMINO/RESCISÃO DO CONTRATO
          </Typography>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>36</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              Qualquer das Partes poderá, a qualquer tempo, sem motivação
              alguma, mediante comunicação, com até 15 (quinze) dias de
              antecedência, rescindir o CONTRATO ou quaisquer Aditivos ou
              PRODUTOS. Tal rescisão ocorrerá livre de direitos indenizatórios,
              ônus, encargos ou penalidades, ressalvadas as obrigações
              contratuais pendentes.
            </Typography>
          </ListItem>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>37</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              Este CONTRATO poderá ser imediatamente rescindido, por justa
              causa, por qualquer das Partes, nos seguintes casos:
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                (i) infração pela parte faltosa de qualquer das cláusulas,
                termos ou condições deste CONTRATO;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  b
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                (ii) decretação de falência, deferimento de pedido de
                recuperação judicial ou proposição de recuperação extrajudicial
                ou declaração de insolvência de qualquer das Partes;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  c
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                (iii) não aceitação pelo ESTABELECIMENTO, de eventuais
                alterações efetuadas pelo ITI no presente CONTRATO, podendo ser
                motivadas, mas não se limitando a, determinação das BANDEIRAS,
                do mercado de meios de pagamento ou da legislação;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  d
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                (iv) cassação de qualquer licença expedida por órgão oficial,
                que seja obrigatória para a prestação ou continuidade dos
                serviços do ESTABELECIMENTO;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  e
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                (v) alteração de Controle direto ou indireto ou qualquer outra
                forma de reestruturação societária ou venda que resulte na
                transferência dos negócios do ESTABELECIMENTO para um
                concorrente do ITI. Caso qualquer das Partes incorra em qualquer
                das hipóteses acima, a Parte que rescindir este CONTRATO deverá
                comunicar a outra Parte, a fim de que essa tome ciência
                inequívoca da rescisão, o que produzirá seus efeitos a partir da
                referida comunicação.
              </Typography>
            </ListItem>
          </List>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>38</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              Constituirá igualmente justa causa de rescisão imediata, a
              exclusivo critério do ITI, sem prejuízo do ressarcimento das
              perdas e danos eventualmente acarretados, o não cumprimento pelo
              ESTABELECIMENTO das obrigações estipuladas no CONTRATO,
              especialmente se o ESTABELECIMENTO praticar ou sofrer medidas,
              tais como, mas não limitadas às que seguem:
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                realizar TRANSAÇÕES irregulares e em desacordo parcial ou total
                com os termos e condições deste CONTRATO, ou ainda TRANSAÇÕES
                suspeitas ou irregulares conforme definição das BANDEIRAS ou
                regras de monitoramento de fraude do ITI;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  b
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                em caso de alterações no relacionamento com a CONTA DE PAGAMENTO
                vinculada ao Contrato, como por exemplo, mas não se limitando a
                restrições do uso da CONTA DE PAGAMENTO, ser impedido de manter
                ou ter encerrada a conta pelo ESTABELECIMENTO ou pelo BANCO
                ITAUCARD;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  c
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                exercer atividades ilegais e/ou indesejáveis;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  d
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                realizar TRANSAÇÕES e/ou adotar conduta fraudulenta ou com
                suspeita de fraude;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  e
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                adotar ou permitir práticas que resultem, parcial ou totalmente,
                em preferência por qualquer outro instrumento de pagamento,
                exclusão, condicionamento ou limitação da utilização dos CARTÕES
                e MEIOS DE PAGAMENTO;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  f
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                ceder, transferir, emprestar, dar em caução ou garantia,
                entregar a terceiros, sem autorização do ITI, os EQUIPAMENTOS,
                aparelhos, software e MATERIAIS de propriedade do ITI, bem como
                quaisquer direitos e obrigações decorrentes deste CONTRATO;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  g
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                tornar-se inativo ou manter-se inativo, considerando-se inativo
                o ESTABELECIMENTO que não realizar qualquer TRANSAÇÃO dentro de
                determinado período, a exclusivo critério do ITI;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  h
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                possuir índice de TRANSAÇÕES canceladas ou não reconhecidas bem
                como atingir índices de CONTESTAÇÃO DE TRANSAÇÕES em volume
                considerado elevado, segundo critérios do ITI e/ou das
                BANDEIRAS, após o decurso de prazo estipulado pelo ITI para
                esclarecimentos e soluções, quando necessário e pertinente;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  i
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                intermediar TRANSAÇÕES de terceiros, sem a devida informação ao
                ITI e assinatura de documento específico;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  j
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                ser extinto por fusão, cisão ou incorporação, falência,
                recuperação judicial ou recuperação extrajudicial da outra
                Parte, sendo a rescisão pelo ITI facultativa nesta hipótese. Não
                optando o ITI por rescindir o Contrato, referida extinção deve
                ser objeto de aditivo contratual competente entre as Partes,
                obrigando-se o sucessor legal do ESTABELECIMENTO a informar tal
                alteração nos moldes deste Contrato, ficando o ESTABELECIMENTO
                ciente que tal alteração pode levar a uma nova negociação
                comercial;
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  k
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                suspeita de inconsistências cadastrais não sanadas no prazo
                indicado pelo ITI.
              </Typography>
            </ListItem>
          </List>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>39</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              O término ou rescisão do CONTRATO não exonera as Partes do
              cumprimento pleno e irrestrito de todas as obrigações decorrentes
              deste CONTRATO, tais como, mas não limitadas às seguintes:
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                o ITI pagar, na CONTA DE PAGAMENTO do ESTABELECIMENTO ou por
                qualquer outro meio de pagamento acordado entre as Partes, os
                valores das TRANSAÇÕES legítimas e regulares devidas ao
                ESTABELECIMENTO; e
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  b
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                o ESTABELECIMENTO pagar ao ITI os valores eventualmente devidos
                na forma deste CONTRATO e permitir a imediata retirada e/ou
                entregar os EQUIPAMENTOS, software e todos os MATERIAIS
                pertencentes ao ITI, que estejam sob sua guarda.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  c
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O ITI poderá reter o pagamento mencionado no item “a” acima, até
                o cumprimento integral, pelo ESTABELECIMENTO, de todas as
                obrigações previstas neste CONTRATO.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  d
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Obriga-se o ESTABELECIMENTO, em qualquer hipótese de término ou
                rescisão deste CONTRATO, a não mais utilizar, sob qualquer
                pretexto ou justificativa, as marcas e/ou logotipos do ITI ou da
                ITAUCARD, bem como os EQUIPAMENTOS, aparelhos, software e
                MATERIAIS cedidos pelo ITI.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  e
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O ESTABELECIMENTO poderá cancelar qualquer um de seus NÚMERO (S)
                DE ESTABELECIMENTO(S), sem que tal fato seja considerado término
                ou rescisão do CONTRATO caso continue com outro(s) NÚMERO(S) DE
                ESTABELECIMENTO.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  f
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O cancelamento de qualquer um dos PRODUTOS contratados não
                implicará o término ou rescisão do CONTRATO, permanecendo em
                pleno vigor, exceto se as Partes acordarem de maneira diversa.
              </Typography>
            </ListItem>
          </List>
          <Typography variant="h2Terms" component="h2" mt={4}>
            ALTERAÇÕES DO CONTRATO
          </Typography>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>40</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              O ITI, por qualquer documento, físico ou eletrônico, enviado ao
              ESTABELECIMENTO, por qualquer meio de transmissão ou comunicação
              ou ainda por disponibilização no APLICATIVO ITI, poderá alterar ou
              aditar cláusulas ou condições deste CONTRATO ou incluir/alterar
              novos Aditivos.
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Se o ESTABELECIMENTO não concordar com as alterações comunicadas
                na forma acima, poderá rescindir este CONTRATO, no prazo de 10
                (dez) dias, contados a partir da data do recebimento da
                comunicação. Caso o ESTABELECIMENTO não se manifeste em tal
                prazo, comprovadamente, em contrário a tais alterações, tal fato
                implicará na sua aceitação plena e irrestrita, sem prejuízo da
                possibilidade de rescindir o CONTRATO, a qualquer tempo, nos
                termos da cláusula 36.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  b
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Os ESTABELECIMENTOS já credenciados ao SISTEMA ITI por meios de
                contratos anteriores, assinados ou não, e que não se manifestem
                nos termos do Item acima, terão sua adesão ao presente CONTRATO
                a partir da realização da primeira TRANSAÇÃO após o início de
                sua vigência.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  c
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                Durante a vigência deste CONTRATO, o ESTABELECIMENTO tem ciência
                que o ITI ou a CONTA DE PAGAMENTO poderão enviar mensagens
                eletrônicas ao ESTABELECIMENTO, inclusive, mas não se limitando,
                por meio de push no APLICATIVO ITI, e-mail e de aplicativos
                mensagens instantâneas, de modo a assegurar a execução
                contratual e pós-contratual referentes ao presente CONTRATO,
                tais como avisos relacionados a alterações contratuais,
                atualização de tecnologias, situação do SISTEMA ITI, ofertas de
                produtos e serviços, entre outros. Essas mensagens não serão
                consideradas indesejadas, abusivas, spam, nem e-mail marketing,
                tendo em vista que sua finalidade é manter o ESTABELECIMENTO
                informado a respeito de sua relação contratual com o ITI e com a
                CONTA DE PAGAMENTO.
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  d
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                As Partes poderão negociar condições comerciais especiais,
                dentre as quais se incluem, mas sem se limitar, condições
                comerciais de fidelização do ESTABELECIMENTO, que serão
                formalizadas por contato telefônico, documento físico,
                eletrônico ou digital.
              </Typography>
            </ListItem>
          </List>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>41</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              As Partes não serão responsáveis por quaisquer falhas,
              interrupções ou atrasos no cumprimento de suas obrigações, quando
              decorrentes de caso fortuito ou de força maior, sendo estes
              excludentes de responsabilidade nos termos do artigo 393 do Código
              Civil Brasileiro, incluindo, entre outros, atos governamentais,
              limitações impostas por parte do Poder Público,
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                interrupção na prestação de serviços sob licença, autorização,
                permissão ou concessão governamental (fornecimento de energia
                elétrica e serviços de telefonia, atuação de operadoras de
                serviço de telecomunicações interconectadas à rede do
                ESTABELECIMENTO e do ITI, entre outros), catástrofes, greves,
                perturbações da ordem pública e demais eventos da mesma
                natureza.
              </Typography>
            </ListItem>
          </List>
          <Typography variant="h2Terms" component="h2" mt={4}>
            AUDITORIA
          </Typography>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>42</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              O ESTABELECIMENTO desde já autoriza o ITI, diretamente ou por meio
              de terceiros, a realizar o acompanhamento de suas atividades
              relacionadas a este Contrato e auditorias com objetivo de
              verificar o cumprimento das obrigações assumidas pelo
              ESTABELECIMENTO por meio deste (“Auditoria”), mediante aviso
              prévio de 5 (cinco) dias úteis. A Auditoria não implicará redução
              ou eliminação da responsabilidade do ESTABELECIMENTO com relação
              às obrigações decorrentes deste Contrato.
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                O ESTABELECIMENTO concorda em fornecer ao ITI ou à terceiro
                indicado por esta, acesso às suas dependências e a todas as
                informações e documentos que o ITI entender necessários para
                realização da Auditoria.
              </Typography>
            </ListItem>
          </List>
          <Typography variant="h2Terms" component="h2" mt={4}>
            DISPOSIÇÕES GERAIS
          </Typography>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>43</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              Ao ESTABELECIMENTO é expressamente vedada a comercialização de
              qualquer informação ou funcionalidades dos PRODUTOS e serviços do
              ITI.
            </Typography>
          </ListItem>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>44</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              O ESTABELECIMENTO, com relação aos nomes e às marcas do ITI ou das
              BANDEIRAS, obriga-se a utilizá-la nos estritos termos deste
              CONTRATO, nas formas, cores e modelos indicados e aprovados
              previamente pelo ITI, não podendo alterá-las, registrá-las ou
              usá-las de forma indevida ou infringindo os direitos de
              propriedade do ITI ou das BANDEIRAS.
            </Typography>
          </ListItem>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>45</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              A eventual tolerância ou transigência das Partes em exigir o
              integral cumprimento das obrigações contratuais não constituirá
              novação, renúncia ou modificação do acordado, tratando-se de mera
              liberalidade, podendo a respectiva Parte exigir, a qualquer tempo,
              o cumprimento integral de todas as obrigações previstas neste
              CONTRATO.
            </Typography>
          </ListItem>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>46</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              O ESTABELECIMENTO autoriza o ITI e a CONTA DE PAGAMENTO a incluir,
              sem qualquer ônus ou encargos, seu nome, marcas e logotipos,
              endereço, bem como os dos respectivos NÚMERO (S) DE
              ESTABELECIMENTO(S) ou filiais ou dependências que designar como
              ESTABELECIMENTO em ações de marketing, comunicados, catálogos e/ou
              quaisquer outros materiais promocionais do SISTEMA ITI ou CONTA DE
              PAGAMENTO, ressalvado o direito do ESTABELECIMENTO de revogar, a
              qualquer momento, por escrito, essa autorização.
            </Typography>
          </ListItem>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>47</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              Este CONTRATO obriga as Partes, bem como seus respectivos
              sucessores a qualquer título.
            </Typography>
          </ListItem>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>48</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              O ESTABELECIMENTO deverá prontamente comunicar ao ITI quaisquer
              alterações relativas às informações prestadas ao ITI,
              especialmente os referentes à composição societária, denominação
              social, objeto social, endereços comerciais e eletrônicos,
              endereços de correspondência ou números de telefone.
            </Typography>
          </ListItem>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>49</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              A nulidade ou invalidade de qualquer das disposições deste
              CONTRATO não implicará na nulidade ou invalidade das demais, que
              permanecerão válidas, produzindo plenos efeitos de direito.
            </Typography>
          </ListItem>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>50</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              Ocorrendo fatos não previstos pelo ITI que possam prejudicar o
              equilíbrio econômico-financeiro, afetando a adequada manutenção da
              operacionalidade do SISTEMA ITI, as taxas e as tarifas referidas
              neste CONTRATO poderão sofrer alterações, a serem prévia e
              expressamente comunicadas ao ESTABELECIMENTO, de forma a restaurar
              o equilíbrio contratual entre as Partes e a eficiência do SISTEMA
              ITI.
            </Typography>
          </ListItem>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>51</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              O ESTABELECIMENTO concorda que as gravações magnéticas,
              digitalizadas ou telefônicas, de negociações envolvendo qualquer
              PRODUTO, qualquer negociação específica ou qualquer termo,
              cláusula ou condição deste CONTRATO, poderão ser utilizadas como
              prova, inclusive em Juízo, por qualquer das Partes.
            </Typography>
          </ListItem>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>52</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              Este CONTRATO não estabelece vínculo trabalhista, previdenciário
              ou societário entre o ITI e o ESTABELECIMENTO, cabendo a este
              exclusiva e integral responsabilidade (a) pela gestão e
              fiscalização desses profissionais e (b) pelo cumprimento das
              obrigações de natureza trabalhista, tributária ou previdenciária,
              inclusive relativas à segurança e higiene do trabalho.
            </Typography>
          </ListItem>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>53</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              O ITI poderá ceder, transferir, total ou parcialmente, os seus
              direitos e obrigações decorrentes deste CONTRATO para suas
              coligadas, controladas, controladores e terceiros, independente de
              prévia notificação ao ESTABELECIMENTO. É vedado ao
              ESTABELECIMENTO, a que título for, a cessão deste Contrato e dos
              direitos dele decorrentes, salvo se por anuência expressa e formal
              do ITI.
            </Typography>
          </ListItem>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>54</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              O ESTABELECIMENTO declara conhecer e respeitar a legislação
              aplicável de prevenção a atos de corrupção e outros atos lesivos à
              administração pública, comprometendo-se a abster de qualquer
              atividade que constitua violação a tais normas, bem como que
              comunicará imediatamente o ITI caso tenha ciência de qualquer ato
              ou fato relacionado a este Contrato que viole referidas normas,
              podendo o Itaú Unibanco tomar as providências que entender
              necessárias.
            </Typography>
          </ListItem>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>55</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              O ITI no cumprimento de sua obrigação legal, comunicará às
              autoridades competentes, sem comunicação prévia, a ocorrência de
              operações ou propostas de operações que, na forma da legislação
              vigente, caracterizem indício de práticas de lavagem de dinheiro
              ou financiamento ao terrorismo.
            </Typography>
          </ListItem>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>56</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              Em caso de dúvida ou de esclarecimentos, entre em contato com a
              ajuda iti disponível no APLICATIVO ITI, ou pela Central de
              Atendimento, SAC ou Ouvidoria:
            </Typography>
          </ListItem>

          <List sx={{ paddingLeft: "36px " }}>
            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  a
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                CENTRAL DE ATENDIMENTO Telefone: 11 3003 6484 - Disponível para
                capitais e regiões metropolitanas - todos os dias, das 08h às
                22h (horário de Brasília)
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  b
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                SERVIÇO DE ATENDIMENTO AO CLIENTE (“SAC”) Telefone 0800 720 3670
                - Disponível todos os dias, 24h por dia Telefone 0800 722 1722 -
                Para deficientes auditivos
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemAvatar sx={{ minWidth: 36 }}>
                <Avatar
                  sx={{
                    bgcolor: "#6B6B6B",
                    width: 24,
                    height: 24,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  c
                </Avatar>
              </ListItemAvatar>
              <Typography variant="listItemTerms">
                OUVIDORIA Telefone 0800 720 3690 - Disponível para capitais e
                regiões metropolitanas de 2ª a 6ª das 9h às 18h Telefone 0800
                2000 484 - Para demais localidades todos os dias, das 08h às 22h
                (horário de Brasília)
              </Typography>
            </ListItem>
          </List>
          <Typography variant="h2Terms" component="h2" mt={4}>
            FORO
          </Typography>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>57</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              Fica eleito o Foro do domicílio do ESTABELECIMENTO como o foro
              competente para dirimir eventuais questões ou litígios entre as
              Partes.
            </Typography>
          </ListItem>
          <Box
            sx={{
              backgroundColor: "gray300",
              margin: "16px 0px",
              height: "0.5px",
            }}
          />

          <ListItem>
            <ListItemAvatar>
              <Avatar>58</Avatar>
            </ListItemAvatar>
            <Typography variant="listItemTerms">
              O presente CONTRATO entra em vigor em 03 de novembro de 2021
              revoga e substitui os Contratos/Anexos anteriores, bem como
              qualquer outro documento estabelecido entre as Partes com o mesmo
              objeto e alcance deste CONTRATO.
            </Typography>
          </ListItem>
        </List>
      </Box>

      <Box component="section">
        <Typography variant="h2Terms" component="h2" mt={4}>
          ANEXO I – DEFINIÇÕES
        </Typography>

        <Typography variant="pTerms" component="p" mt={4}>
          Para o perfeito entendimento e interpretação deste CONTRATO são
          adotadas as seguintes definições, grafadas em caixa alta, utilizadas
          no singular ou no plural:
          <br />
          <br />
          AFILIADAS – Pessoas que controlem, direta ou indiretamente, uma Parte,
          as sociedades controladas, direta ou indiretamente, por uma das
          Partes, ou qualquer outra sociedade sob Controle comum ou
          compartilhado, direta ou indiretamente, de uma das Partes ou de seus
          Controladores.
          <br />
          <br />
          APLICATIVO ITI – Plataforma digital que oferece informações aos
          ESTABELECIMENTOS para contratação e gestão de Produtos e Serviços do
          credenciamento ITI e da gestão da CONTA DE PAGAMENTO.
          <br />
          <br />
          BANDEIRAS – Empresas nacionais ou estrangeiras, detentoras dos
          direitos de propriedade e franqueadoras de suas marcas e logotipos,
          para uso do ITI e dos EMISSORES, mediante a especificação de regras
          gerais de organização e funcionamento do sistema de CARTÕES e MEIOS DE
          PAGAMENTO e/ou PRODUTOS.
          <br />
          <br />
          CANCELAMENTO DA TRANSAÇÃO – Processo em que o ESTABELECIMENTO solicita
          ao ITI o cancelamento de uma TRANSAÇÃO já processada.
          <br />
          <br />
          CARTÕES – Instrumentos de identificação e de pagamento, configurados
          ou apresentados sob a forma de cartões plásticos capazes de realizar
          várias funções, disponibilizadas pelos EMISSORES, para uso pessoal e
          intransferível dos PORTADORES, aceitos no SISTEMA ITI.
          <br />
          <br />
          CENTRAL DE ATENDIMENTO – Canais disponibilizados pelo ITAUCARD por
          meio do canal de atendimento da CONTA DE PAGAMENTO ou APLICATIVO ITI
          aos ESTABELECIMENTOS para atendimento de dúvidas, pesquisas
          operacionais e contratação de PRODUTOS e EQUIPAMENTOS.
          <br />
          <br />
          CHIP (ou SIMCARD) – Microprocessador introduzido nos CARTÕES ou MEIOS
          DE PAGAMENTO, que possui programação e memória de dados do PORTADOR,
          cuja leitura é feita nos EQUIPAMENTOS, com uso de SENHA e/ou
          assinatura do PORTADOR.
          <br />
          <br />
          CÓDIGO DE AUTORIZAÇÃO – Conjunto de caracteres gerado e fornecido a
          exclusivo critério dos EMISSORES e informado ao ESTABELECIMENTO pelo
          ITI, cuja finalidade exclusiva é, no momento da TRANSAÇÃO, identificar
          que: (i) o CARTÃO e/ou MEIO DE PAGAMENTO consultado, não se encontra
          bloqueado ou cancelado; e (ii) o valor e a modalidade da TRANSAÇÃO são
          aprovados pelo EMISSOR ou pela BANDEIRA.
          <br />
          <br />
          COMPROVANTES DE VENDAS – Documentos padronizados pelo ITI que poderão
          ser emitidos pelos EQUIPAMENTOS ou preenchidos manualmente pelos
          ESTABELECIMENTOS no momento da realização da TRANSAÇÃO.
          <br />
          <br />
          CONTA DE PAGAMENTO - A conta iti é criada pelo Aplicativo ITI e
          permite o recebimento das TRANSAÇÕES por meio de uma Conta de
          Pagamento aberta em nome do ESTABELECIMENTO, administrada pelo Banco
          Itaucard S.A.
          <br />
          <br />
          CONTRATO – É este Contrato de Credenciamento e Adesão de
          Estabelecimentos ao SISTEMA ITI disponibilizado por meio do APLICATIVO
          ITI para ciência e consulta pelos ESTABELECIMENTOS, e seus respectivos
          Aditivos, bem como qualquer documento relacionado.
          <br />
          <br />
          CONTROLE - (incluindo termos correlatos, tais como “Controlador”,
          “Controlado por” e “sob Controle comum”) significa (i) o poder detido
          por uma outra pessoa, ou um grupo de pessoas agindo em conjunto, de
          eleger, direta ou indiretamente, a maioria dos administradores e de
          determinar e conduzir as políticas e administração de tal pessoa, quer
          isoladamente ou em conjunto com suas afiliadas; ou (ii) a
          titularidade, direta ou indireta, por uma pessoa e suas afiliadas, de
          pelo menos 50% mais 1 ação/quota representativa do capital social
          votante da pessoa em questão.
          <br />
          <br />
          CONTESTAÇÃO DA TRANSAÇÃO (“CHARGEBACK”) – Processo de devolução de uma
          TRANSAÇÃO, por contestação do PORTADOR ou do EMISSOR, de acordo com as
          regras e prazos definidos pelas BANDEIRAS, conforme detalhado no
          APLICATIVO ITI.
          <br />
          <br />
          DADOS DO PORTADOR DE CARTÃO – Informações dos PORTADORES necessárias
          para a realização de uma TRANSAÇÃO, tais como: número do CARTÃO, nome
          do PORTADOR como escrito no CARTÃO, data de vencimento do CARTÃO, bem
          como todas as informações presentes na tarja magnética do CARTÃO, toda
          e qualquer SENHA relacionada ao seu uso e os códigos de segurança e de
          serviço.
          <br />
          <br />
          DOMICÍLIO BANCÁRIO – Conta de livre movimentação de titularidade do
          ESTABELECIMENTO, mantida junto à instituição bancária participante do
          SISTEMA ITI, onde receberá os créditos das unidades de recebíveis
          cedidas por meio de uma Instituição Financeira ou por meio do sistema
          de recebíveis, conforme previsão no presente CONTRATO.
          <br />
          <br />
          EMISSORES – Empresas nacionais ou estrangeiras, instituições bancárias
          ou não, autorizadas pelas BANDEIRAS a emitir e conceder CARTÕES e/ou
          disponibilizar PRODUTOS, para uso no Brasil e/ou no exterior.
          <br />
          <br />
          EQUIPAMENTOS – Quaisquer aparelhos, independente da tecnologia
          (mecânicos, elétricos, eletrônicos, magnéticos, eletromagnéticos,
          radiotransmissores, telefônicos ou utilizando quaisquer outros meios
          disponíveis), bem como os softwares relacionados, de propriedade da
          REDE, do ESTABELECIMENTO ou de terceiros (incluindo, mas não se
          limitando, a PIN PAD e TERMINAL POS/POO), fornecidos e/ou instalados
          no ESTABELECIMENTO, a título gratuito ou oneroso, para a realização de
          TRANSAÇÕES e a execução de outras funções atribuídas ao SISTEMA ITI.
          <br />
          <br />
          ESTABELECIMENTO – Pessoa jurídica ou pessoa física, fornecedora de
          bens e/ou prestadora de serviços, constituída (s) e localizada (s)
          dentro do território brasileiro, credenciada ao SISTEMA ITI.
          <br />
          <br />
          LEI – significa (i) lei, regulamento, norma, processo legal válido e
          eficaz, incluindo, sem limitação, ordem ou decisão de juízo ou
          tribunal, ou (ii) qualquer outro requerimento, decisão ou ordem de
          autoridade reguladora, autorreguladora ou administrativa competente.
          <br />
          <br />
          MATERIAL – Todo e qualquer suprimento fornecido pelo ITI ou pela CONTA
          DE PAGAMENTO ao ESTABELECIMENTO, a título oneroso ou gratuito, tais
          como: bobinas, COMPROVANTES DE VENDA, formulários, adesivos, display,
          material promocional ou operacional.
          <br />
          <br />
          MEIOS DE PAGAMENTO – Instrumentos físicos ou eletrônicos com funções
          de pagamento (múltiplas ou não), aceitos ou que venham a ser aceitos
          no SISTEMA ITI, disponibilizadas pelos EMISSORES, para uso pessoal e
          intransferível dos PORTADORES.
          <br />
          <br />
          NÚMERO DE ESTABELECIMENTO – Número dado ao ESTABELECIMENTO ou a cada
          ponto de venda de uma rede de ESTABELECIMENTOS credenciados ao SISTEMA
          ITI. Cada NÚMERO DE ESTABELECIMENTO poderá pagar TAXA DE DESCONTO e/ou
          TARIFA POR TRANSAÇÃO diferente dos demais.
          <br />
          <br />
          ÔNUS – Significa o ônus ou gravame constituídos pela Credenciadora em
          razão de garantia das obrigações oriundas do presente Contrato, pelas
          Instituições Financeiras nas operações de crédito, ou pelas
          Instituições não financeiras sobre as Unidades de Recebíveis por meio
          do sistema de registro de recebíveis, nos termos definidos no artigo
          2º, inciso VI, da Resolução 4.734, de 2019.
          <br />
          <br />
          PROPOSTA DE CREDENCIAMENTO – É a Proposta de Credenciamento onde
          constam os dados mínimos básicos para credenciamento do
          ESTABELECIMENTO, cujo modelo variará conforme o canal de
          credenciamento.
          <br />
          <br />
          PDV/TEF – Conjunto de equipamentos e softwares de processamento de
          dados de propriedade do ESTABELECIMENTO ou de terceiros, integrantes
          do seu sistema de automação comercial, e que conectados à REDE DE
          CAPTURA, mediante prévia autorização do ITI, além de funções de
          gerenciamento interno de informações, podem realizar TRANSAÇÕES,
          emitir COMPROVANTES DE VENDAS e RESUMOS DE VENDAS, efetuar intercâmbio
          de informações e executar outras funções atribuídas pelo SISTEMA ITI.
          <br />
          <br />
          PERIFÉRICOS – Artefatos acessórios dos EQUIPAMENTOS, que permitem o
          seu funcionamento, tais como, exemplificativamente, CHIP ou SIMCARD,
          fontes de alimentação de energia elétrica e cabos telefônicos.
          <br />
          <br />
          PIN PAD – Equipamento acoplado a um PDV/TEF, de propriedade do
          ESTABELECIMENTO, do ITI ou de terceiros, para a leitura da tarja
          magnética, do CHIP e/ou para a digitação de SENHA.
          <br />
          <br />
          PORTADORES – Pessoas físicas ou prepostos de pessoas jurídicas,
          detentoras de CARTÃO e/ou usuárias de PRODUTOS e autorizados a
          realizar TRANSAÇÕES.
          <br />
          <br />
          PRODUTOS – Todo e qualquer produto ou serviço disponibilizado através
          do SISTEMA ITI, cujas características, especificações e condições de
          utilização e aceitação, determinadas pelo ITI e aceitas pelo
          ESTABELECIMENTO, encontram-se reguladas em documentos específicos
          disponíveis no APLICATIVO ITI.
          <br />
          <br />
          RECEBIMENTO DIFERENCIADO DE VENDAS (“RECEBIMENTO DIFERENCIADO”) – O
          ESTABELECIMENTO poderá receber o pagamento das TRANSAÇÕES em prazo
          inferior ao estipulado na PROPOSTA DE CREDENCIAMENTO, a ser
          disponibilizado a exclusivo critério do ITI, mediante desconto
          adicional deduzido do VALOR LÍQUIDO.
          <br />
          <br />
          REDE DE CAPTURA – Conjunto formado pelos sistemas de comunicação e
          transmissão de dados, computadores (hardware e software) e outros
          recursos tecnológicos de propriedade do ITI ou de terceiros por ela
          contratados, com a finalidade de manter em funcionamento o SISTEMA
          ITI.
          <br />
          <br />
          RESUMOS DE VENDAS – Documentos padronizados, fornecidos pelo ITI,
          gerados eletronicamente pelo SISTEMA ITI ou APLICATIVO ITI, para
          registrar a quantidade e o valor total das TRANSAÇÕES realizadas em
          determinado dia.
          <br />
          <br />
          REPRESENTANTES – significam conselheiros, diretores, executivos,
          empregados e demais representantes legais das Partes e, ainda,
          agentes, consultores, auditores, advogados, assessores financeiros ou
          assessores externos de qualquer uma das Partes ou de suas Afiliadas.
          <br />
          <br />
          SENHA – Número de identificação pessoal do PORTADOR que permite ao
          EMISSOR autenticar o PORTADOR do CARTÃO ou MEIO DE PAGAMENTO.
          <br />
          <br />
          SISTEMA ITI – Conjunto de pessoas físicas ou jurídicas (o ITI,
          EMISSORES, BANDEIRAS, parceiros, instituições financeiras, prestadores
          de serviços, fornecedores, entre outros), que, de acordo com as
          normas, procedimentos e contratos que regulam a atividade, e com a
          utilização da tecnologia operacional e equipamentos adequados, efetiva
          as operações de captura, roteamento, transmissão, processamento e
          liquidação financeira das TRANSAÇÕES. Essas atividades realizadas pelo
          SISTEMA ITI constituem um conjunto de serviços interligados e
          interconectados e que viabilizam a administração de pagamentos
          mediante o uso de CARTÕES e vinculados com a liquidação das transações
          na CONTA DE PAGAMENTO.
          <br />
          <br />
          SUBCREDENCIADOR – Empresa responsável pela captura, processamento e
          liquidação de TRANSAÇÕES, bem como pela comunicação com o ITI e que
          adere a um ou mais arranjos de pagamento, tendo como atividade
          principal ou acessória, alternativa ou cumulativamente, executar ou
          facilitar a instrução de pagamento relacionada a determinado serviço
          de pagamento, credenciar a aceitação ou gerir o uso de moeda
          eletrônica; e outras atividades relacionadas à prestação de serviço de
          pagamento, designadas pelo Banco Central do Brasil.
          <br />
          <br />
          TARIFA POR TRANSAÇÃO – Remuneração por TRANSAÇÃO, em moeda corrente e
          com valor fixo estipulado entre as Partes, paga pelo ESTABELECIMENTO
          ao ITI, sendo composta de valores devidos ao ITI, ao EMISSOR e à
          BANDEIRA, que possuem denominações e condições acertadas em contratos
          próprios.
          <br />
          <br />
          TAXA DE DESCONTO – Remuneração por TRANSAÇÃO que consiste em um
          percentual incidente sobre o VALOR BRUTO das TRANSAÇÕES, cujo fator é
          estipulado entre as Partes. Trata-se de remuneração paga pelo
          ESTABELECIMENTO ao ITI, sendo composta de valores devidos ao ITI, ao
          EMISSOR e à BANDEIRA, que possuem denominações e condições acertadas
          em contratos próprios.
          <br />
          <br />
          TAXA DE SERVIÇO – Valor pago pelos ESTABELECIMENTOS ao ITI pelos
          serviços prestados, em razão da instalação, reposição, manutenção de
          cada EQUIPAMENTO e/ou fornecimento adicional de EQUIPAMENTOS;
          <br />
          <br />
          TERMINAIS POS/POO – Terminais eletrônicos, com ou sem fio, fornecidos
          pelo ITI ao ESTABELECIMENTO mediante o pagamento de taxa de serviço,
          para a realização de TRANSAÇÕES eletrônicas, emissão de COMPROVANTES
          DE VENDAS e RESUMOS DE VENDAS eletrônicos e execução de outras funções
          atribuídas pelo SISTEMA ITI.
          <br />
          <br />
          TRANSAÇÕES – Todas e quaisquer aquisições de bens e/ou serviços,
          oferta de PRODUTOS aos ESTABELECIMENTOS, EMISSORES, parceiros e
          fornecedores, transferência de fundos, saques de dinheiro ou outras
          modalidades de operações permitidas no SISTEMA ITI, realizadas pelo
          PORTADOR em ESTABELECIMENTOS no Brasil, mediante a utilização de
          CARTÕES e/ou MEIOS DE PAGAMENTO e efetivadas sob a forma eletrônica.
          <br />
          <br />
          TRANSAÇÕES OFF-LINE – Modalidade de captura eletrônica de TRANSAÇÕES,
          não efetuadas em tempo real com o ITI.
          <br />
          <br />
          TRANSAÇÕES ON-LINE – Modalidade de captura e autorização eletrônica de
          TRANSAÇÕES, mediante comunicação em tempo real com o ITI.
          <br />
          <br />
          TRANSMISSÃO DE ARQUIVOS – Captura de TRANSAÇÃO OFF-LINE para posterior
          envio pelo ESTABELECIMENTO ao ITI, e vice-versa, de arquivos de
          informações, por meio de transmissão eletrônica.
          <br />
          <br />
          VALOR BRUTO – Valor total das TRANSAÇÕES realizadas pelo
          ESTABELECIMENTO antes da dedução da TAXA DE DESCONTO e/ou TARIFA POR
          TRANSAÇÃO e da cobrança de quaisquer outras taxas, tarifas e/ou
          deduções, estas conforme quaisquer das alternativas definidas em
          CONTRATO.
          <br />
          <br />
          VALOR LÍQUIDO – Valor a ser creditado ao ESTABELECIMENTO
          correspondente ao VALOR BRUTO, já deduzido da TAXA DE DESCONTO, TAXA
          DE SERVIÇO e/ou da TARIFA POR TRANSAÇÃO e da cobrança de quaisquer
          outras taxas, tarifas e/ou deduções, estas conforme quaisquer das
          alternativas definidas em CONTRATO.
        </Typography>
      </Box>
    </Box>
  );
};
