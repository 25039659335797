import React from "react";
import { SvgIcon } from "@mui/material";

export const Itau = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.03164 0.00195312H20.1467C22.3731 0.00195312 24.1758 1.79477 24.1758 4.00389V20.0047C24.1758 22.2125 22.3731 24.002 20.1467 24.002H4.03164C1.80264 24.002 0 22.2125 0 20.0047V4.00389C0 1.79477 1.80264 0.00195312 4.03164 0.00195312Z"
          fill="#003399"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.01489 20.0026H3.89519V12.0005H2.01489V20.0026Z"
          fill="#FFFF00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.389 17.7885C13.389 17.884 13.3801 17.9919 13.3585 18.0904C13.3549 18.1029 13.3424 18.145 13.3395 18.1533C13.3353 18.1634 13.3306 18.1738 13.327 18.1841C13.1604 18.5756 12.7185 18.9433 12.1261 18.9433C11.6098 18.9433 11.2533 18.6773 11.2533 18.1727C11.2533 17.6087 11.7386 17.3181 12.4618 17.2071C12.6322 17.1805 12.8177 17.1629 13.0171 17.1566C13.1375 17.1525 13.2618 17.1515 13.389 17.1535V17.7885ZM12.3155 13.8311C11.1637 13.8311 10.3034 14.1281 9.83715 14.3982L10.1905 15.5765C10.6143 15.3217 11.3437 15.0927 12.0033 15.0927C13.1023 15.0906 13.3353 15.6443 13.3353 15.9258V15.9897C10.8984 15.9794 9.39307 16.7652 9.39307 18.3155C9.39307 19.2289 10.1223 20.1495 11.4665 20.1495C12.2663 20.1495 12.9426 19.8607 13.3538 19.3787H13.3734L13.5785 20.0025H15.1082V16.4549C15.1082 15.0145 14.3952 13.8311 12.3155 13.8311Z"
          fill="#FFFF00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.301 14.006V17.5215C20.301 17.6623 20.2833 17.7888 20.2374 17.9029C20.0873 18.2634 19.7159 18.6724 19.1053 18.6724C18.3381 18.6724 18.0021 18.1122 18.0021 17.1753V14.0029H16.168V17.5094C16.168 17.9798 16.224 18.3775 16.3384 18.7061C16.452 19.0349 16.6079 19.3081 16.8039 19.5208C17.0017 19.7346 17.2386 19.8919 17.5143 19.9904C17.789 20.089 18.0944 20.1384 18.4269 20.1384C18.7157 20.1384 18.9719 20.1035 19.1957 20.0337C19.4183 19.9638 19.6119 19.8794 19.7732 19.7798C19.9351 19.6823 20.0711 19.5754 20.1793 19.4593C20.289 19.3441 20.3755 19.2414 20.436 19.1511L20.6724 20.0016H22.161V14.006H20.301Z"
          fill="#FFFF00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.65812 12.0005V14.0029H4.80176V15.3701H5.65812V17.8092C5.65812 18.6147 5.821 19.2209 6.17335 19.5886C6.47566 19.9123 7.00652 20.1495 7.64944 20.1495C8.2022 20.1495 8.42007 20.0683 8.66843 19.9728V18.5797C8.61761 18.6033 8.47271 18.632 8.28481 18.632C7.63355 18.632 7.48239 18.2294 7.48239 17.4948V15.3701H8.64862V14.0029H7.48239V12.0005H5.65812Z"
          fill="#FFFF00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.6062 12.0005L18.4741 13.6155H19.7842L21.382 12.0005H19.6062Z"
          fill="#FFFF00"
        />
      </svg>
    </SvgIcon>
  );
};
