import { cpf, cnpj } from "cpf-cnpj-validator";

export const validateCEP = strCEP => {
  const objER = /\d{2}\d{3}-\d{3}/;

  if (objER.test(strCEP)) return true;

  return false;
};

export const validateCPF = valor => {
  try {
    const value = valor.replace(/[^0-9]/g, "");
    if (value.length === 11) {
      return cpf.isValid(value) && "CPF";
    }
    return cnpj.isValid(value) && "CNPJ";
  } catch {
    return false;
  }
};

export const validateExpiryDate = s => {
  s.replace(/[^0-9]/g, "");
  const b = s.split(/\D/);
  const d = new Date();
  const century = d.getFullYear() / 100 || 0;

  const expires = new Date(`${century} ${(b[1], b[0], 1)}`);
  return d < expires;
};

export const validateDate = originalValue => {
  return new Date(originalValue.toLocaleString());
};

export const validatePhone = phone => {
  const regex = /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/g;

  return regex.test(phone);
};
