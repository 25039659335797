import React from "react";
import { SvgIcon } from "@mui/material";

export const Personnalite = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_22556_9844)">
          <g clipPath="url(#clip1_22556_9844)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2 13.8973H26.1758V2H2V13.8973Z"
              fill="url(#paint0_linear_22556_9844)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2 25.9992H26.1758V13.9102H2V25.9992Z"
              fill="url(#paint1_linear_22556_9844)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M3.36564 16.579C3.41824 16.5941 3.48743 16.6002 3.56481 16.6002C3.85806 16.6002 4.03225 16.4429 4.03225 16.1676C4.03225 15.8955 3.85806 15.7654 3.59253 15.7654C3.48743 15.7654 3.40711 15.7775 3.36564 15.7866V16.579ZM3.125 15.599C3.24123 15.5748 3.39326 15.5566 3.58696 15.5566C3.82488 15.5566 3.99907 15.6171 4.10973 15.726C4.2121 15.8228 4.27572 15.9711 4.27572 16.1526C4.27572 16.3371 4.22585 16.4822 4.12905 16.5882C4.0019 16.7364 3.79443 16.812 3.55935 16.812C3.48743 16.812 3.42097 16.8089 3.36564 16.7939V17.6104H3.125V15.599Z"
              fill="#D5B27E"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.06297 16.655H5.34103V17.39H6.14874V17.6108H5.09766V15.5723H6.10717V15.793H5.34103V16.4373H6.06297V16.655Z"
              fill="#D5B27E"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.11565 16.5276H7.36186C7.61909 16.5276 7.78225 16.3733 7.78225 16.1404C7.78225 15.8773 7.60806 15.7623 7.35356 15.7623C7.23744 15.7623 7.15722 15.7744 7.11565 15.7865V16.5276ZM6.875 15.602C6.99669 15.5748 7.17381 15.5566 7.33697 15.5566C7.59421 15.5566 7.76293 15.6111 7.87906 15.726C7.9704 15.8137 8.02573 15.9529 8.02573 16.1132C8.02573 16.3794 7.86803 16.5578 7.67442 16.6305V16.6395C7.81543 16.6939 7.90121 16.8391 7.94825 17.0509C8.00914 17.3352 8.05334 17.5318 8.08925 17.6104H7.84041C7.80997 17.553 7.76849 17.3776 7.71863 17.1234C7.6634 16.8421 7.56103 16.7363 7.34253 16.7272H7.11565V17.6104H6.875V15.602Z"
              fill="#D5B27E"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.7355 17.2884C8.84333 17.364 8.9983 17.4214 9.16419 17.4214C9.4104 17.4214 9.55424 17.2823 9.55424 17.0737C9.55424 16.8861 9.4547 16.7741 9.20293 16.6714C8.89866 16.5504 8.71062 16.3749 8.71062 16.0905C8.71062 15.773 8.95126 15.5371 9.31359 15.5371C9.50174 15.5371 9.64275 15.5854 9.72296 15.6368L9.65661 15.8517C9.59854 15.8123 9.47403 15.7548 9.3053 15.7548C9.0508 15.7548 8.95399 15.9211 8.95399 16.0603C8.95399 16.2509 9.06739 16.3447 9.32462 16.4536C9.64002 16.5896 9.79762 16.7529 9.79762 17.0524C9.79762 17.367 9.58742 17.6423 9.1476 17.6423C8.96785 17.6423 8.77141 17.5818 8.67188 17.5091L8.7355 17.2884Z"
              fill="#D5B27E"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.2428 17.4276C11.5943 17.4276 11.7928 17.0433 11.7928 16.5806C11.7928 16.1753 11.6146 15.7519 11.2453 15.7519C10.8761 15.7519 10.6927 16.1602 10.6927 16.6018C10.6927 17.0312 10.8912 17.4276 11.2402 17.4276H11.2428ZM11.2325 17.6423C10.7767 17.6423 10.4609 17.2249 10.4609 16.6079C10.4609 15.9605 10.7971 15.5371 11.2555 15.5371C11.7267 15.5371 12.0297 15.9635 12.0297 16.5684C12.0297 17.2673 11.6706 17.6423 11.2351 17.6423H11.2325Z"
              fill="#D5B27E"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.8125 17.609V15.5703H13.078L13.673 16.6017C13.8087 16.8407 13.9193 17.0523 14.0051 17.2642L14.0134 17.2611C13.9912 16.9889 13.9857 16.7409 13.9857 16.4263V15.5703H14.2127V17.609H13.9691L13.377 16.5745C13.2469 16.3477 13.1223 16.1148 13.0311 15.894L13.0228 15.897C13.0366 16.1541 13.0394 16.3991 13.0394 16.7379V17.609H12.8125Z"
              fill="#D5B27E"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.0469 17.5562V15.5176H15.3125L15.9075 16.549C16.043 16.788 16.1537 16.9996 16.2396 17.2114L16.2479 17.2084C16.2257 16.9362 16.2202 16.6881 16.2202 16.3736V15.5176H16.447V17.5562H16.2036L15.6114 16.5218C15.4813 16.2949 15.3567 16.062 15.2655 15.8412L15.2572 15.8442C15.271 16.1013 15.2738 16.3464 15.2738 16.6851V17.5562H15.0469Z"
              fill="#D5B27E"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M18.2805 16.7639L18.0951 16.1772C18.0563 16.044 18.0287 15.923 18.001 15.8051H17.9927C17.9678 15.923 17.9374 16.0501 17.9015 16.1741L17.7189 16.7639H18.2805ZM17.6691 16.9696L17.4755 17.6108H17.2266L17.86 15.5723H18.1504L18.7867 17.6108H18.5294L18.3303 16.9696H17.6691Z"
              fill="#D5B27E"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.4336 15.5703H19.677V17.3881H20.4737V17.609H19.4336V15.5703Z"
              fill="#D5B27E"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.0703 17.609H21.3137V15.5703H21.0703V17.609Z"
              fill="#D5B27E"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22.7193 15.7941H22.1523V15.5703H23.5326V15.7941H22.9627V17.609H22.7193V15.7941Z"
              fill="#D5B27E"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24.9757 15.1055L24.6189 15.4624H24.4225L24.6825 15.1055H24.9757ZM25.0199 16.654H24.2981V17.389H25.1057V17.6099H24.0547V15.5713H25.0642V15.7921H24.2981V16.4363H25.0199V16.654Z"
              fill="#D5B27E"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.6976 4.64258H17.1044C17.481 4.64258 17.7946 4.77668 18.0549 5.0357C18.3238 5.29502 18.4581 5.61665 18.4581 5.99191V11.3808C18.4581 11.7471 18.3238 12.0689 18.0549 12.328C17.7946 12.5871 17.481 12.7211 17.1044 12.7211H11.6976C11.3211 12.7211 10.9983 12.5871 10.7382 12.328C10.4784 12.0689 10.3438 11.7471 10.3438 11.3808V5.99191C10.3438 5.61665 10.4784 5.29502 10.7382 5.0357C10.9983 4.77668 11.3211 4.64258 11.6976 4.64258Z"
              fill="#755106"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.497 4.45508H16.805C17.1746 4.45508 17.4826 4.58665 17.738 4.84103C18.0019 5.09551 18.134 5.41129 18.134 5.77981V11.07C18.134 11.4297 18.0019 11.7456 17.738 12C17.4826 12.2543 17.1746 12.3859 16.805 12.3859H11.497C11.1274 12.3859 10.8105 12.2543 10.5552 12C10.3001 11.7456 10.168 11.4297 10.168 11.07V5.77981C10.168 5.41129 10.3001 5.09551 10.5552 4.84103C10.8105 4.58665 11.1274 4.45508 11.497 4.45508Z"
              fill="#D0A967"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.8281 11.0694H11.4531V8.41992H10.8281V11.0694ZM13.8209 10.4641C13.8209 10.2272 14.0584 10.1219 14.5254 10.1306V10.3412L14.5165 10.4554C14.49 10.5343 14.4371 10.5956 14.3666 10.6396C14.2961 10.701 14.2081 10.7273 14.1113 10.7273C13.9176 10.7273 13.8209 10.6396 13.8209 10.4641ZM13.0022 9.08667H12.6237V8.41992H12.0253V9.08667H11.7436V9.5429H12.0253V10.3412C12.0253 10.6132 12.0781 10.815 12.1925 10.929C12.3069 11.0518 12.4741 11.1133 12.6765 11.1133C12.8085 11.1133 12.9142 11.0957 13.011 11.0607L13.0022 10.5956C12.9846 10.6133 12.9493 10.6219 12.8878 10.6219C12.791 10.6219 12.7205 10.5868 12.6765 10.5168C12.6413 10.4641 12.6237 10.3763 12.6237 10.2447V9.5429H13.0022V9.08667ZM14.5078 9.74465C14.1025 9.74465 13.7944 9.80605 13.5831 9.93773C13.3631 10.0693 13.2486 10.2623 13.2486 10.5168C13.2486 10.6746 13.3102 10.815 13.4247 10.929C13.5567 11.0518 13.724 11.1133 13.9264 11.1133C14.1993 11.1133 14.4019 11.0255 14.5429 10.8589H14.5605V11.0694H15.1239V9.90254C15.1239 9.63939 15.0534 9.43764 14.9215 9.28842C14.763 9.12186 14.5254 9.03404 14.2081 9.03404C13.8825 9.03404 13.6096 9.09544 13.3983 9.22712L13.5127 9.61307C13.6888 9.50791 13.8825 9.45518 14.1025 9.45518C14.3755 9.45518 14.5078 9.5429 14.5078 9.7271V9.74465ZM16.0921 9.08667H15.4759V10.236C15.4759 10.543 15.5551 10.7711 15.7047 10.9203C15.8368 11.0518 16.0129 11.1133 16.2329 11.1133C16.541 11.1133 16.7699 10.9904 16.9106 10.7536H16.9195V11.0694H17.4651V9.08667H16.849V10.2447C16.849 10.2974 16.8402 10.3412 16.8314 10.3675C16.761 10.5343 16.6377 10.6219 16.4529 10.6219C16.2153 10.6219 16.0921 10.4554 16.0921 10.1306V9.08667ZM16.2505 8.9551H16.6818L17.2099 8.41992H16.6289L16.2505 8.9551Z"
              fill="#755106"
            />
          </g>
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_22556_9844"
            x1="2"
            y1="7.94872"
            x2="26.1758"
            y2="7.94872"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D1AB66" />
            <stop offset="1" stopColor="#907E39" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_22556_9844"
            x1="2"
            y1="19.9547"
            x2="26.1758"
            y2="19.9547"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00467E" />
            <stop offset="1" stopColor="#002852" />
          </linearGradient>
          <clipPath id="clip0_22556_9844">
            <rect
              width="24.1758"
              height="24"
              fill="white"
              transform="translate(2 2)"
            />
          </clipPath>
          <clipPath id="clip1_22556_9844">
            <rect
              width="24.1758"
              height="24"
              fill="white"
              transform="translate(2 2)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
