import React from "react";
import { Button, Card, CardContent, Stack, Typography } from "@mui/material";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

import { event } from "shared/util/gaEvents";

export const DataCard = ({
  fullName,
  phone,
  email,
  birthDate,
  cep,
  streetName,
  streetNumber,
  complement,
  neighborhood,
  city,
  state,
  handleEdit,
}) => {
  return (
    <Card>
      <CardContent>
        <Stack spacing={5}>
          <Stack spacing={3}>
            <Stack direction="row" spacing={2}>
              <PermIdentityIcon
                sx={{
                  color: "rosafrevo",
                }}
              />
              <Typography variant="h2">dados pessoais</Typography>
            </Stack>

            <Stack spacing={1}>
              <Typography variant="label">nome completo</Typography>
              <Typography variant="bodyBolded">{fullName}</Typography>
            </Stack>

            <Stack spacing={1}>
              <Typography variant="label">celular</Typography>
              <Typography variant="bodyBolded">{phone}</Typography>
            </Stack>

            <Stack spacing={1}>
              <Typography variant="label">e-mail</Typography>
              <Typography variant="bodyBolded">{email}</Typography>
            </Stack>

            <Stack spacing={1}>
              <Typography variant="label">data de nascimento</Typography>
              <Typography variant="bodyBolded">{birthDate}</Typography>
            </Stack>
          </Stack>

          <Stack spacing={3}>
            <Stack direction="row" spacing={2}>
              <LocationOnOutlinedIcon
                sx={{
                  color: "rosafrevo",
                }}
              />
              <Typography variant="h2">endereço</Typography>
            </Stack>

            <Stack spacing={0}>
              <Typography variant="body">{cep}</Typography>
              <Typography variant="body">{`${streetName}, ${streetNumber}`}</Typography>
              {complement && (
                <Typography variant="body">{complement}</Typography>
              )}
              <Typography variant="body">{`${neighborhood}, ${city}, ${state}, Brasil`}</Typography>
            </Stack>
          </Stack>

          <Button
            variant="outlined"
            color="secondary"
            type="button"
            onClick={() => {
              handleEdit(2);
              event("BotaoEditarDados", "Step3", "Click");
            }}
          >
            <Typography variant="button">editar dados</Typography>
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};
