import React from "react";
import ReactDOM from "react-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import "./index.css";
import App from "./App";

ReactDOM.render(
  <GoogleReCaptchaProvider
    reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
    useEnterprise
  >
    <App />
  </GoogleReCaptchaProvider>,
  document.getElementById("iti-site")
);
