import React, { useCallback, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useMediaQuery } from "react-responsive";

import { DataCard } from "features/CheckoutReview/components/DataCard";
import { PaymentCard } from "features/CheckoutReview/components/PaymentCard";
import { mountPayload } from "features/CheckoutReview/util/mountPayload";
import { ErrorHandler } from "features/ErrorHandler";
import { BackButton } from "shared/components/BackButton";
import { event } from "shared/util/gaEvents";
import { useCheckoutStore, useStepsStore } from "stores";

export const CheckoutReview = () => {
  const linkId = new URLSearchParams(window.location.search).get("linkId");
  const { executeRecaptcha } = useGoogleReCaptcha();

  const clearSaleId = useCheckoutStore(state => state.clearSaleId);
  const loading = useCheckoutStore(state => state.loadingPost);
  const error = useCheckoutStore(state => state.errorPost);
  const success = useCheckoutStore(state => state.successPost);
  const data = useCheckoutStore(state => state.data);
  const creditCardValues = useCheckoutStore(state => state.creditCardValues);
  const personalDataValues = useCheckoutStore(
    state => state.personalDataValues
  );
  const submitForm = useCheckoutStore(state => state.submitForm);

  const nextStep = useStepsStore(state => state.nextStep);
  const setCurrentStep = useStepsStore(state => state.setCurrentStep);
  const setEditing = useStepsStore(state => state.setEditing);

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) return "";

    return executeRecaptcha("getToken");
  }, [executeRecaptcha]);

  useEffect(() => {
    if (success) {
      nextStep();
    }
  }, [success]);

  const handleEdit = value => {
    setEditing(true);
    setCurrentStep(value);
  };

  const formik = useFormik({
    initialValues: {
      payOrderId: data.payment.pay_order_id,
      merchantId: data.payment.merchant_id,
    },
    enableReinitialize: true,
    onSubmit: async values => {
      const token = await handleReCaptchaVerify();
      submitForm(
        linkId,
        token,
        values,
        mountPayload(
          creditCardValues,
          personalDataValues,
          linkId,
          data.payment.pay_order_id,
          clearSaleId
        )
      );
    },
  });

  return (
    <>
      {error && <ErrorHandler />}
      {!success && !error && (
        <>
          <BackButton eventName="VoltarTelaPasso2" step="Step3" />

          <Typography variant="h1">
            por favor, confira seus dados antes de concluirmos
          </Typography>

          <Box mt={{ xs: 6, md: 7 }}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <DataCard
                  fullName={personalDataValues.name}
                  phone={personalDataValues.phone}
                  email={personalDataValues.email}
                  birthDate={personalDataValues.birthDate}
                  cep={personalDataValues.cep}
                  streetName={personalDataValues.streetName}
                  streetNumber={personalDataValues.streetNumber}
                  complement={personalDataValues.complement}
                  neighborhood={personalDataValues.neighborhood}
                  city={personalDataValues.city}
                  state={personalDataValues.state}
                  handleEdit={handleEdit}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Stack spacing={4}>
                  <PaymentCard
                    document={creditCardValues.document}
                    creditCard={creditCardValues.creditCard}
                    installments={creditCardValues.installments}
                    totalValue={creditCardValues.formattedTotalValue}
                    handleEdit={handleEdit}
                  />

                  {!isMobile && (
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={loading}
                      mt={4}
                      onClick={() => {
                        formik.handleSubmit();
                        event("BotaoConfirmarEPagarPasso3", "Step3", "Click");
                      }}
                    >
                      {loading ? <CircularProgress size={23} /> : "pagar"}
                    </Button>
                  )}
                </Stack>
              </Grid>
            </Grid>

            {isMobile && (
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
                mt={4}
                onClick={() => {
                  formik.handleSubmit();
                  event("BotaoConfirmarEPagarPasso3", "Step3", "Click");
                }}
                sx={{
                  position: "sticky",
                  marginTop: "24px",
                  bottom: "24px",
                  backgroundColor: "#FF4B8D !important",
                }}
              >
                {loading ? (
                  <CircularProgress size={23} sx={{ color: "white" }} />
                ) : (
                  "pagar"
                )}
              </Button>
            )}
          </Box>
        </>
      )}
    </>
  );
};
