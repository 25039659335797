import React from "react";
import { SvgIcon } from "@mui/material";

export const Elo = props => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2337.9 1104.7">
        <path d="M0 0h2337.9v1104.7H0z" />
        <path
          fill="#ffcb05"
          d="M481.7 338.4c22.5-7.5 46.5-11.5 71.4-11.5 109 0 200 77.4 220.8 180.3l154.5-31.5c-35.4-174.8-190-306.4-375.3-306.4-42.5 0-83.2 6.9-121.4 19.7z"
        />
        <path
          fill="#00a4e0"
          d="m299.4 839 104.5-118.1c-46.6-41.3-76-101.6-76-168.8 0-67.1 29.4-127.4 76-168.6L299.4 265.3C220.2 335.5 170.2 438 170.2 552.1c0 114.2 50 216.7 129.2 286.9"
        />
        <path
          fill="#ef4123"
          d="M773.9 597.3C753 700.1 662.1 777.4 553.2 777.4c-25 0-49.1-4-71.5-11.6l-50 149.6c38.2 12.7 79 19.6 121.5 19.6 185.1 0 339.6-131.4 375.2-306z"
        />
        <path
          fill="#fff"
          d="M1063.5 725.8c-5.1-8.2-11.9-21.3-16.1-31-24.5-56.8-25.6-115.6-5-172 22.7-61.9 66.1-109.2 122.2-133.4 70.5-30.3 148.4-24.4 216 15.7 42.9 24.6 73.3 62.6 96.4 116.4 2.9 6.9 5.5 14.2 8.1 20.5zm140.8-245.1c-50.1 21.5-75.9 68.5-70.5 123.5l212-91.3c-36.5-42.8-83.9-57-141.5-32.2zm167.9 198.6c-.1 0-.1.1-.1.1l-4.4-3c-12.7 20.5-32.4 37.2-57.3 48-47.4 20.6-91.3 15.3-122.9-12.4l-2.9 4.4s0-.1-.1-.1l-53.8 80.5c13.4 9.3 27.7 17.1 42.7 23.4 59.4 24.7 120.2 23.5 180.1-2.5 43.3-18.8 77.3-47.4 100.5-83.7zM1632.4 271v447.4l69.6 28.2-39.5 92.2-76.8-32c-17.2-7.5-29-18.9-37.8-31.8-8.5-13.1-14.8-31.2-14.8-55.4V271zm180.2 333.5c0-38.1 16.9-72.3 43.5-95.5l-71.4-79.6c-48.4 42.8-78.9 105.3-78.9 174.9-.1 69.7 30.4 132.3 78.7 175.1l71.3-79.6c-26.5-23.3-43.2-57.3-43.2-95.3zm126.8 126.9c-14 0-27.6-2.4-40.2-6.6l-34.1 101.3c23.3 7.8 48.2 12.1 74.2 12.1 113 .1 207.3-80.1 229.2-186.6l-104.8-21.4c-11.9 57.9-63 101.3-124.3 101.2zm.3-360.6c-25.9 0-50.8 4.2-74.1 11.9l33.8 101.4c12.7-4.2 26.2-6.5 40.2-6.5 61.4.1 112.6 43.7 124.2 101.7l104.8-21.2c-21.5-106.7-115.8-187.2-228.9-187.3z"
        />
      </svg>
    </SvgIcon>
  );
};
