import { api, apiREDE } from "services/api";

export const getCheckout = async (linkId, token) => {
  const URL = `saleid?linkId=${linkId}`;

  try {
    const response = await api.get(URL, {
      headers: {
        "Content-type": "application/json",
        Authorization: `${token}`,
      },
    });

    return response;
  } catch (error) {
    if (error.message.includes("401"))
      throw Error("Ocorreu um erro com o token do recaptcha");
    else if (error.message.includes("400")) {
      throw Error("Ocorreu um erro, informações invalidas");
    } else {
      throw Error("Ocorreu um erro, tente novamente mais tarde");
    }
  }
};

export const postCheckout = async (
  pvToken,
  { payOrderId, merchantId },
  payload
) => {
  const URL = `pay-order/${payOrderId}/pv/${merchantId}/payment`;

  try {
    const response = await apiREDE.post(URL, payload, {
      headers: {
        "Content-type": "application/json",
        Authorization: `${pvToken}`,
      },
    });

    return response;
  } catch (error) {
    if (error.status !== 200) throw error;
    throw Error("");
  }
};

export const getPVToken = async (linkId, token) => {
  const URL = `pvtoken/${linkId}`;

  try {
    const response = await api.get(URL, {
      headers: {
        "Content-type": "application/json",
        Authorization: `${token}`,
      },
    });

    return response;
  } catch (error) {
    if (error.message.includes("401"))
      throw Error("Ocorreu um erro com o token do recaptcha");
    else if (error.message.includes("400")) {
      throw Error("Ocorreu um erro, informações invalidas");
    } else {
      throw Error("Ocorreu um erro, tente novamente mais tarde");
    }
  }
};
