import create from "zustand";

import { getCheckout, postCheckout, getPVToken } from "services/checkout";
import { formatCurrency } from "shared/util/formatter";
import { getTotalValue } from "shared/util/productValue";
import { useAppStore } from "stores/app";

export const useCheckoutStore = create(set => ({
  clearSaleId: null,
  pvToken: null,
  loadingPost: false,
  errorPost: false,
  successPost: false,
  data: null,
  transactionResponse: null,
  finalStatusCode: null,
  creditCardValues: {
    creditCard: "",
    name: "",
    expiryDate: "",
    cvv: "",
    installments: "",
    document: "",
    totalValue: "",
    formattedTotalValue: "",
  },
  personalDataValues: {
    name: "",
    phone: "",
    email: "",
    birthDate: "",
    cep: "",
    streetName: "",
    streetNumber: "",
    complement: "",
    neighborhood: "",
    city: "",
    state: "",
  },
  setCreditCardValues: values => {
    set({ creditCardValues: values });
  },
  setPersonalDataValues: values => {
    set({ personalDataValues: values });
  },
  fetchData: async (linkId, token) => {
    useAppStore.setState({ loading: true });
    getCheckout(linkId, token)
      .then(response => {
        if (response.status === 200) {
          if (response.data.payment_status !== "PENDING") {
            useAppStore.getState().setError(true);
          }

          if (response.data.payment_status === "PENDING") {
            const totalValue = getTotalValue(
              response.data.product_value,
              response.data.discount,
              response.data.shipping_value
            );
            set(state => ({
              data: response.data,
              creditCardValues: {
                ...state.creditCardValues,
                installments: `em 1x de ${formatCurrency.format(totalValue)}`,
                formattedTotalValue: formatCurrency.format(totalValue),
                totalValue,
              },
            }));
            useAppStore.getState().setSuccess();
          }
        }
      })
      .catch(error => {
        useAppStore.getState().setError({
          message: error.message,
        });
      });
  },
  submitForm: async (linkId, token, values, payload) => {
    set({ loadingPost: true });
    getPVToken(linkId, token)
      .then(response => {
        if (response.status === 200) {
          postCheckout(response.data.pvtoken, values, payload)
            .then(_response => {
              if (_response.status === 200) {
                set({
                  transactionResponse: _response.data["transaction-response"],
                  successPost: true,
                });
              }
            })
            .catch(error => {
              set({
                finalStatusCode: error.response.status,
                transactionResponse:
                  error.response.data["transaction-response"],
                errorPost: true,
              });
            })
            .finally(() => {
              set({ loadingPost: false });
            });
        }
      })
      .catch(error => {
        set({
          error: {
            message: error.message,
          },
          loadingPost: false,
        });
      });
  },
  setClearSaleId: timestamp => {
    set({ clearSaleId: timestamp });
  },
  setErrorPost: value => {
    set({ errorPost: value });
  },
}));
