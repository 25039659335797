import create from "zustand";

import { getCEP } from "services/cep";
import { maskCEP } from "shared/util/formatter";
import { useCheckoutStore } from "stores/checkout";

export const useCEPStore = create(set => ({
  loading: false,
  error: false,
  success: false,
  fetchData: async (zip, token) => {
    set({ pending: true });
    getCEP(zip, token)
      .then(response => {
        if (response.status === 200) {
          useCheckoutStore.setState(state => ({
            personalDataValues: {
              ...state.personalDataValues,
              cep: maskCEP(response.data.zip_code),
              streetName: response.data.street,
              neighborhood: response.data.neighborhood,
              city: response.data.city,
              state: response.data.state_initials,
            },
          }));
          set({
            success: true,
          });
        }
      })
      .catch(error => {
        set({
          error: {
            message: error.message,
          },
        });
      })
      .finally(() => {
        set({ loading: false });
      });
  },
}));
