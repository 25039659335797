import { validateCPF } from "../../../shared/util/validations";

export const mountPayload = (
  creditCardValues,
  personalDataValues,
  linkId,
  payOrderId,
  fraudToken
) => ({
  transactionType: "auth",
  fraudCheck: "Y",
  referenceNum: linkId,
  payOrderID: payOrderId,
  transactionDetail: {
    payType: {
      creditCard: {
        number: creditCardValues.creditCard.replace(/\s/g, ""),
        expMonth: creditCardValues.expiryDate.replace(/\D+/g, "").substr(0, 2),
        expYear: creditCardValues.expiryDate.replace(/\D+/g, "").substr(2),
        cvvNumber: creditCardValues.cvv,
      },
    },
  },
  billing: {
    id: "1",
    name: creditCardValues.name,
    address: `${personalDataValues.streetName}, ${personalDataValues.streetNumber}`,
    address2: personalDataValues.complement,
    city: personalDataValues.city,
    state: personalDataValues.state,
    postalcode: personalDataValues.cep,
    country: "Br",
    phone: personalDataValues.phone,
    email: personalDataValues.email,
    type: "Individual",
    district: personalDataValues.neighborhood,
    birthDate: personalDataValues.birthDate
      .split("/")
      .reverse()
      .join("-"),
    phones: {
      phone: {
        phoneType: "Mobile",
        phoneCountryCode: "55",
        phoneAreaCode: personalDataValues.phone
          .replace(/\D+/g, "")
          .substr(0, 2),
        phoneNumber: personalDataValues.phone.replace(/\D+/g, "").substr(2),
      },
    },
    documents: {
      document: {
        documentType: validateCPF(creditCardValues.document),
        documentValue: creditCardValues.document.replace(/\D+/g, ""),
      },
    },
  },
  shipping: {
    id: "1",
    name: personalDataValues.name,
    address: `${personalDataValues.streetName}, ${personalDataValues.streetNumber}`,
    address2: personalDataValues.complement,
    city: personalDataValues.city,
    state: personalDataValues.state,
    postalcode: personalDataValues.cep,
    country: "Br",
    phone: personalDataValues.phone,
    email: personalDataValues.email,
    type: "Individual",
    district: personalDataValues.neighborhood,
    birthDate: personalDataValues.birthDate
      .split("/")
      .reverse()
      .join("-"),
    phones: {
      phone: {
        phoneType: "Mobile",
        phoneCountryCode: "55",
        phoneAreaCode: personalDataValues.phone
          .replace(/\D+/g, "")
          .substr(0, 2),
        phoneNumber: personalDataValues.phone.replace(/\D+/g, "").substr(2),
      },
    },
    documents: {
      document: {
        documentType: validateCPF(creditCardValues.document),
        documentValue: creditCardValues.document.replace(/\D+/g, ""),
      },
    },
  },
  payment: {
    chargeTotal: creditCardValues.totalValue,
    currencyCode: "BRL",
    creditInstallment: {
      numberOfInstallments: creditCardValues.installments
        .substring(3, 5)
        .replace(/\D/g, ""),
      chargeInterest: "N",
    },
  },
  fraudDetails: {
    fraudToken,
  },
});
