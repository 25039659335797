import React from "react";
import { Card, CardContent, Stack, TextField, Typography } from "@mui/material";
import { PermIdentity } from "@mui/icons-material";

import { event } from "shared/util/gaEvents";
import { MaskedTextField } from "shared/components/MaskedTextField";

export const DataCard = ({ formik }) => {
  return (
    <Card>
      <CardContent>
        <Stack direction="row" spacing={2}>
          <PermIdentity
            sx={{
              color: "rosafrevo",
            }}
          />
          <Typography variant="h2">dados pessoais</Typography>
        </Stack>

        <Stack spacing={5} mt={4}>
          <TextField
            type="text"
            name="name"
            label="nome completo"
            required
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onClick={() => event("NomeCompleto", "Step2", "Click")}
            error={formik.touched.name && formik.errors.name}
            helperText={formik.touched.name && formik.errors.name}
          />

          <TextField
            type="tel"
            name="phone"
            label="telefone"
            required
            InputProps={{
              inputComponent: MaskedTextField,
              inputProps: { mask: "(00) 00000-0000", maxLength: 15 },
            }}
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onClick={() => event("Telefone", "Step2", "Click")}
            error={formik.touched.phone && formik.errors.phone}
            helperText={formik.touched.phone && formik.errors.phone}
          />

          <TextField
            type="email"
            name="email"
            label="e-mail"
            required
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onClick={() => event("Email", "Step2", "Click")}
            error={formik.touched.email && formik.errors.email}
            helperText={formik.touched.email && formik.errors.email}
          />

          <TextField
            type="text"
            name="birthDate"
            label="data de nascimento"
            required
            InputProps={{
              inputComponent: MaskedTextField,
              inputProps: { mask: "00/00/0000", maxLength: 10 },
            }}
            value={formik.values.birthDate}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onClick={() => event("DataDeNascimento", "Step2", "Click")}
            error={formik.touched.birthDate && formik.errors.birthDate}
            helperText={formik.touched.birthDate && formik.errors.birthDate}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};
