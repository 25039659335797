import React, { useEffect } from "react";
import { Grid, Typography, Box } from "@mui/material";
import { useFormik } from "formik";

import { VendorCard } from "features/CheckoutCreditCard/components/VendorCard";
import { PaymentCard } from "features/CheckoutCreditCard/components/PaymentCard";
import { creditCardSchema } from "features/CheckoutCreditCard/schema";
import { formatCurrency } from "shared/util/formatter";
import { useCheckoutStore } from "stores";

export const CheckoutCreditCard = () => {
  const data = useCheckoutStore(state => state.data);
  const initialValues = useCheckoutStore(state => state.creditCardValues);

  const formik = useFormik({
    initialValues,
    validationSchema: creditCardSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    formik.validateForm();
  }, []);

  return (
    <>
      <Typography variant="h1">
        vamos ver se está tudo certo antes de pagar?
      </Typography>

      <Box mt={{ xs: 6, md: 7 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <VendorCard
              vendor={data.trading_name}
              totalValue={initialValues.formattedTotalValue}
              product={data.product_name}
              paymentType="cartão de crédito"
              maxInstallments={data.payment.max_number_of_installments}
              productValue={formatCurrency.format(data.product_value)}
              shipping={formatCurrency.format(data.shipping_value)}
              discount={formatCurrency.format(data.discount)}
              hasShippingAndDiscount={Boolean(
                data.shipping_value || data.discount
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <PaymentCard formik={formik} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
