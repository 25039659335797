import React from "react";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";

export const VendorCard = ({
  nameLabel,
  name,
  description,
  totalValue,
  showTotalValue,
}) => {
  return (
    <Card>
      <CardContent>
        <Stack spacing={4}>
          <Stack direction="row" spacing={3}>
            <Box
              component="img"
              alt="icone"
              aria-hidden="true"
              src={require("../../../shared/svg/iticon.svg").default}
              width="32px"
            />

            <Stack spacing={1}>
              <Typography variant="label" component="p">
                {nameLabel}
              </Typography>
              <Typography variant="bodyBolded2" component="p">
                {name}
              </Typography>
            </Stack>
          </Stack>

          {description && (
            <Stack spacing={1}>
              <Typography variant="label" component="p">
                descrição
              </Typography>
              <Typography variant="bodyBolded2" component="p">
                {description}
              </Typography>
            </Stack>
          )}

          {showTotalValue && (
            <Stack spacing={1}>
              <Typography variant="label" component="p">
                valor a pagar
              </Typography>
              <Typography variant="strong2" component="p">
                {totalValue}
              </Typography>
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};
