import React from "react";
import { getCreditCardNameByNumber } from "creditcard.js";
import { Card, CardContent, Typography, Box, Stack } from "@mui/material";

import { CreditCardFlag } from "../../../shared/components/CreditCardFlag";

export const TransactionDataCard = ({
  product,
  creditCard,
  payment,
  totalValue,
}) => {
  const brandName = getCreditCardNameByNumber(creditCard);

  return (
    <Card>
      <CardContent>
        <Stack spacing={4}>
          <Typography variant="h2">dados da transação</Typography>

          {product && (
            <Stack spacing={1}>
              <Typography variant="label">nome do produto</Typography>
              <Typography variant="body">{product}</Typography>
            </Stack>
          )}

          {product && (
            <Box
              sx={{
                backgroundColor: "gray300",
                margin: "32px 0px",
                height: "0.5px",
              }}
            />
          )}

          <Stack direction="row" alignItems="center" spacing={4}>
            <CreditCardFlag creditCard={creditCard} />

            <Stack spacing={1}>
              <Typography variant="label">cartão</Typography>
              <Typography variant="body">
                {`${brandName.toUpperCase()} (**** **** **** ${creditCard.slice(
                  -4
                )})`}
              </Typography>
            </Stack>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="label">pagamento</Typography>
            <Typography variant="body">{payment}</Typography>
          </Stack>

          <Stack spacing={1}>
            <Typography variant="label">valor pago</Typography>
            <Typography variant="strong">{totalValue}</Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
