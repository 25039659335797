import React from "react";
import { SvgIcon } from "@mui/material";

export const Pix = props => {
  return (
    <SvgIcon {...props}>
      <path
        d="M7.82717 19.8271C7.12005 19.12 6.76649 18.7665 6.76649 18.3271C6.76649 17.8878 7.12005 17.5343 7.82717 16.8271L10.4999 14.1544C11.2071 13.4473 11.5606 13.0938 12 13.0938C12.4393 13.0938 12.7929 13.4473 13.5 14.1544L16.1727 16.8271C16.8799 17.5343 17.2334 17.8878 17.2334 18.3271C17.2334 18.7665 16.8799 19.12 16.1727 19.8271L13.5 22.4999C12.7929 23.207 12.4393 23.5605 12 23.5605C11.5606 23.5605 11.2071 23.207 10.4999 22.4999L7.82717 19.8271Z"
        fill="#FF8F00"
      />
      <path
        d="M14.1543 13.5C13.4472 12.7929 13.0936 12.4393 13.0936 12C13.0936 11.5607 13.4472 11.2071 14.1543 10.5L16.8271 7.82727C17.5342 7.12016 17.8878 6.76661 18.3271 6.76661C18.7665 6.76661 19.12 7.12016 19.8271 7.82727L22.4999 10.5C23.207 11.2071 23.5606 11.5607 23.5606 12C23.5606 12.4393 23.207 12.7929 22.4999 13.5L19.8271 16.1727C19.12 16.8798 18.7665 17.2334 18.3271 17.2334C17.8878 17.2334 17.5342 16.8798 16.8271 16.1727L14.1543 13.5Z"
        fill="#FFDEB2"
      />
      <path
        d="M1.50002 13.5C0.792905 12.7929 0.439346 12.4393 0.439346 12C0.439346 11.5607 0.792905 11.2071 1.50002 10.5L4.17279 7.82727C4.87991 7.12016 5.23346 6.76661 5.67281 6.76661C6.11216 6.76661 6.46572 7.12016 7.17283 7.82727L9.8456 10.5C10.5527 11.2071 10.9063 11.5607 10.9063 12C10.9063 12.4393 10.5527 12.7929 9.8456 13.5L7.17283 16.1727C6.46572 16.8798 6.11216 17.2334 5.67281 17.2334C5.23346 17.2334 4.87991 16.8798 4.17279 16.1727L1.50002 13.5Z"
        fill="#FFDEB2"
      />
      <path
        d="M7.82716 7.17286C7.12005 6.46575 6.76649 6.11219 6.76649 5.67285C6.76649 5.23351 7.12005 4.87995 7.82716 4.17284L10.4999 1.50009C11.207 0.792979 11.5606 0.439423 11.9999 0.439423C12.4393 0.439423 12.7928 0.792979 13.4999 1.50009L16.1727 4.17284C16.8798 4.87995 17.2333 5.23351 17.2333 5.67285C17.2333 6.11219 16.8798 6.46575 16.1727 7.17286L13.4999 9.84561C12.7928 10.5527 12.4393 10.9063 11.9999 10.9063C11.5606 10.9063 11.207 10.5527 10.4999 9.84561L7.82716 7.17286Z"
        fill="#FF8F00"
      />
    </SvgIcon>
  );
};
