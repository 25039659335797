import React from "react";
import { Box, Card, CardContent, Grid, Stack, Typography } from "@mui/material";

export const QRCodeCard = ({ src, pixKey }) => {
  return (
    <Card>
      <CardContent>
        <Stack spacing={{ xs: 4, md: 8 }}>
          <Grid
            container
            direction={{ xs: "row-reverse", md: "row" }}
            spacing={{ xs: 0, md: 4 }}
          >
            <Grid item xs={6} md={12} display="flex" justifyContent="center">
              <Box
                component="img"
                src={`data:image/jpeg;base64,${src}`}
                alt="QR Code"
                width={{ xs: "120px", md: "120px" }}
                height={{ xs: "120px", md: "120px" }}
              />
            </Grid>

            <Grid item xs={6} md={12}>
              <Stack spacing={2} textAlign={{ xs: "left", md: "center" }}>
                <Typography variant="h2" component="p">
                  se preferir, leia o QR Code® Pix com seu app de pagamentos
                </Typography>
                <Typography variant="label" component="p">
                  sempre confira as informações antes de pagar
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Stack spacing={1}>
            <Typography variant="label" component="p">
              chave Pix
            </Typography>
            <Typography variant="h2" component="p">
              {pixKey}
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
