import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";

export const PixPaymentButton = ({
  pixCode,
  onCopy,
  label,
  brandIcon: BrandIcon,
  actionIcon: ActionIcon,
}) => {
  return (
    <CopyToClipboard text={pixCode} onCopy={onCopy}>
      <Box
        component="button"
        padding={3}
        border="1px solid #DDE1E5"
        borderRadius={2}
        height="64px"
        backgroundColor="white"
        sx={{
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "gray100",
          },
        }}
      >
        <Stack direction="row" alignItems="center" spacing={3}>
          <BrandIcon />
          <Typography
            variant="button"
            textAlign="left"
            width="100%"
            overflow="hidden"
            color="#2A2A2A"
          >
            {label}
          </Typography>
          <ActionIcon color="primary" sx={{ fontSize: 24 }} />
        </Stack>
      </Box>
    </CopyToClipboard>
  );
};
