export const redirectDeepLinkITI = () => {
  window.location.href = "iti://pix-key-management";
  setTimeout(() => {
    window.location.href = "https://app.adjust.com/zp0fk8";
  }, 2000);
};

export const redirectDeepLinkITAU = () => {
  window.location.href = "itauvarejo://central_pix";
  setTimeout(() => {
    window.location.href =
      "https://app.adjust.com/ezb10an_eg0ygy9?&fallback=http%3A%2F%2Fwww.itau.com.br/canais-itau/aplicativo-itau/";
  }, 2000);
};

export const redirectDeepLinkPERSONNALITE = () => {
  window.location.href = "itaupersonnalite://central_pix";
  setTimeout(() => {
    window.location.href =
      "https://app.adjust.com/2ck7cti_97qgik0?&fallback=http%3A%2F%2Fwww.itau.com.br/personnalite/onde-estiver/";
  }, 2000);
};
