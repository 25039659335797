import React from "react";
import { Box, Stack } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { useStepsStore } from "stores/steps";
import { event } from "shared/util/gaEvents";

export const BackButton = ({ eventName, step }) => {
  const goToPreviousStep = useStepsStore(state => state.previousStep);

  return (
    <Box
      component="button"
      type="button"
      onClick={() => {
        goToPreviousStep();
        event(eventName, step, "Click");
      }}
      color="rosafrevo"
      backgroundColor="white"
      border="none"
      fontWeight="bold"
      padding={0}
      fontSize="16px"
      lineHeight="20px"
      mb={{ xs: "16px", md: "24px" }}
      sx={{
        cursor: "pointer",
      }}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        <ArrowBackIosNewIcon />
        <span>voltar</span>
      </Stack>
    </Box>
  );
};
