import React, { useCallback, useEffect } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import FadeIn from "react-fade-in";

import { CheckoutCreditCard } from "features/CheckoutCreditCard";
import { CheckoutPersonalData } from "features/CheckoutPersonalData";
import { CheckoutReview } from "features/CheckoutReview";
import { CheckoutSuccess } from "features/CheckoutSuccess";
import { ErrorPage } from "features/ErrorHandler/components/ErrorPage";
import { Loading } from "features/Layout/components/Loading";
import { ScrollTop } from "shared/components/ScrollTop";
import { clearSaleId } from "shared/util/clearSaleId";
import { useAppStore, useCheckoutStore, useStepsStore } from "stores";

export const Checkout = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const loading = useAppStore(state => state.loading);
  const error = useAppStore(state => state.error);
  const success = useAppStore(state => state.success);

  const fetchData = useCheckoutStore(state => state.fetchData);
  const setClearSaleId = useCheckoutStore(state => state.setClearSaleId);

  const currentStep = useStepsStore(state => state.currentStep);

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) return;

    const token = await executeRecaptcha("checkout");
    const linkId = new URLSearchParams(window.location.search).get("linkId");
    await fetchData(linkId, token);
  }, [executeRecaptcha]);

  useEffect(() => {
    setClearSaleId(clearSaleId());
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return (
    <>
      {loading && <Loading />}
      {error && (
        <ErrorPage
          title="o link não está mais disponível"
          description="Entre em contato com a pessoa que fez a venda e peça um novo link de pagamento"
          image={require("../shared/svg/naoencontrado.svg").default}
        />
      )}
      {success && (
        <>
          {currentStep === 1 && (
            <ScrollTop>
              <FadeIn>
                <CheckoutCreditCard />
              </FadeIn>
            </ScrollTop>
          )}
          {currentStep === 2 && (
            <ScrollTop>
              <FadeIn>
                <CheckoutPersonalData />
              </FadeIn>
            </ScrollTop>
          )}
          {currentStep === 3 && (
            <ScrollTop>
              <FadeIn>
                <CheckoutReview />
              </FadeIn>
            </ScrollTop>
          )}
          {currentStep === 4 && (
            <ScrollTop>
              <FadeIn>
                <CheckoutSuccess />
              </FadeIn>
            </ScrollTop>
          )}
        </>
      )}
    </>
  );
};
