import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";

import { CreditCardFlip } from "features/CheckoutCreditCard/components/CreditCardFlip";
import { formatCurrency } from "shared/util/formatter";
import { event } from "shared/util/gaEvents";
import { getinstallments } from "shared/util/productValue";
import { useCheckoutStore, useStepsStore } from "stores";
import { MaskedTextField } from "shared/components/MaskedTextField";

export const PaymentCard = ({ formik }) => {
  const data = useCheckoutStore(state => state.data);
  const setCreditCardValues = useCheckoutStore(
    state => state.setCreditCardValues
  );

  const editing = useStepsStore(state => state.editing);
  const nextStep = useStepsStore(state => state.nextStep);
  const setCurrentStep = useStepsStore(state => state.setCurrentStep);
  const setEditing = useStepsStore(state => state.setEditing);

  const [focusCVV, setFocusCVV] = useState(false);

  const handleNext = () => {
    setCreditCardValues(formik.values);
    nextStep();
  };

  const handleEdit = () => {
    setCreditCardValues(formik.values);
    setCurrentStep(3);
    setEditing(false);
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h2">insira os dados do seu cartão</Typography>

        <Box mt={4}>
          <CreditCardFlip formik={formik} focusCVV={focusCVV} />
        </Box>

        <Box component="form" onSubmit={formik.handleSubmit} mt={7}>
          <Stack spacing={4}>
            <TextField
              type="text"
              name="creditCard"
              label="número do cartão de crédito"
              required
              InputProps={{
                inputComponent: MaskedTextField,
                inputProps: { mask: "0000 0000 0000 0000", maxLength: 19 },
              }}
              value={formik.values.creditCard}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onClick={() => event("NumeroDoCartao", "Step1", "Click")}
              error={Boolean(
                formik.touched.creditCard && formik.errors.creditCard
              )}
              helperText={formik.touched.creditCard && formik.errors.creditCard}
            />

            <TextField
              type="text"
              name="name"
              label="nome impresso no cartão"
              required
              inputProps={{ maxLength: 99 }}
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onClick={() => event("NomeImpressoNoCartao", "Step1", "Click")}
              error={Boolean(formik.touched.name && formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />

            <TextField
              type="text"
              name="expiryDate"
              label="data de validade do cartão"
              required
              InputProps={{
                inputComponent: MaskedTextField,
                inputProps: { mask: "00/00", maxLength: 5 },
              }}
              value={formik.values.expiryDate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onClick={() => event("ValidadeDoCartao", "Step1", "Click")}
              error={Boolean(
                formik.touched.expiryDate && formik.errors.expiryDate
              )}
              helperText={formik.touched.expiryDate && formik.errors.expiryDate}
            />

            <TextField
              type="text"
              name="cvv"
              label="código de segurança CVV"
              required
              InputProps={{
                inputComponent: MaskedTextField,
                inputProps: { mask: "0000", maxLength: 4 },
              }}
              value={formik.values.cvv}
              onChange={formik.handleChange}
              onFocus={() => setFocusCVV(true)}
              onBlur={e => {
                setFocusCVV(false);
                formik.handleBlur(e);
              }}
              onClick={() => event("CodigoCVVDoCartao", "Step1", "Click")}
              error={Boolean(formik.touched.cvv && formik.errors.cvv)}
              helperText={formik.touched.cvv && formik.errors.cvv}
            />

            <FormControl variant="standard">
              <InputLabel>parcelamento</InputLabel>

              <Select
                name="installments"
                IconComponent={KeyboardArrowDown}
                value={formik.values.installments}
                onChange={formik.handleChange}
                onClick={() => event("ParcelamentoDaCompra", "Step1", "Click")}
              >
                {getinstallments(data).map(option => (
                  <MenuItem
                    key={option.quantity}
                    value={`em ${option.quantity}x de ${formatCurrency.format(
                      option.value
                    )}`}
                  >
                    {`em ${option.quantity}x de ${formatCurrency.format(
                      option.value
                    )}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              type="text"
              name="document"
              label="CPF ou CNPJ"
              required
              InputProps={{
                inputComponent: MaskedTextField,
                inputProps: {
                  mask: [
                    { mask: "000.000.000-00" },
                    { mask: "00.000.000/0000-00" },
                  ],
                  maxLength: 18,
                },
              }}
              value={formik.values.document}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onClick={() => event("CPFouCNPJDoCartao", "Step1", "Click")}
              error={Boolean(formik.touched.document && formik.errors.document)}
              helperText={formik.touched.document && formik.errors.document}
            />
          </Stack>

          <Box mt={5}>
            <Button
              variant="contained"
              color="primary"
              type="button"
              disabled={!formik.isValid}
              onClick={() => {
                if (editing) {
                  handleEdit();
                  event("BotaoSalvarPasso1", "Step1", "Click");
                } else {
                  handleNext();
                  event("BotaoPagarPasso1", "Step1", "Click");
                }
              }}
            >
              {editing ? "salvar" : "continuar"}
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
