export const clickEvent = (ID, AC, MO) => ({
  analytics: {
    custom: {
      actioninfo: `ID:${ID}|AC:${AC}|MO:${MO}`,
      events: "Click",
    },
  },
  rule: "customLink",
  site: {
    ambiente: "NL",
    negocio: "ITI",
    nome: "IT",
    tipoDeCanal: "Web",
    versaoAmbiente: "AEM",
  },
});

export const event = (ID, AC, MO) => {
  window.TrackAnalytics.Track(clickEvent(ID, AC, MO));
};
