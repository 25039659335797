import React from "react";
import { Box } from "@mui/material";

export const Header = () => {
  return (
    <Box
      component="header"
      display="flex"
      justifyContent={{ xs: "center", md: "left" }}
      alignItems="center"
      width="100%"
      height={{ xs: "76px", md: "120px" }}
    >
      <Box component="a" href="https://iti.itau/">
        <Box
          component="img"
          src={require("../../../shared/svg/logoitauheader.svg").default}
          alt="Logo iti"
          height={{ xs: "40px", md: "64px" }}
        />
      </Box>
    </Box>
  );
};
