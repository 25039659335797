import { createTheme } from "@mui/material";
import { breakpoints } from "theme/breakpoints";
import { palette } from "theme/palette";
import { spacing } from "theme/spacing";

const baseTheme = createTheme({
  palette,
  spacing,
  breakpoints,
  typography: {
    fontFamily: "ItauText-Regular",
  },
});

export const theme = createTheme(baseTheme, {
  typography: {
    h1: {
      maxWidth: "468px",
      fontWeight: "bold",
      fontSize: "20px",
      lineHeight: "26px",
      [baseTheme.breakpoints.up("md")]: {
        fontSize: "32px",
        lineHeight: "41px",
      },
    },
    h2: {
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "20px",
    },
    body: {
      fontSize: "14px",
      lineHeight: "18px",
    },
    body2: {
      fontSize: "16px",
      lineHeight: "20px",
    },
    body3: {
      fontSize: "16px",
      lineHeight: "20px",
      color: baseTheme.palette.gray700,
    },
    bodyBolded: {
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "18px",
    },
    bodyBolded2: {
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "20px",
    },
    link: {
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "18px",
      color: baseTheme.palette.rosafrevo,
      textDecoration: "none",
    },
    label: {
      fontSize: "14px",
      lineHeight: "18px",
      color: baseTheme.palette.gray700,
    },
    button: {
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "20px",
      textTransform: "none",
    },
    strong: {
      fontWeight: "bold",
      fontSize: "24px",
      lineHeight: "30px",
    },
    strong2: {
      fontWeight: "bold",
      fontSize: "28px",
      lineHeight: "38px",
    },
    pixCode: {
      fontWeight: "bold",
      fontSize: "12px",
      lineHeight: "18px",
    },
    strongResponsive: {
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "20px",
      [baseTheme.breakpoints.up("md")]: {
        fontSize: "24px",
        lineHeight: "30px",
      },
    },
    creditCard: {
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "20px",
      color: baseTheme.palette.gray,
    },
    footerBody: {
      fontSize: "12px",
      lineHeight: "18px",
      color: baseTheme.palette.white,
    },
    footerStrong: {
      fontWeight: "bold",
      fontSize: "18px",
      lineHeight: "24px",
      color: baseTheme.palette.white,
    },
    h2Terms: {
      fontSize: "24px",
      lineHeight: "26px",
      color: baseTheme.palette.dark,
      fontWeight: "bold",
    },
    pTerms: {
      fontSize: "18px",
      lineHeight: "24px",
      color: baseTheme.palette.dark,
    },
    listItemTerms: {
      fontSize: "18px",
    },
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: "#FF4B8D",
          fontWeight: "bold",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          width: "100%",
          height: "48px",
          borderRadius: "50px",
          boxShadow: "none",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
          boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: "24px",
          paddingRight: "24px",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& label.Mui-focused": {
            color: "#6b6b6b",
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          ":hover": {
            ":not(.Mui-disabled)": {
              ":before": {
                borderBottom: "1px solid #6B6B6B",
              },
            },
          },
          ":before": {
            borderBottom: "1px solid #6B6B6B",
          },
          ":after": {
            borderBottom: "1px solid #6B6B6B",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#6b6b6b",
        },
        asterisk: {
          display: "none",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard",
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          "& label.Mui-focused": {
            color: "#6b6b6b",
          },

          "& label.Mui-error": {
            color: "#D50000",
          },
        },
      },
    },
  },
});
