import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import { useMediaQuery } from "react-responsive";

import { ScrollTop } from "shared/components/ScrollTop";

export const ErrorPage = ({
  title,
  description,
  button = undefined,
  image,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <ScrollTop>
      <Stack
        direction={{ xs: "column", md: "row" }}
        alignItems="center"
        spacing={5}
        pt="120px"
        pb="120px"
        sx={{
          minHeight: { xs: "calc(100vh - 558px)", md: "calc(100vh - 398px)" },
        }}
      >
        {!isMobile && <img alt="Erro" src={image} aria-hidden="true" />}

        <Stack spacing={5}>
          <Stack spacing={2}>
            <Typography variant="strong2" component="h1">
              {title}
            </Typography>
            <Typography variant="body3" component="p">
              {description}
            </Typography>
          </Stack>

          {!isMobile && button && (
            <Button
              variant={button.variant}
              color={button.color}
              sx={{
                maxWidth: "234px",
              }}
              onClick={button.onClick}
              type="button"
            >
              <Typography variant="button">{button.label}</Typography>
            </Button>
          )}
        </Stack>

        {isMobile && <img alt="Erro" src={image} aria-hidden="true" />}

        {isMobile && button && (
          <Button
            variant={button.variant}
            color={button.color}
            sx={{
              maxWidth: { md: "234px" },
            }}
            onClick={button.onClick}
            type="button"
          >
            <Typography variant="button">{button.label}</Typography>
          </Button>
        )}
      </Stack>
    </ScrollTop>
  );
};
