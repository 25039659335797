import create from "zustand";

export const useStepsStore = create(set => ({
  currentStep: 1,
  editing: false,
  nextStep: () => set(state => ({ currentStep: state.currentStep + 1 })),
  previousStep: () => set(state => ({ currentStep: state.currentStep - 1 })),
  setCurrentStep: value => set({ currentStep: value }),
  setEditing: editing => {
    set({ editing });
  },
}));
