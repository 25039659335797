export const brandCodes1 = [78];
export const brandCodes2 = [
  4,
  5,
  8,
  9,
  10,
  13,
  22,
  60,
  61,
  64,
  65,
  72,
  76,
  77,
  79,
  83,
  84,
  92,
  94,
  99,
  100,
  110,
  116,
  151,
  153,
  158,
  171,
];
export const brandCodes3 = [
  2,
  11,
  16,
  17,
  18,
  20,
  21,
  24,
  25,
  26,
  27,
  28,
  29,
  31,
  32,
  33,
  34,
  35,
  36,
  37,
  38,
  40,
  42,
  44,
  45,
  46,
  47,
  49,
  50,
  55,
  66,
  67,
  68,
  69,
  70,
  71,
  73,
  74,
  75,
  85,
  91,
  96,
  150,
  154,
  155,
  156,
  157,
  159,
  911,
  912,
  3025,
];
export const brandCodes4 = [
  1,
  6,
  12,
  14,
  15,
  19,
  23,
  30,
  54,
  56,
  62,
  82,
  101,
  115,
  122,
  180,
  181,
];
export const brandCodes5 = [52, 39];
export const brandCodes6 = [3, 7, 41, 43, 57, 58, 59, 63, 86, 93, 109, 200];
export const brandCodes7 = [51, 80];
export const brandCodes8 = [
  53,
  87,
  88,
  89,
  97,
  98,
  132,
  133,
  160,
  161,
  167,
  169,
  173,
  176,
  898,
  899,
  1002,
  1003,
  1018,
  1019,
  1020,
  1021,
  1023,
  1027,
  1030,
  1032,
  1034,
  1036,
  1038,
  3026,
  3027,
  3028,
  3029,
  3030,
  3031,
  3032,
  3033,
  3034,
  3035,
];
