import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useFormik } from "formik";

import { DataCard } from "features/CheckoutPersonalData/components/DataCard";
import { CepCard } from "features/CheckoutPersonalData/components/CepCard";
import { personalDataSchema } from "features/CheckoutPersonalData/schema";
import { BackButton } from "shared/components/BackButton";
import { useCheckoutStore } from "stores";

export const CheckoutPersonalData = () => {
  const initialValues = useCheckoutStore(state => state.personalDataValues);

  const formik = useFormik({
    initialValues,
    validationSchema: personalDataSchema,
  });

  return (
    <>
      <BackButton eventName="VoltarTelaPasso2" step="Step3" />

      <Typography variant="h1">
        agora vamos preencher o restante dos seus dados?
      </Typography>

      <Box component="form" mt={{ xs: 6, md: 7 }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <DataCard formik={formik} />
          </Grid>
          <Grid item xs={12} md={6}>
            <CepCard formik={formik} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
