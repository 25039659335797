import { Amex } from "../icons/Amex";
import { Diners } from "../icons/Diners";
import { Discover } from "../icons/Discover";
import { Elo } from "../icons/Elo";
import { Hipercard } from "../icons/Hipercard";
import { JCB } from "../icons/JCB";
import { Mastercard } from "../icons/Mastercard";
import { Visa } from "../icons/Visa";

export const creditCardFlags = {
  Amex,
  Diners,
  Discover,
  Elo,
  Hipercard,
  JCB,
  Mastercard,
  Visa,
};
