import React from "react";
import { getCreditCardNameByNumber } from "creditcard.js";
import { Button, Card, CardContent, Stack, Typography } from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";

import { event } from "shared/util/gaEvents";

export const PaymentCard = ({
  document,
  creditCard,
  installments,
  totalValue,
  handleEdit,
}) => {
  const brandName = getCreditCardNameByNumber(creditCard);

  return (
    <Card>
      <CardContent>
        <Stack spacing={4}>
          <Stack spacing={3}>
            <Stack direction="row" spacing={2}>
              <CreditCardIcon
                sx={{
                  color: "rosafrevo",
                }}
              />
              <Typography variant="h2">pagamento</Typography>
            </Stack>

            <Stack spacing={1}>
              <Typography variant="label">
                documento de identificação
              </Typography>
              <Typography variant="bodyBolded">{document}</Typography>
            </Stack>

            <Stack spacing={1}>
              <Typography variant="label">cartão de crédito</Typography>
              <Typography variant="bodyBolded">
                {`${brandName.toUpperCase()} (**** **** **** ${creditCard.slice(
                  -4
                )})`}
              </Typography>
            </Stack>

            <Stack spacing={1}>
              <Typography variant="label">parcelamento</Typography>
              <Typography variant="bodyBolded">{installments}</Typography>
            </Stack>

            <Stack spacing={1}>
              <Typography variant="label">total</Typography>
              <Typography variant="strong">{totalValue}</Typography>
            </Stack>
          </Stack>

          <Button
            variant="outlined"
            color="secondary"
            type="button"
            onClick={() => {
              handleEdit(1);
              event("BotaoEditarCartao", "Step3", "Click");
            }}
          >
            <Typography variant="button">editar cartão</Typography>
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};
