import React from "react";
import { SvgIcon } from "@mui/material";

export const Diners = props => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        viewBox="0 0 750 471"
      >
        <path
          fill="#0079BE"
          d="M584.934 236.947c0-99.416-82.98-168.133-173.896-168.1h-78.241c-92.003-.033-167.73 68.705-167.73 168.1 0 90.931 75.729 165.641 167.73 165.203h78.241c90.913.437 173.896-74.293 173.896-165.203z"
        />
        <path
          fill="#FFF"
          d="M333.281 82.932c-84.069.026-152.193 68.308-152.215 152.58.021 84.258 68.145 152.532 152.215 152.559 84.088-.026 152.229-68.301 152.239-152.559-.012-84.274-68.151-152.554-152.239-152.58z"
        />
        <path
          fill="#0079BE"
          d="M237.066 235.098c.08-41.18 25.747-76.296 61.94-90.25v180.479c-36.193-13.946-61.861-49.044-61.94-90.229zm131 90.275V144.848c36.208 13.921 61.915 49.057 61.981 90.256-.066 41.212-25.773 76.322-61.981 90.269z"
        />
      </svg>
    </SvgIcon>
  );
};
