import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export const ReadMore = ({ children: text }) => {
  const [readMore, setReadMore] = useState(false);

  return (
    <>
      <Typography
        variant="pixCode"
        component="p"
        sx={{ wordBreak: "break-all" }}
      >
        {readMore ? text : `${text.slice(0, 50)}...`}
      </Typography>

      <Box
        component="button"
        aria-expanded={readMore ? "true" : "false"}
        onClick={() => setReadMore(!readMore)}
        sx={{
          width: "100%",
          padding: "8px 0px",
          cursor: "pointer",
          backgroundColor: "transparent",
          border: "none",
          borderBottom: "1px solid #dcdcdc",
          color: "gray900",
          fontFamily: "ItauText-Regular",
          fontSize: "14px",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {readMore ? "recolher" : "exibir código completo"}
          {readMore ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </Stack>
      </Box>
    </>
  );
};
