import React from "react";
import { Box, Card, CardContent, Stack, Typography } from "@mui/material";

export const RevisionCard = ({
  product,
  productValue,
  shipping,
  discount,
  totalValue,

  showShipping,
  showDiscount,
}) => {
  return (
    <Card>
      <CardContent>
        <Stack spacing={4}>
          <Typography variant="h2">revisão do pagamento</Typography>

          {product && (
            <Stack spacing={1}>
              <Typography variant="label" component="p">
                produto ou serviço
              </Typography>
              <Typography variant="body" component="p">
                {product}
              </Typography>
            </Stack>
          )}

          <Stack spacing={2}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="label" component="p">
                valor da venda
              </Typography>
              <Typography variant="body" component="p">
                {productValue}
              </Typography>
            </Stack>

            {showShipping && (
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="label" component="p">
                  valor da entrega
                </Typography>
                <Typography variant="body" component="p">
                  {shipping}
                </Typography>
              </Stack>
            )}

            {showDiscount && (
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="label" component="p">
                  desconto
                </Typography>
                <Typography variant="body" component="p">
                  - {discount}
                </Typography>
              </Stack>
            )}

            <Box
              sx={{
                backgroundColor: "gray300",
                height: "0.5px",
              }}
            />

            <Stack direction="row" justifyContent="space-between">
              <Typography variant="label" component="p">
                total a pagar
              </Typography>
              <Typography variant="bodyBolded" component="p">
                {totalValue}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
