import * as yup from "yup";
import { parse } from "date-fns";
import { validateCEP, validatePhone } from "../../shared/util/validations";

export const personalDataSchema = yup.object().shape({
  name: yup
    .string()
    .min(8, "digite seu nome completo")
    .required("campo obrigatório"),
  phone: yup
    .string()
    .required("campo obrigatório")
    .test("test-number", "celular inválido", phone => {
      if (phone === undefined) return false;
      return validatePhone(phone);
    }),
  email: yup
    .string()
    .required("campo obrigatório")
    .email("e-mail inválido."),
  birthDate: yup
    .date()
    .transform((value, originalValue, context) => {
      if (originalValue.length < 10) return false;
      if (context.isType(value)) return value;
      return parse(originalValue, "dd/MM/yyyy", new Date());
    })
    .typeError("data inválida")
    .required("campo obrigatório")
    .min(new Date(1900, 0, 1), "data inválida")
    .max(new Date(), "data inválida"),
  cep: yup
    .string()
    .required("campo obrigatório")
    .min(9, "cep inválido")
    .max(11, "cep inválido")
    .test("test-number", "cep inválido", cep => {
      if (cep === undefined) return false;
      return validateCEP(cep);
    }),
  streetName: yup
    .string()
    .required("campo obrigatório")
    .min(2, "campo inválido")
    .max(99, "campo inválido"),
  streetNumber: yup
    .string()
    .required("campo obrigatório")
    .min(1, "campo inválido")
    .max(10, "campo inválido"),
  complement: yup.string(),
  neighborhood: yup
    .string()
    .required("campo obrigatório")
    .min(1, "campo inválido")
    .max(30, "campo inválido"),
  city: yup
    .string()
    .required("campo obrigatório")
    .min(1, "campo inválido")
    .max(30, "campo inválido"),
  state: yup
    .string()
    .required("campo obrigatório")
    .min(2, "campo inválido")
    .max(20, "campo inválido"),
});
