import React, { useCallback, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LocationOnOutlined } from "@mui/icons-material";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { stripCEP } from "shared/util/formatter";
import { event } from "shared/util/gaEvents";
import { useCEPStore, useCheckoutStore, useStepsStore } from "stores";
import { MaskedTextField } from "shared/components/MaskedTextField";

export const CepCard = ({ formik }) => {
  const personalDataValues = useCheckoutStore(
    state => state.personalDataValues
  );
  const setPersonalDataValues = useCheckoutStore(
    state => state.setPersonalDataValues
  );

  const success = useCEPStore(state => state.success);
  const fetchData = useCEPStore(state => state.fetchData);

  const editing = useStepsStore(state => state.editing);
  const nextStep = useStepsStore(state => state.nextStep);
  const setCurrentStep = useStepsStore(state => state.setCurrentStep);
  const setEditing = useStepsStore(state => state.setEditing);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) return "";

    return executeRecaptcha("cep");
  }, [executeRecaptcha]);

  const searchCEP = async () => {
    setPersonalDataValues(formik.values);
    const token = await handleReCaptchaVerify();
    fetchData(stripCEP(formik.values.cep), token);
  };

  const handleNext = () => {
    setPersonalDataValues(formik.values);
    nextStep();
    setEditing(false);
  };

  const handleEdit = () => {
    setPersonalDataValues(formik.values);
    setCurrentStep(3);
  };

  useEffect(() => {
    formik.setValues({ ...personalDataValues });
  }, [personalDataValues]);

  return (
    <Card>
      <CardContent>
        <Stack direction="row" spacing={2}>
          <LocationOnOutlined
            sx={{
              color: "rosafrevo",
            }}
          />
          <Typography variant="h2">dados pessoais</Typography>
        </Stack>

        <Stack spacing={4} mt={4}>
          <TextField
            type="text"
            name="cep"
            label="CEP"
            required
            InputProps={{
              inputComponent: MaskedTextField,
              inputProps: { mask: "00000-000", maxLength: 9 },
            }}
            value={formik.values.cep}
            onChange={formik.handleChange}
            onBlur={e => {
              formik.handleBlur(e);
              searchCEP(e);
            }}
            onClick={() => event("CEP", "Step2", "Click")}
            error={formik.touched.cep && formik.errors.cep}
            helperText={formik.touched.cep && formik.errors.cep}
          />

          <Typography
            variant="link"
            component="a"
            rel="noreferrer"
            href="http://www.buscacep.correios.com.br/sistemas/buscacep/"
            target="_blank"
          >
            não sei meu CEP
          </Typography>
        </Stack>

        {success && (
          <Stack spacing={5} mt={4}>
            <TextField
              type="text"
              name="streetName"
              label="logradouro"
              required
              inputProps={{
                maxLength: 99,
              }}
              value={formik.values.streetName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onClick={() => event("EditarLogradouro", "Step2", "Click")}
              error={formik.touched.streetName && formik.errors.streetName}
              helperText={formik.touched.streetName && formik.errors.streetName}
            />

            <TextField
              type="text"
              name="streetNumber"
              label="número"
              required
              InputProps={{
                inputComponent: MaskedTextField,
                inputProps: { mask: Number, maxLength: 10 },
              }}
              value={formik.values.streetNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onClick={() => event("EditarNumero", "Step2", "Click")}
              error={formik.touched.streetNumber && formik.errors.streetNumber}
              helperText={
                formik.touched.streetNumber && formik.errors.streetNumber
              }
            />

            <TextField
              type="text"
              name="complement"
              label="complemento (opcional)"
              required
              inputProps={{
                maxLength: 20,
              }}
              value={formik.values.complement}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onClick={() => event("EditarComplemento", "Step2", "Click")}
            />

            <TextField
              type="text"
              name="neighborhood"
              label="bairro"
              required
              inputProps={{
                maxLength: 30,
              }}
              value={formik.values.neighborhood}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onClick={() => event("EditarBairro", "Step2", "Click")}
              error={formik.touched.neighborhood && formik.errors.neighborhood}
              helperText={
                formik.touched.neighborhood && formik.errors.neighborhood
              }
            />

            <TextField
              type="text"
              name="city"
              label="cidade"
              required
              inputProps={{
                maxLength: 30,
              }}
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onClick={() => event("EditarCidade", "Step2", "Click")}
              error={formik.touched.city && formik.errors.city}
              helperText={formik.touched.city && formik.errors.city}
            />

            <TextField
              type="text"
              name="state"
              label="estado"
              required
              inputProps={{
                maxLength: 20,
              }}
              value={formik.values.state}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onClick={() => event("EditarEstado", "Step2", "Click")}
              error={formik.touched.state && formik.errors.state}
              helperText={formik.touched.state && formik.errors.state}
            />
          </Stack>
        )}

        {success ? (
          <Box mt={5}>
            <Button
              variant="contained"
              color="primary"
              type="button"
              disabled={!formik.isValid}
              onClick={() => {
                if (editing) {
                  handleEdit();
                  event("BotaoSalvarPasso2", "Step1", "Click");
                } else {
                  handleNext();
                  event("BotaoContinuarPasso2", "Step1", "Click");
                }
              }}
            >
              <Typography variant="button">
                {editing ? "salvar" : "continuar"}
              </Typography>
            </Button>
          </Box>
        ) : (
          <Box mt={5}>
            <Button
              variant="contained"
              color="primary"
              type="button"
              disabled={!formik.values.cep}
              onClick={() => {
                searchCEP();
                event("ConsultarCEP", "Step2", "Click");
              }}
            >
              <Typography variant="button">buscar endereço</Typography>
            </Button>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
