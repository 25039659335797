import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { ErrorPage } from "features/ErrorHandler/components/ErrorPage";
import { Layout } from "features/Layout";
import { Checkout } from "pages/Checkout";
import { CheckoutPix } from "pages/CheckoutPix";
import { CheckoutPixPF } from "pages/CheckoutPixPF";
import { theme } from "theme";

import "./index.css";
import { TermsAndConditions } from "pages/TermsAndConditions";

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: "receber",
          children: [
            { path: "pix", element: <CheckoutPix /> },
            { index: true, element: <Checkout /> },
          ],
        },
        {
          path: "receber-pix",
          element: <CheckoutPixPF />,
        },
        {
          path: "termos-e-condicoes2",
          element: <TermsAndConditions />,
        },
      ],
      errorElement: (
        <Layout>
          <ErrorPage
            title="o sistema falhou"
            description="Este erro não era esperado"
            image={require("./shared/svg/ilustra.svg").default}
          />
        </Layout>
      ),
    },
  ]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};
export default App;
