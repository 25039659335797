import React, { useState } from "react";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import { ContentCopyOutlined, LaunchOutlined } from "@mui/icons-material";
import MediaQuery from "react-responsive";

import { PixPaymentButton } from "features/Pix/components/PixPaymentButton";
import { ReadMore } from "features/Pix/components/ReadMore";
import { Itau, Iti, Personnalite, Pix } from "shared/icons";
import {
  redirectDeepLinkITI,
  redirectDeepLinkITAU,
  redirectDeepLinkPERSONNALITE,
} from "shared/util/deepLinks";

export const PixCard = ({ pixCode }) => {
  const [copy, setCopy] = useState(false);

  return (
    <Card>
      <CardContent>
        <Stack spacing={4}>
          <Typography variant="h2">
            <MediaQuery maxWidth={1023}>
              como você quer fazer o pagamento?
            </MediaQuery>
            <MediaQuery minWidth={1024}>
              copie o código abaixo para pagar via Pix
            </MediaQuery>
          </Typography>

          <Stack spacing={3}>
            <PixPaymentButton
              pixCode={pixCode}
              onCopy={() => setCopy(true)}
              label={copy ? "código copiado!" : "copiar código Pix"}
              brandIcon={Pix}
              actionIcon={ContentCopyOutlined}
            />

            <MediaQuery maxWidth={1023}>
              <PixPaymentButton
                pixCode={pixCode}
                onCopy={() => redirectDeepLinkITI()}
                label="pagar no app Iti"
                brandIcon={Iti}
                actionIcon={LaunchOutlined}
              />

              <PixPaymentButton
                pixCode={pixCode}
                onCopy={() => redirectDeepLinkITAU()}
                label="pagar no app Itáu"
                brandIcon={Itau}
                actionIcon={LaunchOutlined}
              />

              <PixPaymentButton
                pixCode={pixCode}
                onCopy={() => redirectDeepLinkPERSONNALITE()}
                label="pagar no app Personnalité"
                brandIcon={Personnalite}
                actionIcon={LaunchOutlined}
              />
            </MediaQuery>
          </Stack>

          <Stack spacing={2}>
            <Typography variant="label" component="p">
              código Pix
            </Typography>
            <ReadMore>{pixCode}</ReadMore>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};
