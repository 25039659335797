import React from "react";
import { IMaskInput } from "react-imask";

export const MaskedTextField = React.forwardRef(function MaskedTextField(
  props,
  ref
) {
  const { mask, onChange, ...otherProps } = props;

  return (
    <IMaskInput
      {...otherProps}
      mask={mask}
      inputRef={ref}
      onAccept={value => onChange({ target: { name: props.name, value } })}
    />
  );
});
