import { api } from "services/api";

export const getCEP = async (zip, token) => {
  const URL = `cep?zip=${zip}`;

  try {
    const response = await api.get(URL, {
      headers: {
        "Content-type": "application/json",
        Authorization: `${token}`,
      },
    });

    return response;
  } catch (error) {
    if (error.message.includes("500")) {
      throw Error("ocorreu um erro");
    } else if (error.message.includes("400")) {
      throw Error("cep inexistente");
    } else {
      throw Error("Ocorreu um erro, tente novamente mais tarde");
    }
  }
};
