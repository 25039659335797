export const getTotalValue = (productValue, discount, shipping) => {
  return productValue - discount + shipping;
};

export const getinstallments = data => {
  const instalmments = [];

  [...Array(data.payment.max_number_of_installments).keys()]
    .map(i => i + 1)
    .forEach(i => {
      instalmments.push({
        quantity: i,
        value:
          getTotalValue(
            data.product_value,
            data.discount,
            data.shipping_value
          ) / i,
      });
    });

  return instalmments;
};
