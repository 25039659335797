export const palette = {
  common: {
    black: "2A2A2A",
  },
  primary: {
    main: "#FF4B8D",
  },
  secondary: {
    main: "#2A2A2A",
  },
  error: {
    main: "#D50000",
  },
  text: {
    primary: "#2A2A2A",
  },
  laranjasamba: "#ec7000",
  rosafrevo: "#FF4B8D",
  azulgafieira: "#2b394a",
  gray100: "#f9f9f9",
  gray300: "#dcdcdc",
  gray700: "#6b6b6b",
  gray900: "#2a2a2a",
  gray: "#929292",
  grayblue: "#98a0ae",
  graydarkblue: "#4f5a6d",
  darkgrey: "#303030",
  greyclaro: "#767676",
  dark: "#232d3e",
  green: "#00F38C",
  white: "#FFFFFF",
};
